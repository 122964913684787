// IF YOU USE THIS PLUGIN, YOU MUST DECLARE THE COLORS FOR IT FIRST
// .blbColor{
//    color: $black !important;
// }
// .blbColor2{
//    color: $dark-gray !important;
// }

// FOR DEVELOPMENT PURPOSES, USE THIS:
// body > #scriptDiv.hidden{
//    display: block !important;
// }

a.BLBST_a{
   @extend .blbColor;
	&:hover{
      @extend .blbColor2;
	}
}

#blbTagger{
   font-size: 1.3em;
   @extend .blbColor;
   .bubHead, .bubBody, .bubHead div{
      border-radius: 0 !important;
   }
   .bubHead{
      @extend .blbColor;
      border-color: currentColor !important;
      background-color: currentColor !important;
      border-bottom: none !important;
      div{
         @extend .blbColor2;
         background-color: currentColor !important;
      }
      div, h6, h1{
         border: none !important;
      }
      h6, h1{
         background-color: transparent !important;
         padding: .6rem 0 .9rem .5rem !important;
         text-shadow: rgba(black, .35) .1rem .1rem 0 !important;
      }
   }
   .close{
      display: none !important;
      // INSTEAD OF JUST HIDING IT, OPTIONALLY USE THESE STYLES:
      // background: transparent !important;
      // border: none !important;
      // width: 1.2rem !important;
      // span{
      //    display: none !important;
      // }
      // &:after{
      //    content: '';
      //    display: block !important;
      //    @include icon('close-white', 'light');
      //    height: 1.6rem;
      // }
   }
   .bubBody{
      color: inherit !important;
      box-shadow: $box-shadow !important;
      border-color: currentColor !important;
      div{
         color: $black;
         a{
            @extend .blbColor;
            border-bottom-color: currentColor !important;
            &:hover{
               @extend .blbColor2;
            }
         }
      }
   }
}
