@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin icon($icon, $weight: 'regular'){
    background-image: url('../../svg/' + $weight + '/' + $icon + '.svg');
    background-repeat: no-repeat;
}

@mixin truncate($line-count: 3, $line-height: 1em){
    overflow: hidden;
    max-height: $line-count * $line-height;
    position: relative;
    cursor: text;
    span{
        display: block;
    }
    &.oversized:after {
        content: "...";
        text-align: right;
        position: absolute;
        top: ($line-count - 1) * $line-height;
        right: 0;
        width: 2.5em;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
    @supports ((-webkit-line-clamp: $line-count) or (text-overflow: -o-ellipsis-lastline)){
        display: -webkit-box;
        -webkit-line-clamp: $line-count;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        &:after{
            display: none;
        }
    }
    &.opened{
        &:after{
            display: none;
        }
        max-height: inherit;
        -webkit-line-clamp: inherit;
    }
}


@mixin breakpointMin($min, $height: false) {
    @if($height){
        @media (min-height: $min) {
            @content;
        }
    }
    @else {
        @media (min-width: $min) {
            @content;
        }
    }
}
@mixin breakpointMax($max, $height: false) {
    @if($height){
        @media (max-height: $max) {
            @content;
        }
    }
    @else {
        @media (max-width: $max) {
            @content;
        }
    }
}
@mixin breakpointMinMax($min, $max, $height: false) {
    @if($height){
        @media (min-height: $min) and (max-height: $max) {
            @content;
        }
    }
    @else {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
}
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin arrow($size, $color, $direction: 'down', $display: 'inline-block') {

    width: 0;
    height: 0;
    display: unquote($display);

    @if $direction == 'down' {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    } @else if $direction == 'up'{
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    } @else if $direction == 'left'{
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    } @else if $direction == 'right'{
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    }

}

@mixin dropdown($font-size: 12px, $width: 10em, $height: 2.5em, $background-color: white, $border-color: $lightest-gray, $font-color: $dark-gray, $dark-background-color: $darkest-gray, $dark-border-color: $gray, $dark-font-color: $off-white) {
    font-size: $font-size;
    border: 1px solid $border-color;
    width: $width;
    height: $height;
    line-height: $height;
    background-color: $background-color;
    .ie &{
        line-height: $height - .2em;
    }
    &:hover:not(.disabled):not(.loading){
        box-shadow: 0 0 .75em rgba($font-color, .2);
    }
    &:after{
        border-top-color: $font-color;
    }
    select {
        color: $font-color;
        background-color: $background-color;
        height: calc(#{$height} - 1.2rem);
        line-height: calc(#{$height} - 1.1rem);
        &:-moz-focusring {
            text-shadow: 0 0 0 $background-color;
        }
    }
    > .spinner{
        left: 1em;
        color: currentColor;
        position: absolute;
        top: calc(#{$height} / 2 - .5em);
    }
    body.dark &{
        border-color: $dark-border-color;
        background-color: $dark-background-color;
        &:hover{
            box-shadow: 0 0 .75em rgba($dark-font-color, .2);
        }
        &:after{
            border-top-color: $dark-font-color;
        }
        select{
            color: $dark-font-color;
            background-color: $dark-background-color;
            &:-moz-focusring {
                text-shadow: 0 0 0 $dark-background-color;
            }
        }
    }
}

@mixin title-switcher($mediaWidth: $mobile-width){
    .short-title{
        display: none;
    }
    @media screen and (max-width: $mediaWidth){
        .short-title{
            display: inline !important;
        }
        .full-title{
            display: none;
        }
    }
}




@function sermon-list-button-width($icon-count, $play-button:true, $video:false){
    $width: ($sermon-list-button-icon-width + $sermon-list-button-icon-margin) * $icon-count;
    @if($play-button){
        $width: $width + $sermon-list-play-button-extra-width;
    }
    @if($video){
        $width: $width + $sermon-list-video-width + $sermon-list-video-margin;
    }
    @return $width;
}
@mixin sermon-list-widths ($video: false, $audio: false ){
    $count: 0;
    $openCount: 0;
    $microCount: 0;
    $openMicroCount: 0;
    @if($audio){
        $count: 2;
        $openCount: 3;
        $microCount: 1;
        $openMicroCount: 2;
    }
    $width: sermon-list-button-width($count, $audio, $video);
    $openWidth: sermon-list-button-width($openCount, $audio, false);
    $microWidth: sermon-list-button-width($microCount, $audio, $video);
    $openMicroWidth: sermon-list-button-width($openMicroCount, $audio, false);

    .buttons{ width: $width; }
    .info { width: calc(100% - #{$width} - 4rem - #{$sermon-list-button-container-margin}); }
    @if($audio){
        @media screen and (max-width: $mobile-width){
            .buttons{ width: $microWidth; }
            .info { width: calc(100% - #{$microWidth} - #{$sermon-list-button-container-margin}); }
        }
        &.open{
            .buttons{ width: $openWidth; }
            .info { width: calc(100% - #{$openWidth} - 4rem - #{$sermon-list-button-container-margin}); }
            @media screen and (max-width: $mobile-width){
                .buttons{ width: $openMicroWidth; }
                .info { width: calc(100% - #{$openMicroWidth} - #{$sermon-list-button-container-margin}); }
            }
        }
    }

}
