.leaflet-fade-anim .leaflet-tile, .leaflet-fade-anim .leaflet-popup{
    transition: opacity 0.25s linear;
}
.leaflet-popup{
    outline: none !important;
}
.leaflet-popup .leaflet-popup-content-wrapper, .leaflet-popup-content{
    padding: 0 !important;
    margin: 0 !important;
    width: 35rem !important;
    font-size: 1rem !important;
    @media screen and (max-width: $mobile-width) {
        width: 30rem !important;
    }
}

.leaflet-bar, .leaflet-touch .leaflet-bar{
    border: none;
    a{
        box-sizing: content-box;
        border-color: $lightest-gray !important;
        background-color: white;
        &.leaflet-disabled{
            background-color: #f4f4f4;
        }

        body.desktop &:hover{
            background-color: $lightest-gray;
            &.leaflet-disabled{
                background-color: #f4f4f4;
            }
        }
    }
}

@keyframes locationSpinner {
    1%{
            transform: rotate(0deg);
        }
    100%{
            transform: rotate(1800deg);
        }
}

.my-location{
    cursor: pointer;
    transition: fill 1s;
    .icon{
        transform: rotate(0deg);
        font-size: 1.25em;
    }
    .searching{
        animation: locationSpinner 3s ease 0s infinite;
    }
    .found, .found:hover{
        fill: $primary-accent-color;
    }
    .error{
        fill: $error-color;
    }
    &.error:hover{
        .icon{
            fill: $error-color-dark;
        }
    }
    &.found:hover, &.error:hover{
        background-color: $primary-bg-color !important;
    }
}

.leaflet-container{
    background-color: $secondary-bg-color;

    .leaflet-right{
        width: 100%;
        max-width: 31.25rem;
    }

    .leaflet-control-attribution{
        padding: .25rem .75rem;
        text-align: center;
        font-size: 1rem;
        color: $dark-gray;
        font-weight: normal;
        a{
            font-weight: normal;
            color: $darkest-gray;
        }
        a:hover{
            color: $primary-accent-color;
        }
    }
}
