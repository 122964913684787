$checkmark: '✔'; // alternate checkmark style: ✓

$base-size: 10px;
$font-family: 'Open Sans', sans-serif;
$header-height: 4.5rem;
$breadcrumb-height: 5.5rem;
$breadcrumb-header-height: $header-height + $breadcrumb-height;
$nav-width: 30rem;
$sidebar-width: 28rem;
$user-panel-width: 35rem;
$generic-transition-speed: .5s;

$wrapper-open-speed: .25s;
$wrapper-ease: cubic-bezier(.42,0,.58,1);

$primary-bg-color: #fff;
$secondary-bg-color: #f7f7f7;

$off-white:                   #eee;
$lightest-gray:               #dfdfdf;
$light-gray:                  #aaaaaa;
$gray:                        #888;
$dark-gray:                   #6e6e6e;
$darkest-gray:                #333;


$black:                       #222;

$primary-accent-color:          rgb(0, 130, 255);
$primary-accent-color-faded:    rgba(0, 130, 255, 0.2);
$primary-accent-color-highlight:lighten($primary-accent-color, 15);
$primary-accent-color-light:    lighten($primary-accent-color, 45);
$primary-accent-color-dark:     darken($primary-accent-color, 15);


$sale-color: rgb(127,195,121);


$success-color:                 rgb(56,193,74);
$success-color-light:           lighten($success-color,45);
$success-color-dark:            darken($success-color,15);
$success-color-highlight:           lighten($success-color,15);


$error-color:                 rgba(255,30,50,1);
$error-color-faded:           rgba(255,30,50,0.6);
$error-color-light:           lighten($error-color,40);
$error-color-dark:            darken($error-color,20);
$error-color-highlight:       lighten($error-color,15);

$warning-color:               rgba(255,233,100,1);
$warning-color-faded:               rgba(255,233,100,0.2);
$warning-color-light:         lighten($warning-color, 25);
$warning-color-dark:          darken(mix($warning-color, orange, .8), 20);
$warning-color-highlight:     mix($warning-color, $warning-color-dark);

$live-color:                  rgb(111, 43, 164);
$live-color-light:            lighten($live-color,25);
$live-color-highlight:            lighten($live-color,10);
$live-color-dark:             darken($live-color,15);


$box-shadow: 0 0 2rem rgba(0,0,0,0.15);
$box-shadow-error: 0 0 2rem rgba($error-color, 0.15);
$box-shadow-active: 0 0 2rem rgba($primary-accent-color, 0.15);
$box-shadow-sucess: 0 0 2rem rgba($success-color, 0.15);

$spotify-brand-color: #1DB954;
$tunein-brand-color: #1C203C;
$apple-brand-color: #555555;
$facebook-brand-color: #3C5A99;
$twitter-brand-color: #1da1f2;
$youtube-brand-color: #FF0000;
$dropbox-brand-color: #0061FF;
$twitch-brand-color: #9146FF;
$vimeo-brand-color: #1ab7ea;

$generic-border: .1rem solid $lightest-gray;

$mobile-width: 480px;
$modal-stack-width: 680px;
$desktop-small: 960px;
$desktop-medium: 1280px;
$desktop-large: 1440px;

$video-large-breakpoint: 720px;
$video-medium-breakpoint: 480px;
$video-small-breakpoint: 320px;

$sermon-list-bar-height: 3rem;
$sermon-list-sermon-transition-time: .25s;
$sermon-list-extra-video-height: .25rem;
$sermon-list-video-margin: 1.15rem;
$sermon-list-video-height: $sermon-list-bar-height + ($sermon-list-extra-video-height * 2);
$sermon-list-video-width: $sermon-list-video-height * 1.62 + .2rem;
$sermon-list-button-icon-width: 2.5rem;
$sermon-list-button-icon-margin: 0.5rem;
$sermon-list-play-button-extra-width: .5rem;
$sermon-list-pagination-height: 5.25rem;
$sermon-list-button-container-margin: 1.25rem;

$sermon-browser-border-size: .1rem;
$sermon-browser-header-height: 9rem;
$sermon-browser-sort-filter-height: 3rem;
$sermon-browser-body-padding: 1.5rem;

$site-header-margins: 1.5rem;
$site-button-spacing: .5em;
$sidebar-minimized-width: 8.5rem;

html{
    --sa-color-sale: #{$sale-color};

    --sa-color-success: #{$success-color};
    --sa-color-success-light: #{$success-color-light};
    --sa-color-success-highlight: #{$success-color-highlight};
    --sa-color-success-dark: #{$success-color-dark};

    --sa-color-error: #{$error-color};
    --sa-color-error-light: #{$error-color-light};
    --sa-color-error-highlight: #{$error-color-highlight};
    --sa-color-error-dark: #{$error-color-dark};

    --sa-color-warning: #{$warning-color};
    --sa-color-warning-light: #{$warning-color-light};
    --sa-color-warning-highlight: #{$warning-color-highlight};
    --sa-color-warning-dark: #{$warning-color-dark};

    --sa-color-live: #{$live-color};
    --sa-color-live-light: #{$live-color-light};
    --sa-color-live-highlight: #{$live-color-highlight};
    --sa-color-live-dark: #{$live-color-dark};

    --sa-color-primary: #{$primary-accent-color};
    --sa-color-primary-light: #{$primary-accent-color-light};
    --sa-color-primary-highlight: #{$primary-accent-color-highlight};
    --sa-color-primary-dark: #{$primary-accent-color-dark};

    --sa-color-facebook: #{$facebook-brand-color};
    --sa-color-twitter: #{$twitter-brand-color};
    --sa-color-youtube: #{$youtube-brand-color};

    --sa-color-background: #{$primary-bg-color};
    --sa-color-secondary-background: #{$secondary-bg-color};
    --sa-color-off-white: #{$off-white};
    --sa-color-lightest-gray: #{$lightest-gray};
    --sa-color-light-gray: #{$light-gray};
    --sa-color-gray: #{$gray};
    --sa-color-dark-gray: #{$dark-gray};
    --sa-color-darkest-gray: #{$darkest-gray};
    --sa-color-black: #{$black};

    --sa-font-family: #{$font-family};
}
