$play-transition-speed: $generic-transition-speed;

.audio-player{
    font-size: 1em;
    position: relative;
    .noHTML5Message{
        display: none;
    }
    *{
        user-select: none;
    }
    .alert{
        font-size: 0;
        opacity: 0;
    }
    .sermon-details, .time-container{
         margin-bottom: 1.1em;
    }
    .sermon-details{
        font-size: 1.1em;
        height: 3.5em;
        .sermon-title, .sermon-subtitle{
            line-height: 1.5em;
            &, a, span{
                font-weight: 400;
                color: $dark-gray;
                .dark &{
                    color: $light-gray;
                }
            }
            a:hover{
                color: $black;
                .dark &{
                    color: white;
                }
                text-decoration: underline;
            }
        }
        .sermon-title{
            margin-bottom: .2em;
            a{
                font-size: 1.5em;
                color: $darkest-gray;
                .dark &{
                    color: $lightest-gray;
                }
                @include title-switcher(540px);
            }
        }
    }
    .popup-audio{
        top: -.15rem;
    }
    .player-contents{
        .time-controller, .player-controller{
            display: flex;
            position: relative;
            margin-top: -.65em;
            .group{
                flex: 1;
                display: flex;
                &.left-group{
                    > div:not(:first-child.speed){
                        left: -.85rem;
                    }
                }
                &.center-group{
                    flex: 2;
                    justify-content: center;
                }
                &.right-group{
                    justify-content: flex-end;
                    > div, > a{
                        right: -1rem;
                    }
                }
                > div, > a, div.play{
                    height: 2.25em;
                    line-height: 2em;
                    font-size: 1.4em;
                    text-align: center;
                    display: inline-block;
                    height: 100%;
                    width: 3.5rem;
                    position: relative;
                }
                &.center-group{
                    > div, > a, div.play{
                        line-height: 1.5em;
                        font-size: 1.8em;
                    }
                }
                &.time{
                    .dark &{
                        color: $lightest-gray;
                    }
                    span, input, &.duration{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        height: 1em;
                        position: relative;
                        font-weight: 400 !important;
                    }
                    &.current span{
                        cursor: pointer;
                        pointer-events: all;
                        &:hover{
                            color: $primary-accent-color;
                            .dark &{
                                color: white;
                            }
                        }
                    }
                    input{
                        display: none;
                        pointer-events: all;
                        padding: .5em .5em .5em 1em;
                        position: absolute;
                        left: -1em;
                        font-size: 1em;
                        top: -.4em;
                        width: 3.5em;
                        border-radius: 1.5em;
                        background: $primary-bg-color;
                        .dark &{
                            background: $black;
                        }
                    }
                    &.input{
                        span{
                            display: none;
                        }
                        input{
                            display: inline;
                        }
                    }
                }
            }
        }
        .time-controller{
            font-size: 1.4em;
            height: 2em;
            pointer-events: none;
        }
        .player-controller{
            .control, .volume-slider, .popup-audio{
                color: $gray;
                &:hover{
                    color: $dark-gray;
                    .dark &{
                        color: $light-gray;
                    }
                }
            }
            .group{
                height: 3.5em;
                text-align: center;
                div, a{
                    &.skip-backward, &.skip-forward {
                        position: relative;
                    }
                    &.play-button-container{
                        width: 100%;
                        max-width: 2em;
                        box-sizing: content-box;
                        display: inline-block;
                        transition: padding $play-transition-speed, max-width $play-transition-speed;
                        > .play{
                            width: .9em;
                            max-width: none;
                            font-size: 1em;
                            background: transparent;
                            border-radius: 1.5em !important;
                            top: 0;
                            padding: 0 .5em !important;
                            position: relative;
                            left: 0;
                            box-sizing: content-box;
                            transition: all $play-transition-speed;
                            .icon.play{
                                margin-left: .2em;
                            }
                            .icon{
                                transition: fill $play-transition-speed;
                            }
                            .spinner{
                                transition: border-color $play-transition-speed;
                                .ie &{
                                    display: inline-block !important;
                                }
                                .dark &{
                                    border-color: $darkest-gray;
                                }
                            }
                        }
                    }
                }
            }
        }

        .volume-slider{
            width: 4em !important;
            margin-right: 1rem;
            color: currentColor;
        }
        .volume-slider-container .icon{
            max-width: 4em;
        }
    }
    &.noHTML5{
        display: table;
        width: 100%;
        margin: auto;
        max-width: 90rem;
        .player-contents{
            .time-controller, .player-controller{
                display: none !important;
            }
        }
        .noHTML5Message{
            color: $dark-gray;
            display: table-cell;
            vertical-align: middle;
            padding: 1rem 1rem 2.5rem 1rem;
            font-size: 1.2rem;
            line-height: 1.35;
            text-align: center;
            a{
                text-decoration: underline;
                color: $primary-accent-color;
                &:hover{
                    color: $primary-accent-color-highlight;
                }
            }
        }
    }
    h2{
        color: $dark-gray;
        z-index: 1;
        text-transform: uppercase;
        font-weight: 300;
        text-align: center;
        margin: 0;
        width: 100%;
    }
    &.no-sermon{
        display: flex;
        align-items: center;
    }

    div.play:hover svg.icon{
        fill: $dark-gray !important;
        .dark &{
            fill: white !important;
        }
    }
    .redo, .spinner, .pause{ display: none; }
    &.paused.unplayed{
         .player-contents .player-controller .group div{
            &.play-button-container{
                max-width: 0;
                padding: 0;
            }
            &.play{
                background-color: $darkest-gray !important;
                &:hover{
                    background-color: $dark-gray !important;
                }
                .dark &{
                    background-color: $lightest-gray !important;
                    &:hover{
                        background-color: white !important;
                    }
                }
                width: 2.5em;
                top: -2.5em;
                line-height: 1.7em;
                left: -1.75em;
                .icon{
                    max-width: none;
                    fill: $primary-bg-color !important;
                    .dark &{
                        fill: $darkest-gray !important;
                    }
                }
            }
        }
    }
    &.playing, &.loading{
        .play{ display: none;}
    }
    &.playing{
        .spinner{ display: none; }
        .pause{ display: inline-block; }
        .pause{
            fill: $darkest-gray !important;
            .dark &{
                fill: $lightest-gray !important;
            }
        }
    }
    &.ended{
        .pause, .play, .spinner{ display: none; }
        .redo{
            display: inline-block;
        }
    }
    &.loading{
        .redo, .pause{ display: none; }
        .spinner{
            display: inline-block !important;
            border-bottom-color: $darkest-gray !important;
            border-right-color: $darkest-gray !important;
            opacity: 1;
            .dark &{
                border-bottom-color: $lightest-gray !important;
                border-right-color: $lightest-gray !important;
            }
        }
    }

    @include breakpointMax($breakpoint-medium){
        &.allow-split{
            .player-contents{
                .time-controller, .player-controller{
                    flex-wrap: wrap;
                }
                .player-controller{
                    .group{
                        flex-wrap: wrap;
                        &.center-group{
                            order: 1;
                            flex-basis: 100%;
                            div.play-button-container .play{
                                padding: 0;
                            }
                            margin: -1em 0;
                        }
                        &.left-group, &.right-group{
                            flex-basis: 50%;
                        }
                        &.left-group{ order: 2; }
                        &.right-group{ order: 3; }
                    }
                }
            }
            &.paused.unplayed .player-contents .player-controller .group div.play{
                top: -2.4em;
            }
        }
    }

    &.large-format{
        .sa_wave-container, .sa_wave_mask{
            height: 9em;
        }
        .time-controller{
            margin-bottom: 1em;
        }
        &.paused .player-contents .player-controller .group div.play{
            top: -4.4em;
            @include breakpointMax($breakpoint-medium){
                top: -3.8em;
            }
        }
    }

    &.legacy .time-controller .group.time input{
        border-radius: 0;
    }

    // may want to use this later for hiding the background for autoplay situations
    // &.autoplays {
    //     &, &.playing, &.paused, &.loading, &.unplayed{
    //         .player-contents .player-controller .group div.play{
    //             background-color: transparent !important;
    //             transition: all $play-transition-speed, background-color 0s;
    //         }
    //     }
    // }
    &.airplaying .speed{
        left: -.25rem !important
    }
	&.chromecasting .chromecast{
		.shortcut-tooltip{
			left: auto;
			right: 1em;
			transform: none;
		}
	}
}
