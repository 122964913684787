
.image-editor{
    --aspectRatio: 1;
	img{
		max-width: 100%;
	}
	.preview-image-container{
		position: relative;
	}
	h3{
		font-weight: normal;
		font-size: 1.2em;
        margin-bottom: .5em;
	}
	.preview-header{
		display: flex;
		justify-content: space-between;
	}
	.preview{
		width: 100%;
		overflow: hidden;
        background-color: black;
	}
    .preview-overlays{
        z-index: 10;
        color: rgba(black, .75);
        &, span{
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
        }
        .icon{
            height: 100%;
        }
        span:not(.active){
            display: none;
        }
    }
    .preview-overlay-buttons{
        position: absolute;
        right: 0;
        z-index: 20;
    }
    .overlay-button{
        padding: 0;
        color: $black;
        font-size: 2em;
        border: none;
        margin-bottom: 1rem;
        &:hover{
            background-color: transparent !important;
            color: $gray;
        }
        &.active{
            color: $primary-accent-color;
            &:hover{
                color: $primary-accent-color-highlight;
            }
        }
        .icon{
            display: block;
        }
    }
	.shapes{
		display: flex;
		align-items: center;
        margin-bottom: .5em;
		> div{
			 width: 2em;
			 height: 2em;
			 display: inline-flex;
             align-items: center;
			 cursor: pointer;
			 color: $light-gray;
             border-bottom: .3em solid currentColor;
             justify-content: center;
			 &:before{
				content: '';
                 border: 1px solid currentColor;
                 width: 1em;
                 height: 1em;
                 display: block;
			 }
			 + div{
				 margin-left: 3px;
			 }
		}
        .circle:before{
            border-radius: 50%;
        }
        .square{
            &:before{
                width: calc(1em * var(--aspectRatio));
            }
        }
	}
    .square-type .square, .circle-type .circle{
        color: $primary-accent-color;
    }
	.square-type{
		.circle-overlay{
			display: none;
		}
	}

	.circle-overlay{
		position: absolute;
		z-index: 1;
		top: 0; left: 0; right: 0; bottom: 0;
		background: radial-gradient(circle at center, transparent 70%, rgba($secondary-bg-color, .8) 0%);
	}
    .flex-grid-row{
        margin-top: 1em;
    }
	.flex-grid-row > div{
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
	}
	button{
		display: block;
		margin-bottom: .5em;
	}
    .button-stack{
        margin-top: 2em;
    }
    &.low-resolution > div:not(.image-too-small){
        display: none;
    }
    &:not(.low-resolution) .image-too-small{
        display: none;
    }
    .resolution-warning{
        color: $gray;
    }
    .resolution-error{
        color: $error-color;
    }
    .output-resolution-container {
        margin-top: 2.5em;
    }
    .resolution-warning, .resolution-error{
        margin-top: 1em;
        display: flex;
        span{
            white-space: nowrap;
        }
        div{
            font-size: 2.1rem;
        }
        div + div{
            font-size: 1.6rem;
            margin-left: 1.2rem;
        }
    }
    .tools-container{
        display: flex;
        width: 100%;
        margin-bottom: 1em;
        button{
            color: $black;
            display: inline-block;
            border: none;
            background-color: transparent !important;
            font-size: 1.6rem;
            padding: 0;
            margin: 0 1.5em 0 0;
            align-self: center;
            &.reset{
                margin-right: 2em;
            }
        }
        .tooltip-button{
            font-size: 2.8rem;
            color: currentColor;
        }
        .tooltip-target{
            width: auto;
            top: 2.25em;
            font-size: 1.4rem;
            padding: 1rem 1.2rem;
        }
        .reset{
            font-size: 1.8rem;
        }
    }
    .cropper-image-container{
        max-height: 360px;
    }
    @include breakpointMin($desktop-medium){
        display: flex;
        flex-wrap: wrap;
        .cropper-image-container{
            max-height: 450px;
            width: calc(70% - 2em);
        }
        > .flex-grid-row{
            margin: 0 0 0 2em;
            width: calc(30%);
            flex-direction: column;
            > div {
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }
        .output-resolution-container{
            margin-top: 1em;
        }
    }
}
.cropper-line{
    background-color: transparent;
}
.cropper-point{
    width: 5px !important;
    height: 5px !important;
}

.upload-image-modal .modal-contents-container{
    max-width: 62rem;
    @include breakpointMin($desktop-medium){
        max-width: 96rem;
    }
}