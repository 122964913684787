
.embed-group{
    $body-padding: 2rem;
    $internal-padding: 1.25em;
    $pagination-height: 3rem;
    $broadcaster-height: 4em;
    $max-description-height: 7.2em; // lines are around 1-1.2em each - this intentionall cuts off a line so users can see that they need to scroll
    background-color: $off-white;
    position: relative;
    font-size: 1.2em;
    color: $darkest-gray;
    .no-group{
        align-items: center;
        justify-content: center;
        font-size: 1.6em;
        .icon{
            margin-top: 2.5rem;
            font-size: 1.8em;
        }
    }
    .primary-content:not(.no-group){
        background: $primary-bg-color;
    }
    .primary-content{
        overflow: hidden;
        position: absolute;
        top: $body-padding; right: $body-padding; bottom: $body-padding; left: $body-padding;
        display: flex;
        flex-direction: column;
        > div{
            &:not(.pagination){
                flex-grow: 1;
            }
            width: 100%;
        }
    }
    .group-metadata{
        margin-top: 2rem;
        margin-bottom: .5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button, .icon, .group-broadcaster-count{
            font-size: 1.2em;
        }
        button{
            text-transform: capitalize;
            margin-left: 1rem;
            white-space: nowrap;
        }
        @include breakpointMax(480px){
            flex-wrap: wrap;
        }
    }
    .group-broadcaster-count{
        margin-right: auto;
        @include breakpointMax(480px){
            white-space: nowrap;
            width: 100%;
            margin-right: 0;
            order: 3;
        }
    }
    a .icon{
        opacity: .5;
    }
    a:hover{
        text-decoration: underline;
        .icon{
            opacity: .8;
        }
    }
    .group-url, .group-description a{
        color: $primary-accent-color;
    }
    .group-description{
        max-height: $max-description-height;
        padding-bottom: .5rem;
        line-height: 1.2em;
    }
    .broadcaster-list{
        ul{
            width: 100%;
            margin: 0;
            padding: 0;
        }
        li{
            padding: $internal-padding;
            border-top: $generic-border;
            display: flex;
            overflow: hidden;
            &:first-child{
                border-top: none;
            }
        }
    }
    .broadcaster-thumbnail{
        height: $broadcaster-height;
        margin-right: $internal-padding;
        img{
            height: $broadcaster-height;
        }
    }
    .broadcaster-name{
        font-size: 1.4em;
        margin: .35em 0 .25em 0;
    }
    .header{
        padding: 1rem;
        box-sizing: border-box;
        max-height: 26rem;
    }
    h1{
        margin: .25em 0;
    }
    .header + .broadcaster-list, .pagination{
        border-top: $generic-border;
    }
    .pagination{
        height: $pagination-height;
        padding: $body-padding 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
}
