@include keyframes(fadeOutUp) {
    0% {
        opacity: 1;
        @include transform(translateY(0));
    }

    100% {
        opacity: 0;
        @include transform(translateY(-200px));
    }
}
@include keyframes(fadeInDown) {
    0% {
        opacity: 0;
        @include transform(translateY(-200px));
    }

    100% {
        opacity: 1;
        @include transform(translateY(0));
    }
}
