.solo .modal-container{
    top: 0;
}

.solo .donate-modal{
    $height: 720px;
    $headerHeight: 50px;
    .donate-iframe-header{
        height: $headerHeight;
        width: 100%;
        background-color: $theme1-accent-color;
        color: white;
        display: flex;
        align-items: center;
        font-weight: lighter;
        font-size: 1.5em;
        padding-left: 30px;
        padding-right: $headerHeight;
        border-bottom: 1px solid $theme1-accent-hover-color;
        > div{
            @extend .overflow-ellipsis;
        }
        @media screen and (min-width: 500px) {
            font-size: 2em;
        }
    }
    .modal-contents-container{
        padding: 0 !important;
        height: $height;
        max-height: 100vh;
        position: relative;
    }
    .modal-close-button{
        width: $headerHeight;
        height: $headerHeight;
        line-height: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        svg{
            fill: currentColor !important;
        }
        &, &:hover{
            color: white;
        }
        &:hover{
            background-color: $theme1-accent-hover-color !important;
        }
    }
    iframe{
        width: 100%;
        height: $height - $headerHeight;
        max-height: calc(100vh - #{$headerHeight});
    }
    .donation-iframe-container{
        position: absolute;
        top: $headerHeight;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: hidden;
    }
}