.solo-block.solo-footer {
	background: #373737;
	color: $lightest-gray;

	ul {
		padding: 0;

		li {
			margin: .5em .2rem;
		}
	}

	// .gc-cs-link is specifically to deal with phone numbers getting styled
	// by the google voice plugin
	a:not(.powered-by-url), a:visited:not(.powered-by-url), .gc-cs-link {
		color: $lightest-gray;
		text-decoration: none;
		transition: color .2s;

		&:hover {
			color: white !important;
			text-decoration: underline;
		}
	}

	.solo-site-by {
		text-align: left;
		margin-top: 20px;
		font-size: .8em;
        a{
            display: inline-block;
        }
	}

    .logo-min{
        font-size: .9em;
    }

}
