
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  transition: transform .3s ease-out, opacity .3s ease-in;
}

.leaflet-cluster-spider-leg {
	// stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly
  transition: stroke-dashoffset .3s ease-out, stroke-opacity .3s ease-in;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 50%;

  div {
    border-radius: 50%;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    text-align: center;
  }

  span {
    line-height: 30px;
  }
}

.marker-cluster,
.leaflet-oldie .marker-cluster {
  background-color: $cluster-border-color;

  div {
    background-color: $cluster-background-color;
    color: $cluster-color;
    font-weight: 600;
  }

}

.marker-cluster-small {
  @include cluster-icon(1);
}

.marker-cluster-medium {
  @include cluster-icon(2);
}

.marker-cluster-large {
  @include cluster-icon(3);
}
