$slider-height: 1rem;

.sermons-anywhere-slider {
	.slider-preface {
		margin-bottom: 2rem;

		h3, p {
			text-align: center;
			line-height: 1.6em;
		}

		p.broadcaster-id {
			font-size: 1.2em;
			strong {
				font-size: 2em;
				display: block;
				margin-top: .5rem;
				letter-spacing: .1em;
				font-weight: lighter;
			}
		}
	}

	.slick-track{
		display: flex;
		align-items: center;
	}

	.slides-container{
		width: 100%;
	}

	.slider-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1em 0;
		// https://stackoverflow.com/questions/3226001/some-font-sizes-rendered-larger-on-safari-iphone
		// https://blog.55minutes.com/2012/04/iphone-text-resizing/
		@media screen and (max-device-width: 480px){
		    -webkit-text-size-adjust: 100%;
		}

		.prev, .next {
			height: 100%;
			display: flex;
			align-items: center;
			padding: 0 10px;
			cursor: pointer;
			border-radius: .3rem;
			background-color: inherit !important;
			overflow: hidden;
			// position: relative;

			&:hover:after {
				position: absolute;
				display: block;
				content: '';
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(0,0,0,.05) !important;
			}
		}

		.slides {
			.platform-slide {
				align-items: center;
				padding: 0 1rem;
			 	text-align: left;

				.platform-icon {
					height: 12rem;
					max-width: 18rem;
					margin: 0 auto;
					text-align: center;

					svg {
						height: 100%;
					}
				}

				.platform-description {
					margin: 1rem 3rem;
					line-height: 1.6em;

					h4 {
						font-weight: lighter;
						font-size: 1.5em;
						margin-top: 0;
					}

					p {
						margin: 0;
					}
				}
			}
		}
	}

	ul.slide-pager {
		padding: 0;
		margin: 2rem 0 0;
		display: flex;
		justify-content: center;

		li {
			width: 7rem;
			height: 7rem;
			padding: 1.5rem 0;
			border-radius: 5rem;
			margin: 0 .5rem;
			text-align: center;
			transition: background-color $generic-transition-speed;


			&.active {
				background: $darkest-gray;
				color: white;
			}

			svg {
				height: 4rem;
			}

			&:hover:not(.active){
				background: rgba(0,0,0,.05);
				color: black;
				cursor: pointer;
			}
		}
	}

	@media screen and (min-width: $mobile-break) {
		.slider-content {
			min-height: $slider-height;

			.prev, .next {
				padding: 0 10px;
				font-size: 1.5em;
			}

			.slides {
				.platform-slide {
					min-height: $slider-height;
					display: flex;
					// padding: 2rem;
					justify-content: center;

					.platform-icon {
						width: 40%;
						margin-right: 2rem;
						margin-left: 0;
					}

					.platform-description {
						width: 60%;
						max-width: 96rem;
						margin: 0;
					}
				}
			}
		}
	}
}
