
@keyframes webcast-live-pulse {
	0% {transform: scale(1,1); opacity: 1;}
	50% {transform: scale(4,4); opacity: 0;}
	100% {opacity: 0}
}

.solo-header {
	height: 50px;
	text-align: center;
	background: rgba(33,33,33,.8);
	color: white;

	a {
		color: currentColor;
		font-size: 1.1em;

		&:visited {
			color: currentColor;
		}
	}

	.translation-dropdown-button{
		border-right-width: 1px;
		border-right-style: solid;
		line-height: 40px !important;
		padding-left: 0;
		display: none;
		.translation-dropdown{
			top: 60px;
			left: 0;
			right: auto;
		}
	}

	.solo-logo {
		display: block;
		background: rgba(255,255,255,.1);
		width: 200px;
		height: 60px;
		float: left;
		text-align: center;
		line-height: 60px;
		border-radius: 10px;
	}

	.solo-profile-img {
		display: block;
		height: 50px;
		float: left;
		text-align: center;
		line-height: 60px;
		position: relative;

		img {
			height: 100%;
		}

		&:after {
			transition: background .2s;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}

		&:hover:after {
			background: rgba(255,255,255,.1);
		}
	}

	.mobile-translation-button, .solo-nav-toggle{
		float: right;
		height: 100%;
	}

	.mobile-translation-button{
		font-size: 1.4em;
	}

	.solo-nav-toggle {
		font-size: 1.5em;
		padding-top: 20px;
		width: 5rem;

		.menu-icon {
			margin: 0 auto;

			span {
				background: white;
			}
		}
	}

    .webcast-status{
        &.offline{
            .online-text{
                display: none;
            }
        }
        &:not(.offline){
            .offline-text{
                display: none;
            }
        }

		.webcast-indicator{
		    display: inline-block;
		    width: 10px;
		    height: 10px;
		    background: red;
		    vertical-align: middle;
		    border-radius: 10px;
		    position: relative;
		    overflow: visible;
		    margin-bottom: .2rem;
			margin-left: .4rem;

		    &:after {
		        content: "";
		        display: block;
		        width: 10px;
		        height: 10px;
		        animation-name: webcast-live-pulse;
		        animation-timing-function: ease-out;
		        animation-duration: 1$generic-transition-speed;
		        animation-iteration-count: infinite;
		        background: red;
		        border-radius: 10px;
		    }

			// Reenable this if we want an offline style later
			// .offline &{
			// 	background: $gray;
			// 	&:after {
			//         display: none;
			//     }
			// }
			// @media screen and (min-width: $mobile-break) {
			// 	.offline &{
			// 		background: rgba(0,0,0,.4);
			// 	}
			// }
		}

		&.offline .webcast-indicator{
			display: none;
		}
    }

	.mobile-translation-button{
		position: static;
		display: inline-flex;

		.translation-dropdown{
			background: $darkest-gray;
			width: 100%;
			top: 50px;
			padding: 1em 0;
			font-size: 1.1em;
			.translation-icon{
				color: $darkest-gray;
				background-color: $gray;
			}
			li{
				color: white;
				display: inline-flex;
				justify-content: center;
				padding: .75em 0;
				&:hover{
					background-color: rgba(white, .1);
				}
				&.active{
					background-color: rgba(white, .2);
				}
				.li-contents{
					min-width: 21rem;
				}
			}
		}
	}

	.solo-nav {
		font-size: 1.5rem;
		padding: 1rem 0;
		background: $darkest-gray;
		clear: right;
		display: none;
		li {

			a {
				display: block;
				height: 100%;
				padding: 1rem 0.9rem;
				line-height: 1.5em;

				@media screen and (max-width: $mobile-break - 1) {
					.mobile-translation-button{
						display: flex;
					}
				}

				&:hover {
					background: rgba(255,255,255,.1);
				}
                .new{
                    &:after{
                        content: '';
                        width: 1rem;
                        height: 1rem;
                        background: white;
						display: inline-block;
						border-radius: 50%;
						margin-left: .7rem;
                    }
                }
			}
		}

		.solo-nav-open & {
			display: block;
		}

	}

	@media screen and (min-width: $header-break) {
		height: 80px;
		text-align: right;
		.solo-block-content {
			padding: 0px 20px;
		}
		.solo-profile-img {
			height: 80px;
		}
		.solo-nav-toggle {
			display: none;
		}
		.mobile-translation-button{
			display: none;
		}
		.solo-nav {
			display: block;
			height: 100%;
			padding: 0;
			background: none;
			margin: 0 0 0 130px;
			> li {
				display: inline-block;
				line-height: 60px;
				a {
					line-height: 60px;
				}
			}
			&.translation-dropdown-button{
				display: flex;
			}
		}
	}
}
