.solo-highlighted-sermon{
    $background-color: black;
    $gradient-offset: 25rem;
    $gradient-size: 12rem;
    $audio-background-cover-opacity: .25;
    $video-background-cover-opacity: .5;
    $waveform-height: 6rem;
    $waveform-bottom-padding: 2.5rem;
    $speaker-photo-size: 3.5em;

    background-color: $background-color !important;
    height: 34rem;
    position: relative;
    display: flex;

    .metadata{
        position: relative;
        z-index: 5;
        display: flex;
        align-self: center;
        padding-bottom: 3em;
        &, a.sermon-item-title{
            color: white;
        }
        a:not(.sermon-item-title):not(.solo-button){
            color: $light-gray !important;
            &:hover{
                color: $lightest-gray !important;
            }
        }
        .type{
            margin-bottom: .5rem;
        }
        .speaker-photo{
            padding: 0 1em;
            .speaker-img{
                width: $speaker-photo-size;
                height: $speaker-photo-size;
            }
        }
        .sermon-item-title{
            font-size: 1.8em;
            font-weight: lighter;
            margin: 1.5rem 0;
        }
        .sermon-item-metadata, .sermon-item-series{
            font-size: .9em;
        }
    }
    .solo-button{
        margin-top: 1em;
    }
    .noHTML5Message{
        display: none;
    }
    .audio-background{
        position: absolute;
        bottom: $waveform-bottom-padding;
        width: 100%;
    }
    .sa_wave-container, .sa_wave_mask{
        height: $waveform-height;
        opacity: 1;
    }
    .video-background{
        padding: 0;
        overflow: hidden;
        align-items: center;
        display: flex;
        &, .image{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .image{
            display: inline-flex;
            justify-content: center;
        }
        img{
            align-self: center;
        }
    }
    .background-cover{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $background-color;
        z-index: 3;
    }

    &.highlighted-video .background-cover{
        opacity: $video-background-cover-opacity;
    }
    &.highlighted-audio .background-cover{
        opacity: $audio-background-cover-opacity;
    }
    .gradient{
        height: 100%;

        &, &:before, &:after{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
        }
        &:before, &:after{
            content: '';
        }
        &:before{
            box-shadow: inset $gradient-offset 0rem $gradient-size -#{$gradient-size} $background-color;
        }
        &:after{
            box-shadow: inset -#{$gradient-offset} 0rem $gradient-size -#{$gradient-size} $background-color;
        }
    }
}
