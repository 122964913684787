.shortcut-icon{
    background: white;
    padding: .1rem .5rem;
    border-radius: .2rem;
    color: $black;
    min-width: 1em;
    height: 1.5em;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    .icon{
        display: inline-block;
    }
}

.player-shortcuts{
    li{
        pointer-events: none;
        display: flex;
        justify-content: space-between;
    }
}

.shortcut-tooltip{
    $dark-background-color: $black;
    $dark-text-color: $lightest-gray;
    $dark-icon-color: $light-gray;
    $light-background-color: white;
    $light-text-color: $gray;
    $light-icon-color: $light-gray;
    $tooltip-hover-in-speed: .6s;
    $tooltip-hover-out-speed: .1s;
    $tooltip-hover-delay: .5s;
    .light &{
        background-color: $light-background-color;
        color: $light-text-color;
        .shortcut-icon{
            color: $light-background-color;
            background-color: $light-icon-color;
        }
    }
    .dark &{
        background-color: $dark-background-color;
        color: $dark-text-color;
        .shortcut-icon{
            color: $dark-background-color;
            background-color: $dark-icon-color;
        }
    }
    border-radius: .4rem !important;
    padding: 0 .75rem;
    z-index: 5;
    pointer-events: none;
    font-size: .9em;
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 3.8rem;
    opacity: 0;
    transition: opacity $tooltip-hover-out-speed ease-in;
    font-size: 1.2rem;
    white-space: nowrap;
    &.right{
        right: 1rem;
    }
    &.center{
        left: 50%;
        transform: translate(-50%, 0);
    }
    &:not(.center):not(.right){
        left: 1rem;
    }
    div:hover > &, a:hover > &{
        opacity: 1;
        transition: opacity $tooltip-hover-in-speed ease-out $tooltip-hover-delay;
    }
    .single-shortcut{
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
    }
    .shortcut-title{
        margin-right: 1em;
    }
    .shortcut, .shortcut-title{
        white-space: nowrap;
        display: inline-block;
    }
    .mini &{
        display: none;
    }
}

.shortcut-stack{
    &.shortcut{
        display: flex;
        align-items: center;
    }
    .shortcut-icon{
        &:first-child{
            margin-right: .5rem;
        }
        &:last-child{
            margin-left: .5rem;
        }
    }
}
