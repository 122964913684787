.scrolls{
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    $scrollbar-width: 0.8rem;
    &.pad.padded{
        padding-right: .4rem;
    }
    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background-color: $lightest-gray;
        .dark & {
            background-color: darken($dark-gray, 10);
        }
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background-color: $light-gray;
        // uncomment if we want inactive scrollbar colors
        // &:window-inactive {
        // 	background-color: lighten($light-gray, 5);
        //     .dark & {
        //         background-color: darken($dark-gray, 5);
        //     }
        // }
    }
    &.rounded{
        &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb{
            border-radius: $scrollbar-width;
        }
    }
    // color options for IE - uncomment if we want to use them
    // scrollbar-base-color: $lightest-gray;
    // scrollbar-arrow-color: $light-gray;
}
