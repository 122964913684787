.stripe-name{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1rem;
    input{
        width: 100%;
    }
}

.stripe-card{
    padding: 1em;
    display: block;
    &.StripeElement--webkit-autofill:not(.disabled){
        background: $primary-bg-color !important;
    }
    &.StripeElement--focus{
        border-color: $primary-accent-color;
    }
    &.StripeElement--complete{
        border-color: $success-color;
    }
    &.StripeElement--invalid{
        border-color: $error-color;
    }
    &.disabled{
        &, > div{
            background: $off-white !important;
            border-color: $lightest-gray;
        }
    }
}

.stripe-card-settings{
    .base{
        color: $black;
        font-weight: 400;
        font-family: $font-family;
        font-size: 1.6rem;
    }
    .placeholder{
        color: $light-gray;
    }
    .invalid{
        color: $error-color;
    }
    .complete{
        color: $success-color;
    }
}
