
[type="checkbox"], [type="radio"]{
    &:not(:checked),
    &:checked {
        position: absolute;
        left: -9999rem;
        user-select: none;
        + label{
            position: relative;
            padding-left: 2.8rem;
            margin-right: 1rem;
            display: inline-block;
            cursor: pointer;
            font-size: 1.1rem;
            line-height: 1.8rem;
            user-select: none;
            .new-tag{
                margin-left: .5rem;
                font-size: .8em;
                color: $primary-accent-color-highlight;
            }
            &:hover:before {
                border-color: $primary-accent-color;
            }
            &:before{
                /* checkbox aspect */
                content: '';
                position: absolute;
                left: 0;
                top: -.1rem;
                width: 1.8rem;
                height: 1.8rem;
                border: .1rem solid $light-gray;
                background-color: $primary-bg-color;
            }
            &:after {
                /* checked mark aspect */
                content: '';
                position: absolute;
                font-size: 1.4rem;
                line-height: 1;
                transition: transform .2s, opacity .2s;
            }
        }
        /* disabled checkbox */
        &:disabled {
             + label{
                 cursor: default;
                 &:before{
                    box-shadow: none;
                    border-color: $gray;
                    background-color: $light-gray;
                 }
                 &:after{
                     opacity: .25;
                 }
             }
        }
    }
    &:not(:checked):not(.partial) {
        + label:after{
            /* checked mark aspect changes */
            opacity: 0;
            transform: scale(0);
        }
    }
    &:checked, &.partial{
        + label:after{
            opacity: 1;
            transform: scale(1);
        }
        &:disabled + label:after{
            color: $dark-gray;
        }
    }

    &:disabled + label {
        color: $light-gray;
    }
    /* accessibility */

    + label.outline{
        outline: none;
        color: $primary-accent-color;
        &:before{
            border-color: $primary-accent-color;
        }
    }
    &:focus + label:before,
    &:not(:checked):focus + label:before{
        border-color: $primary-accent-color-highlight;
    }
}

.disabled-checkbox{
    + label{
        pointer-events: none;
        cursor: default;
        color: $light-gray;
        &:before{
           box-shadow: none;
           border-color: $light-gray;
           background-color: $lightest-gray;
        }
        &:after{
            opacity: .25;
        }
    }
}

[type="checkbox"], [type="radio"]{
    &:not(:checked),
    &:checked {
        /* disabled checkbox */
        &:disabled {
            @extend .disabled-checkbox;
        }
    }
}

[type="checkbox"]{
    &:not(:checked),
    &:checked{
        + label{
            &:after {
                background-image: url('../../svg/regular/check.svg');
                background-position: center;
                background-repeat: no-repeat;
                width: 1.4rem;
                height: 1.4rem;
                top: .2rem;
                left: .3rem;
            }
        }
    }
    &.partial + label:after{
        background-image: url('../../svg/regular/hyphen.svg');
    }
}

[type="radio"]{
    &:not(:checked),
    &:checked {
        + label{
            &:before, &:after{
                border-radius: 50%;
            }
            &:after {
                background: $black;
                top: 0rem;
                left: .1rem;
                width: 1.8rem;
                height: 1.8rem;
                border: .4rem solid $primary-bg-color;
                box-sizing: border-box;
            }
        }
    }
    &.fancy{
        + label{
            background-color: $off-white;
            border-radius: 1em;
            margin-right: $site-button-spacing;
            padding-right: 1rem;
            line-height: 2.4rem;
            font-size: 1.3em;
            color: $light-gray;
            &:before{
                left: .6rem;
                top: .4rem;
                width: 1.3rem;
                height: 1.3rem;
                background: $primary-bg-color;
            }
            &:hover{
                background: $lightest-gray;
                color: $dark-gray;
                &:before{
                    border-color: $dark-gray;
                }
            }
            &:after {
                background: $primary-accent-color;
                width: 1.3rem;
                height: 1.3rem;
                left: .7rem;
                top: .5rem;
            }
        }
        &:checked + label{
            background-color: $primary-accent-color;
            color: $primary-bg-color;
            &:before{
                border-color: $primary-accent-color-dark !important;
            }
            &:after {
                border-width: .3rem;
            }
        }
    }
}
