@use "sass:math";

$grid-col-width: 8.3333333333%;
$grid-gutter-width: 3rem;
$increase-gutter-multiplier: 1.5;
$standard-gutter-divider: 2;
$grid-guide-color-a: rgba(0,200,255,.2);
$grid-guide-color-b: rgba(255,0 ,100,.1);
$mobile-break: $modal-stack-width;
$column-count: 12;

.flex-grid-guides {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	// background: rgba(255,255,255,.1);
	pointer-events: none;
	z-index: 2;

	.flex-grid-guide-max {
		display: flex;
		max-width: 960px;
		padding: 0 $grid-gutter-width - .1rem;
		margin: 0 auto;
		height: 100%;
		border-left: .1rem solid $grid-guide-color-a;
		border-right: .1rem solid $grid-guide-color-a;

		@media screen and (min-width: $mobile-break) {

			.flex-grid-guide-col {
				border-left: .1rem solid $grid-guide-color-b;
				width: 8.3333333333%;
				position: relative;

				&:before, &:after {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					width: .1rem;
					background: $grid-guide-color-a;
				}

				&:before {
					left: math.div($grid-gutter-width, $standard-gutter-divider);
				}
				&:after {
					right: math.div($grid-gutter-width, $standard-gutter-divider);
				}

				&:last-child {
					border-right: .1rem solid $grid-guide-color-b;
				}
			}
		}
	}
}

@mixin gridLayout(){
	display: flex;
	justify-content: flex-start;
    &.increase-gutter{
		> div:nth-child(odd) {
    		padding-right: $grid-gutter-width * $increase-gutter-multiplier;
        }
		> div:nth-child(even) {
    		padding-left: $grid-gutter-width * $increase-gutter-multiplier;
        }
    }
	@for $i from 1 through $column-count {
		.med-#{$i}-col { width: calc(#{$grid-col-width} * #{$i}); }
		.indent-#{$i}-col { margin-left: calc(#{$grid-col-width} * #{$i}); }
	}
}

@mixin columnSize($i){
    width: calc(#{$grid-col-width} * #{$i});
}

.flex-grid-row {
	> div {
		padding: 0 math.div($grid-gutter-width, $standard-gutter-divider);
		width: 100%;
		margin-bottom: 1rem;
	}
	&.align-right, & > .align-right{
		display: flex;
		justify-content: flex-end;
	}
	&.no-break{
		@include gridLayout();
	}
	@media (min-width: $mobile-break) {
		@include gridLayout();
	}
}
