
.embed-browser{
    position: absolute;
    top: 0; right: 0;
    bottom: 0; left: 0;
    background-color: $off-white;
    display: flex;
    flex-direction: row;
    flex-flow: column;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;

    $zindex_browser_body_cover: 5;
    $zindex_filter_option_inner_container: 10;
    $zindex_filter_option_dropdown: 101;
    $zindex_footer: 100;
    $rounded-radius: 1rem;

    &:not(.remove-external-borders){
        border: $generic-border;
    }
    .header, .footer{
        border: $generic-border;
        border-left-width: 0;
        border-right-width: 0;
        width: calc(100% - 3rem);
    }
    .sermons, .sermon, .player-controller, canvas{
        -webkit-transform: translate3d(0,0,0);
    }
    .header{
        $img-width: calc((#{$sermon-browser-header-height} - 3rem) * 1.33 + 1rem);
        background-color: $primary-bg-color;
        padding: 1.5rem;
        height: $sermon-browser-header-height - 3rem;
        position: relative;
        display: none;
        border-top-width: 0;
        > div, a {
            float: left;
        }
        .broadcaster-online-notification{
            padding: .8em .5em;
            .light &{
                background-color: rgba($black, .9);
                &:hover{
                    background-color: rgba($black, .8);
                }
            }
            div{
               padding: 0 .35em;
            }
        }
        .broadcaster-info{
            width: calc(100% - #{$img-width} - 1rem);
            height: $sermon-browser-header-height - 3rem;
            margin-left: 1rem;
            @include breakpointMax($mobile-width){
                width: 100%;
                margin-left: 0;
            }
            *{
                margin: 0;
                font-weight: 400;
            }
            h3{
                text-transform: uppercase;
                font-weight: 1.3rem;
            }
            .title{
                font-size: 2.1rem;
                line-height: 2.6rem;
                color: $darkest-gray;
                font-weight: 600;
                margin: 0rem 0 .8rem;
                @extend .overflow-ellipsis;
            }
            .subtitle{
                font-size: 1.2rem;
                color: $dark-gray;
                height: 2rem;
                @extend .overflow-ellipsis;
            }
        }
        .broadcaster-img{
            width: $img-width;
            img{
                height: $sermon-browser-header-height - 3rem;
            }
            @include breakpointMax($mobile-width){
                display: none;
            }
        }

        .logo-button{
            position: absolute;
            font-size: 1.4rem;
            top: 1.2rem;
            right: 1.5rem;
            .icon{
                fill: $light-gray;
            }
            &:hover .icon{
                fill: $dark-gray;
            }
            .logo-min{
                display: none;
            }
            @media screen and (max-width: $mobile-width){
                .logo-min{
                    display: block;
                }
                .logo{
                    display: none;
                }
            }
        }
    }
    .footer{
        @extend .sermon-list-pagination;
        background-color: $primary-bg-color;
        border-bottom-width: 0;
        position: relative;
        z-index: $zindex_footer;
    }
    .filter-options-dropdown, .browser-body-cover{
        top: .1rem;
    }
    .browser-body-cover{
        background-color: rgba(black, .75);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $zindex_browser_body_cover;
        opacity: 1;
        transition: opacity .35s;
        &.hide{
            opacity: 0;
            pointer-events: none;
        }
    }
    &.show-header .filter-options-dropdown, &.show-header .browser-body-cover{
        top: $sermon-browser-header-height + .1rem;
    }
    .sort-filter-container{
        height: $sermon-browser-sort-filter-height;
        overflow: hidden;
        width: calc(100% - #{$sermon-browser-body-padding} * 2);
        display: none;
        padding: $sermon-browser-body-padding;
        font-size: 1.4rem;
        background: $off-white;
        button .icon{
            vertical-align: inherit !important;
        }
        .main-filter-bar, .filter-options-dropdown .settings-header{
            color: $dark-gray;
            display: flex;
            justify-content: space-between;
            > div{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: calc(100% - 6.75rem);
                + div{
                    width: 6.75rem;
                }
            }
            button{
                border: none;
                font-size: 1.8rem;
                height: $sermon-browser-sort-filter-height;
                padding: 0 1rem;
                position: relative;
                right: -1rem;
                flex: 2;
                .icon + .icon{
                    margin-left: 1rem;
                }
            }
        }

        .filter-options-dropdown{
            $transitionSpeed: .3s;
            $opacityTransitionOffset: .001s;
            left: 0;
            right: 0;
            z-index: $zindex_filter_option_dropdown;
            position: absolute;
            transition: height $transitionSpeed ease-out, opacity $opacityTransitionOffset ease-out;
            height: 30rem;
            overflow: hidden;
            opacity: 1;
            pointer-events: none;
            *{
                pointer-events: all;
            }
            .filter-dropdown-inner-container{
                padding: $sermon-browser-body-padding;
                background: $off-white;
                z-index: $zindex_filter_option_inner_container;
                position: relative;
            }
            &.hide{
                height: 5rem;
                opacity: 0;
                *{
                    pointer-events: none;
                }
                transition: height $transitionSpeed ease-out, opacity $opacityTransitionOffset ease-out $transitionSpeed - $opacityTransitionOffset;
            }
            .uppercase{
                display: inline-block !important;
                text-transform: uppercase;
                height: $sermon-browser-sort-filter-height;
                line-height: $sermon-browser-sort-filter-height;
            }
            .dropdown{
                @include dropdown(1.4rem);
            }
            .dropdown, .search-bar{
                margin: 0;
                width: calc(100% - .2rem);
                margin-top: 1.4rem;
            }

            .sermon-count{
                color: $gray;
                margin-left: 1rem;
                display: inline-block;
                margin-top: 1.4rem;
            }
            .search-bar{
                height: 2.5em;
                input{
                    line-height: 2.5em;
                    font-size: inherit;
                }
                button{
                    width: 2em;
                    font-size: 1.2em;
                }
            }
            .spinner{
                display: inline-block;
                font-size: 1em;
                margin-left: .5rem;
                position: relative;
                top: -.3rem;
            }
            .indent.content-input{
                width: 3.5rem;
                margin-top: 1.9rem;
                font-size: 2.1rem;
                color: $gray;
                text-align: center;
                display: inline-block;
            }
            .filters.dropdown{
                width: calc(100% - 3.5rem - .2rem);
            }
            @include breakpointMin($mobile-width + 1){
                .dropdown, .search-bar{
                    width: auto !important;
                }
                .search-bar{
                    max-width: 45rem;
                }
                .dropdown{
                    margin-right: 1rem;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .indent{
                    display: none !important;
                }
                .sermon-count{
                    margin-top: 2.4rem;
                }
                .spinner{
                    margin-top: 0rem;
                }
            }
        }
        .main-filter-bar{
            position: relative;
            height: 100%;
            // top: -.2rem;
            span{
                margin:0;
                color: $dark-gray;
            }
            .filters-result-line-1{
                // line-height: inherit;
                // display: block;
                top: -.2rem;
                position: relative;
                min-height: 1.8rem;
                @extend .overflow-ellipsis;
            }
            .filters-result-line-2{
                font-size: 1.3rem;
            }
            .filters-result-line-1 .filter-inline-sermon-count{
                margin-left: .5rem;
            }
            button{
                display: flex;
                align-items: center;
            }
            .result-type{
                text-transform: capitalize;
                &.uppercase{
                    text-transform: uppercase;
                    font-size: 1.3rem;
                }
            }
            .search-set{
                color: $primary-accent-color;
            }
        }
    }
    .sermons{
        height: 100%;
        @extend .sermon-list;
    }
    .browser-body{
        padding: 0 $sermon-browser-body-padding $sermon-browser-body-padding;
        flex: 2;
        width: calc(100% - #{$sermon-browser-body-padding} * 2);
        overflow: auto;
    }
    &:not(.show-header){
        .browser-body-cover{
            top: 1rem;
        }
    }
    &:not(.show-filters) .browser-body{
        padding-top: $sermon-browser-body-padding;
    }

    &.remove-sermon-borders .sermons .sermon{
        border-width: 0;
        margin-bottom: .6rem;
        &:last-child{
            margin-bottom: 0;
        }
    }

    &.rounded{
        border-radius: $rounded-radius;
        overflow: hidden;
        &:not(.compact) .sermon{
            border-radius: .6rem;
        }
    }
    &.hide-background{
        background-color: transparent !important;
        .browser-body-cover{
            background-color: transparent;
        }
        .browser-body{
            padding: $sermon-browser-body-padding 0;
            width: 100%;
        }
        &:not(.remove-external-borders){
            border-width: 0;
            .sermons{
                border-top-width: .1rem;
                border-bottom-width: .1rem;
            }
            .header, .footer, .sort-filter-container{
                border-width: .1rem;
                width: calc(100% - #{$sermon-browser-body-padding} * 2 - .2rem);
            }
            .sort-filter-container{
                border-style: solid;
                border-color: inherit;
                // I can't figure out why this was here, but it breaks the following combo:
                // no-header + external borders + hide-background
                // so I am disabling it for now
                // border-top-width: 0;
            }
            .filter-dropdown-inner-container{
                border: $generic-border;
            }
        }
        &.remove-external-borders{
            .footer{
                border-top-width: 0;
            }
            &.show-header:not(.show-filters) {
                .header{
                    border-bottom-width: 0;
                }
            }
        }
        &.rounded{
            border-radius: 0;
            .footer{
                border-radius: $rounded-radius;
            }
            .header{
                border-radius: $rounded-radius $rounded-radius 0 0;
            }
            .filter-dropdown-inner-container, .sort-filter-container{
                border-radius: 0 0 $rounded-radius $rounded-radius;
            }
            &:not(.show-header){
                .filter-dropdown-inner-container, .sort-filter-container{
                    border-radius: $rounded-radius;
                }
            }
            &:not(.show-filters){
                .header{
                    border-radius: $rounded-radius;
                }
            }
        }
    }

    &.compact{
        .browser-body{
            padding: 0;
            width: 100%;
        }
        .filter-dropdown-inner-container, .sort-filter-container{
            border-bottom: $generic-border;
        }
        .sermons{
            padding-right: 0;
            .sermon{
                border-width: 0 0 .1rem 0;
                margin: 0;
                &:last-child{
                    border-bottom-width: 0;
                }
            }
        }
        &.remove-external-borders:not(.show-footer){
            background-color: transparent;
        }
        &.rounded{
            &:not(.show-filters):not(.show-header){
                border-radius: 0 0 $rounded-radius $rounded-radius;
            }
            &:not(.show-footer){
                border-radius: $rounded-radius $rounded-radius 0 0;
            }
            &:not(.show-filters):not(.show-header):not(.show-footer){
                border-radius: 0;
            }
        }
    }

    &.show-header .header, &.show-filters .sort-filter-container{
        display: block;
    }
    &.show-footer .footer{
        display: flex;
    }
    &.remove-external-links{
        a:not(.download-button):not(.popup-video):not(.popup-audio):not(.logo-button):not(.powered-by-url){
            pointer-events: none;
        }
        &:not(.show-header){
            .sermons{
                height: calc(100% - 2rem);
            }
            // may add this later, but currently it's against the established style
            // &.rounded:not(.show-footer){
            //     border-radius: 0 0 $rounded-radius $rounded-radius;
            // }
            &.compact{
                .sermons{
                    height: calc(100% - 3.5rem);
                }
                &:not(.remove-external-borders){
                    border-bottom: $generic-border;
                    .dark &{
                        border-bottom-color: $black;
                    }
                }
            }
            &.compact:not(.remove-external-borders), &.remove-external-borders.show-footer.hide-background, &.remove-external-borders.show-footer.compact{
                .powered-by-sa{
                    margin-left: 1rem;
                }
            }
        }
    }
    .powered-by-sa{
        display: block;
        font-size: 1.2em;
        overflow: visible;
        height: 0;
        line-height: 3em;
    }

    /* dark theme */
    body.dark &{
        &:not(.remove-external-borders){
            border-color: $black;
        }
        &:not(.remove-external-borders):not(.hide-background), &.remove-external-borders:not(.hide-background):not(.compact), &.remove-external-borders.show-footer:not(.hide-background){
            .powered-by-sa{
                color: $lightest-gray;
                a:hover{
                    color: white;
                }
            }
        }
        &:not(.remove-sermon-borders) .sermon{
            border-color: $black;
        }
        .header, .footer{
            background-color: $darkest-gray;
            border-color: $black;
        }
        &.compact{
            .filter-dropdown-inner-container, .sort-filter-container{
                border-bottom-color: $black;
            }
        }
        .sort-filter-container, &{
            background: $dark-gray;
            color: $off-white;
            .main-filter-bar, .filter-options-dropdown .settings-header{
                &, button, span{
                    color: inherit;
                }
            }
            .filter-options-dropdown{
                .filter-dropdown-inner-container{
                    background: $dark-gray;
                    border-color: $black;
                }
                .sermon-count, .indent.content-input{
                    color: inherit;
                }
            }
        }
        .header{
            .title, h3{
                color: white;
            }
            .subtitle{
                color: $light-gray;
            }
            .header-logo{
                .icon{
                    fill: $gray;
                }
                &:hover .icon{
                    fill: $lightest-gray;
                }
            }
        }
    }
}
