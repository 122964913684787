.video-player.message.webcast{
    background-color: $off-white;
    color: $light-gray;
    position: relative;
    overflow: hidden;

    button{
        margin-top: 1rem;
    }
    svg{
        fill: $light-gray;
    }
    a{
        color: $light-gray;
        font-weight: 600;
        &:hover{
            text-decoration: underline;
        }
    }
    .contents > span em{
        font-style: normal;
        white-space: nowrap;
        padding-left: .3em;
    }

}
