
html, body, .overscroll{
    background-color: $secondary-bg-color;
}
// html.site{
//     opacity: 0;
//     transition: opacity .25s;
// }
// html.site.loaded{
//     opacity: 1;
// }

#outdated{
    z-index: 10000000000;
}

$overscroll-offset: 5rem;
$overscroll-offset-safari: 3.5rem;
.overscroll{
    position: fixed;
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
    z-index: -1;
    div{
        position: fixed;
        right: 0;
        left: 0;
        text-align: center;
        font-size: 2em;
        color: $lightest-gray;
        &.top{
            top: -$overscroll-offset;
            .safari &{
                top: $overscroll-offset-safari;
            }
            .safari:not(.popout) &{
                top: $overscroll-offset-safari + $header-height;
            }
        }
        &.bottom{
            bottom: -$overscroll-offset;
            .safari &{
                bottom: $overscroll-offset-safari;
            }
        }
    }
    .safari .sidebar ~ &{
        div{
            left: $sidebar-width;
        }
    }
}

header, .header-dropdown {
    width: 100%;
    height: $header-height;
    position: fixed;
}

header{
    z-index: 10000;
    background-color: $primary-bg-color;
}

.header-dropdown{
    z-index: 10000 - 1;
}

main{
    background-color: $primary-bg-color;
    padding: 0;
    margin: 0;
    overflow: auto;
    min-height: 100vh;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    padding-top: $header-height;
    .popout &{
        padding-top: 0 !important;
        height: 100% !important;
        top: 0 !important;
    }
    .has-breadcrumbs &{
        @include breakpointMin($modal-stack-width + 1) {
            padding-top: $breadcrumb-height + $header-height !important;
        }
    }
    .has-breadcrumbs.popout &{
        @include breakpointMin($modal-stack-width + 1) {
            padding-top: $breadcrumb-height !important;
        }
    }
}

.sidebar{
    width: $sidebar-width;
    max-width: $sidebar-width;
    background: $off-white;
    min-height: 3em;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    top: $header-height;
    .popout &{
        top: 0;
    }
    .has-breadcrumbs &{
        @include breakpointMin($modal-stack-width + 1) {
            top: $breadcrumb-height + $header-height;
        }
    }
    .has-breadcrumbs.popout &{
        @include breakpointMin($modal-stack-width + 1) {
            top: $breadcrumb-height;
        }
    }
    + main{
        width: calc(100% - #{$sidebar-width});
        left: $sidebar-width;
        padding-left: 2em;
        display: block;
        padding-right: 2em;
        .fixed-body-header{
            left: $sidebar-width;
        }
        @include breakpointMax($modal-stack-width) {
            padding: 0;
            &, .fixed-body-header{
                width: 100% !important;
                left: 0 !important;
            }
        }
    }
    @include breakpointMax($modal-stack-width) {
        width: auto;
        max-width: none;
        position: relative;
        top: 0;
        margin-top: $header-height;
        overflow: hidden;
        .popout &{
            margin-top: 0;
            + main{
                height: auto !important;
            }
        }
        + main{
            display: block;
        }
    }
}

.primary-content{
    padding: 2rem;
    width: 100%;
    max-width: 1400px;
    box-sizing: border-box;
    min-height: calc(100vh - #{$header-height});
    margin: $header-height auto 0;
    &.centered-page-contents{
        max-width: 76rem;
    }
}

.splash{
    main{
        text-align: center;
    }
    > h1, > a{
        color: $gray;
    }
    > h1{
        margin: 6rem 0 0;
        line-height: 1.5em;
        font-weight: 300;
    }
    > a{
        padding: 1rem;
        background-color: $gray;
        color: white;
        margin-top: 1rem;
        display: inline-block;
        font-size: 1.1em;
        text-transform: uppercase;
        &:hover{
            background-color: $dark-gray;
        }
        &:active{
            background-color: $darkest-gray;
        }
    }
}


.splash-modal{
    @extend .splash;
    main{
        display: flex;
        background-color: $secondary-bg-color;
        @include breakpointMax($modal-stack-width) {
            flex-direction: column;
        }
    }
    .primary-content{
        max-width: $mobile-width;
        padding: 0;
        @include breakpointMin($mobile-width){
            min-height: auto;
            max-width: 38rem;
            margin: auto;
        }
        .modal{
            padding: 2rem;
            width: 100%;
            background-color: $primary-bg-color;
        }
    }
    &.ie .primary-content{
        // TODO: make a better workaround
        // display: flex;
        // align-items: center;
        // flex-direction: column;
        // height: calc(100vh - #{$header-height} );
        margin-top: 8em;
    }
}

.tab-nav-active-inline-button{
    color: $primary-accent-color !important;
    pointer-events: none;
}

.tab-nav{
    $button-color: $light-gray;
    $dark-button-hover-color: $off-white;
    button{
        border-width: 0;
        border-color: currentColor !important;
        padding: .5em;
        &:not(:hover){
            color: $button-color;
        }
        .dark &:hover{
            color: $dark-button-hover-color !important;
        }
    }
    &:not(.contained){
        button{
            border-bottom-width: .2em;
            background-color: transparent !important;
            + button{
                margin-left: $site-button-spacing;
            }
        }
        .active{
            @extend .tab-nav-active-inline-button;
        }
        &.bold button{
            border-bottom-width: .4em;
            padding: .75em 1.25em;
        }
    }
    &.grayscale{
        button:not(.active):not(:hover){
            border-bottom-width: 0;
        }
        button.active{
            color: $button-color !important;
        }
    }
    &.centered{
        text-align: center;
    }
    &.contained{
        background-color: $off-white;
        .active{
            pointer-events: none;
            background-color: white;
        }
        button{
            padding: 1em 1.25em;
            &, &.active, &:hover{
                color: $darkest-gray !important;
            }
        }
    }
}
