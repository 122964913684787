.status-message{
    padding: 1em;
    font-size: 1.4em;
    border: $generic-border;
    color: $darkest-gray;
    background-color: $secondary-bg-color;
    margin-top: 1em !important;
    margin-bottom: 1.5em !important;
    .icon{
        vertical-align: bottom;
    }
    a{
        text-decoration: underline;
        &:hover{
            color: $dark-gray;
        }
    }
    p{
        color: currentColor !important;
    }
    .status-body *{
        color: inherit;
    }
    p:first-child{
        margin-top: 0;
    }
    p:last-child{
        margin-bottom: 0;
    }
    .status-footer{
        font-size: .75em;
        margin-top: .5em;
        font-style: italic;
        display: flex;
        justify-content: flex-end;
        color: $light-gray;
        .icon{
            margin-right: .5em;
        }
    }
    &.critical, &.error{
        border-color: $error-color-faded;
        color: $error-color-dark;
        background-color: $error-color-light;
        .status-footer{
            color: $error-color-highlight;
        }
        a{
            &:hover{
                color: $error-color-highlight;
            }
        }
    }
    &.warning{
        border-color: $warning-color;
        color: $warning-color-dark;
        background-color: $warning-color-light;
        .status-footer{
            color: $warning-color-highlight;
        }
        a{
            &:hover{
                color: $warning-color-highlight;
            }
        }
    }
    &.info{
        border-color: $primary-accent-color-faded;
        color: $primary-accent-color-dark;
        background-color: $primary-accent-color-light;
        .status-footer{
            color: $primary-accent-color-highlight;
        }
        a{
            &:hover{
                color: $primary-accent-color-highlight;
            }
        }
    }
}
