$waveform-height: 4.4rem;
$waveform-height-legacy: 6.4rem;

.sa_wave-container{
	position: relative;
	width: 100%;
	height: 3em;
    cursor: pointer;
	opacity: .8;
	transition: opacity .35s;
    box-sizing: border-box;
    .playing &, &:hover{
        opacity: 1;
    }
	&, *{
		font-size: 10px;
	}
	&, .sa_wave_mask{
		height: $waveform-height;
        .legacy &{
            height: $waveform-height-legacy;
        }
	}
    .playing &{
        cursor: default;
    }
    .noHTML5 &{
        display: none;
    }
    .muted &{
        opacity: .3;
    }
    .live:not(.offline) & .wave_played{
        width: 100% !important;
    }
}

.sa_wave_canvas{
	&:not(.animating){
        image-rendering: optimizeSpeed;
    	image-rendering: -moz-crisp-edges;
    	image-rendering: -o-crisp-edges;
    	image-rendering: crisp-edges;
    	image-rendering: -webkit-optimize-contrast;
    	image-rendering: pixelated;
    	image-rendering: optimize-contrast;
    	-ms-interpolation-mode: nearest-neighbor;
    }

	user-select: none;
	float: left;
}

.sa_wave_mask{
	position: absolute;
	width: 0;
	overflow: hidden;
    &.wave_scrub{
        opacity: 0;
		transition: opacity $generic-transition-speed;
		.desktop &.show{
			opacity: 1;
		}
    }
    &.wave_background{
    	width: 100%;
    }
}
