.icon, google-cast-launcher{
    height: 1em;
}
google-cast-launcher{
    vertical-align: text-top;
    font-size: 1.1em;
    .available &{
        display: inline-block !important;
    }
}
.icon{
    fill: currentColor;
    .ie &:not(.logo-full):not(.logo){
        max-width: 1em;
    }
    // transform: translate3d(0,0,1px);
    display: inline-block;
    vertical-align: middle;
    pointer-events: none;
    .right &{
        transform: rotate(270deg);
    }
    .left &{
        transform: rotate(90deg);
    }
    .flip &{
        transform: scaleX(-1);
    }

    .ie &.logo{
        width: 8.1em;
    }
    .ie &.logo-full{
        width: 4.85em;
    }
    &.volume{
        .on{ display: inline-block; }
        .off{ display: none; }
        &.muted{
            .on{ display: none; }
            .off{ display: inline-block; }
        }
    }
    &.playback-speed{
        // speed-1: 0.50x
        // speed-2: 0.75x
        // speed-3: 1.00x
        // speed-4: 1.25x
        // speed-5: 1.50x
        // speed-6: 1.75x
        // speed-7: 2.00x
        .one, .two, .point-five, .zero, .point-zero, .point-two, .point-seven, .point-n-zero, .point-n-five {
            display: none;
        }

        &.speed-1 .zero,
        &.speed-1 .point-five,
        &.speed-1 .point-n-zero,
        &.speed-2 .zero,
        &.speed-2 .point-seven,
        &.speed-2 .point-n-five,
        &.speed-3 .one,
        &.speed-3 .point-zero,
        &.speed-3 .point-n-zero,
        &.speed-4 .one,
        &.speed-4 .point-two,
        &.speed-4 .point-n-five,
        &.speed-5 .one,
        &.speed-5 .point-five,
        &.speed-5 .point-n-zero,
        &.speed-6 .one,
        &.speed-6 .point-seven,
        &.speed-6 .point-n-five,
        &.speed-7 .two,
        &.speed-7 .point-zero,
        &.speed-7 .point-n-zero{
            display: inline;
        }
    }
    &.pin, &.pin-live, &.pin-basic {
        .shadow{ fill: rgba(black, .3);}
        .logo, .border, .tower, .circle { fill: white; }
    }
    &.pin{
        .background{ fill: $primary-accent-color-dark; }
    }
    &.pin-live {
        .background{ fill: $live-color; }
    }
    &.location{
        .crossout{
            display: none;
        }
        &.error{
            .crossout{
                display: inline-block;
            }
        }
    }
}

.brand-icon{
    &.facebook{ color: $facebook-brand-color; }
    &.youtube{ color: $youtube-brand-color; }
    &.twitter{ color: $twitter-brand-color; }
    &.dropbox{ color: $dropbox-brand-color; }
    &.twitch{ color: $twitch-brand-color; }
    &.vimeo{ color: $vimeo-brand-color; }
    &.spotify{ color: $spotify-brand-color; }
    &.tune-in{ color: $tunein-brand-color; }
    &.apple{ color: $apple-brand-color; }
    &.google-podcasts{
        .color-1{ fill: #4285f4; }
        .color-2{ fill: #ea4335; }
        .color-3{ fill: #34a853; }
        .color-4{ fill: #fab908; }
        .color-5{ fill: #0066d9; }
    }
    &.stitcher{
        .color-1{ fill: #3590c5; }
        .color-2{ fill: #c53827; }
        .color-3{ fill: #ec8b24; }
        .color-4{ fill: #fbc618; }
        .color-5{ fill: #612268; }
    }
}

.menu-icon{
    width: 1.1em;
    height: 1em;
    position: relative;
    transform: rotate(0deg);
    transition: $generic-transition-speed ease-in-out;
    span {
      display: block;
      position: absolute;
      height: .2em;
      width: 100%;
      background: $darkest-gray;
      border-radius: 0;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2), &:nth-child(3) {
        top: .4em;
      }
      &:nth-child(4) {
        top: .8em;
      }
    }

    &.open span{
        &:nth-child(1), &:nth-child(4) {
          top: .4em;
          width: 0%;
          left: 50%;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
    }
}

.multicast-med{
    .multicast{
        fill: currentColor;
    }
    .webcast-active &{
        .multicast{
            color: $lightest-gray;
        }
    }
}

div.volume-slider {
    width: 4.8em;
    cursor: pointer;
    text-align: left;
    .icon{
        pointer-events: none;
    }
    div.volume-slider-container {
        position: relative;
        div.volume-slider-fill-container {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            color: currentColor;
        }
    }
}
