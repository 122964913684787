@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../../common/Open-Sans/fonts/Open-Sans-300/Open-Sans-300.eot');
  src: url('../../common/Open-Sans/fonts/Open-Sans-300/Open-Sans-300.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light'),
       local('Open-Sans-300'),
       url('../../common/Open-Sans/fonts/Open-Sans-300/Open-Sans-300.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-300/Open-Sans-300.woff') format('woff'),
       url('../../common/Open-Sans/fonts/Open-Sans-300/Open-Sans-300.ttf') format('truetype'),
       url('../../common/Open-Sans/fonts/Open-Sans-300/Open-Sans-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../../common/Open-Sans/fonts/Open-Sans-regular/Open-Sans-regular.eot');
  src: url('../../common/Open-Sans/fonts/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Open Sans'),
       local('Open-Sans-regular'),
       url('../../common/Open-Sans/fonts/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-regular/Open-Sans-regular.woff') format('woff'),
       url('../../common/Open-Sans/fonts/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'),
       url('../../common/Open-Sans/fonts/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../../common/Open-Sans/fonts/Open-Sans-600/Open-Sans-600.eot');
  src: url('../../common/Open-Sans/fonts/Open-Sans-600/Open-Sans-600.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Semibold'),
       local('Open-Sans-600'),
       url('../../common/Open-Sans/fonts/Open-Sans-600/Open-Sans-600.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-600/Open-Sans-600.woff') format('woff'),
       url('../../common/Open-Sans/fonts/Open-Sans-600/Open-Sans-600.ttf') format('truetype'),
       url('../../common/Open-Sans/fonts/Open-Sans-600/Open-Sans-600.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../../common/Open-Sans/fonts/Open-Sans-300italic/Open-Sans-300italic.eot');
  src: url('../../common/Open-Sans/fonts/Open-Sans-300italic/Open-Sans-300italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light Italic'),
       local('Open-Sans-300italic'),
       url('../../common/Open-Sans/fonts/Open-Sans-300italic/Open-Sans-300italic.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-300italic/Open-Sans-300italic.woff') format('woff'),
       url('../../common/Open-Sans/fonts/Open-Sans-300italic/Open-Sans-300italic.ttf') format('truetype'),
       url('../../common/Open-Sans/fonts/Open-Sans-300italic/Open-Sans-300italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../../common/Open-Sans/fonts/Open-Sans-italic/Open-Sans-italic.eot');
  src: url('../../common/Open-Sans/fonts/Open-Sans-italic/Open-Sans-italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Italic'),
       local('Open-Sans-italic'),
       url('../../common/Open-Sans/fonts/Open-Sans-italic/Open-Sans-italic.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-italic/Open-Sans-italic.woff') format('woff'),
       url('../../common/Open-Sans/fonts/Open-Sans-italic/Open-Sans-italic.ttf') format('truetype'),
       url('../../common/Open-Sans/fonts/Open-Sans-italic/Open-Sans-italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: normal;
  font-style: normal;
  src: url('../../common/Open-Sans/fonts/Open-Sans-condensed/Open-Sans-condensed.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-condensed/Open-Sans-condensed.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-style: normal;
  src: url('../../common/Open-Sans/fonts/Open-Sans-condensed-light/Open-Sans-condensed-light.woff2') format('woff2'),
       url('../../common/Open-Sans/fonts/Open-Sans-condensed-light/Open-Sans-condensed-light.woff') format('woff');
}
