
.module-page{
    main{
        background-color: $secondary-bg-color;
    }
    .module-container, .module{
        background-color: $primary-bg-color;
        border: $generic-border;
    }
    .module-container{
        padding: 1.5rem 1.5rem 2rem;
        margin-bottom: 2.3rem;
        border-radius: .5rem;
    }
    .id-indicator.module{
        border: 0;
    }
}
