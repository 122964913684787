.site-share-modal .modal-contents-container{
    max-width: 35rem;
    padding: 0;
    .share-modal{
        background-color: $primary-bg-color;
        color: $black;
        padding: 2rem;
        overflow: auto;
        position: relative;
    }
    .qr-code-container{
        border: .1rem solid black;
    }
    .button-set button{
        padding-left: 0;
        padding-right: 0;
        width: 1.25em !important;
    }
    .button-set .icon-button{
        color: $black;
        &:hover{
            color: $dark-gray;
            background: transparent;
        }
    }
    .copy-code-button, .title{
        margin-bottom: 2.5rem;
    }
    .copy-code-button{
        // border: none;
        padding: .5em 1em 1.5em !important;
        line-height: 1;
    }
    .dismiss{
        color: $black !important;
        &:hover{
            color: $dark-gray !important;
        }
    }
    input{
        font-size: 1.2rem;
    }
}
