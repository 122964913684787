
@use "sass:math";

.video-player {
	$background-black: rgba(0,0,0,.75);
	$waveformHeight: 0.5rem;
	$waveformOpenHeight: 2rem;
	$controlsTransitionSpeed: .2s;
	$playButtonTransitionSpeed: .4s;
	$smallWaveformBreakpoint: 480px;

	width: 100% !important;
	position: relative;
	overflow: hidden;
	user-select: none;
	background: black;
	font-size: 1rem !important;

	.noHTML5Message{
		display: none;
		background: rgba(black, .75);
		font-size: 1.5em;
		margin: auto;
		width: calc(100% - 2em);
		max-width: 72rem;
		padding: 1em;
		line-height: 1.35;
		border-radius: .2em;
		z-index: 100;
		a{
			color: $primary-accent-color-highlight;
			&:hover{
				text-decoration: underline;
			}
		}
		@include breakpointMax($video-medium-breakpoint){
			font-size: 1.4em;
		}
		@include breakpointMax($video-small-breakpoint){
			font-size: 1.2em;
		}
	}
    .noHTML5 &{
		.noHTML5Message{
			display: block;
		}
		.video-player-contents{
			display: flex;
			align-items: center;
		}
		.big-play{
			pointer-events: none;
		}
		.video-player-controls, .big-play-button, .big-cast-button{
			display: none !important;
		}
    }
    .broadcaster-online-notification{
		box-sizing: content-box;
		button{
			border-width: .1rem;
			border-style: solid;
		}
    }
	video {
		width: 100%;
		max-height: 100%;
		align-self: center;
        display: none;
	}
    .spinner-container{
		$spinner-width: 9rem;
		border: none;
		z-index: 3;
		margin: -2.5rem 0 0 math.div(-$spinner-width, 2);
		width: $spinner-width;
		height: 5rem;
		padding-top: .5rem;
		display: block;
		user-select: none;
		pointer-events: none;
		font-size: 3em;
		text-align: center;
		opacity: 0;
		transition: opacity $generic-transition-speed;
		position: absolute;
	    top: 50%;
	    left: 50%;

		.spinner{
			border-color: white;
			opacity: .8;
		}
    }

	.poster{
		position: absolute;
		left: 0; right: 0;
		top: 0; bottom: 0;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 1;
	}
	&.playing, &.paused, &.autoplaying{
		&:not(.chromecasting){
			video{
				display: block;
			}
			.poster{
				display: none !important;
			}
		}
	}

	.ie & .icon.logo-min{
		max-width: 2em;
	}

    .waveform {
    	height: $waveformHeight;
    	transition: height $controlsTransitionSpeed, margin $controlsTransitionSpeed;
    	position: relative;
    	overflow: hidden;
    	.sa_wave-container{
    	    height: $waveformHeight;
    		width: 100%;
    		opacity: 1;
    		position: absolute;
    		bottom: 0;
    		&.animatingOut{
    			height: $waveformOpenHeight;
    		}
    	}
		// i dont believe we need this anymore
    	// @include breakpointMax($smallWaveformBreakpoint){
    	// 	height: calc(#{$waveformHeight} / 2);
		//
    	// 	.sa_wave-container{
    	// 		height: calc(#{$waveformHeight} / 2);;
    	// 	}
    	// }
    }

	&:not(.simple-waveform) .waveform{
		margin: 1rem 0rem 0 0rem;
	}

    .big-play{
    	$bigPlayButtonWidth: 4em;
    	$bigPlayButtonHeight: 2em;
        position: absolute;
        left: 0; right: 0;
        top: 0; bottom: 0em;
		color: white;
		cursor: pointer;
		transition: bottom $controlsTransitionSpeed;
		z-index: 1;
		.big-play-button, .big-cast-button{
			position: absolute;
	        left: 50%;
	        top: 50%;
			opacity: 0;
			transform: scale(0);
			transition: background $controlsTransitionSpeed, opacity $playButtonTransitionSpeed, transform math.div($playButtonTransitionSpeed, 2);
			text-align: center;
		}
		.big-play-button, .big-cast-button .cast{
			border-radius: 40px;
			cursor: pointer;
		}
		.big-cast-button{
			$bigCastButtonWidth: 20em;
			$castPadding: 1em;
			margin-top: calc(#{$bigPlayButtonHeight} / -2 + #{$bigPlayButtonHeight} + 3rem);
			width: $bigCastButtonWidth;
			font-size: 1.2em;
			margin-left: calc(#{$bigCastButtonWidth} / -2);
			background: transparent;
			color: $light-gray;

			@include breakpointMax($breakpoint-large){
				margin-top: calc(#{$bigPlayButtonHeight} / -2 + #{$bigPlayButtonHeight} + 1.5rem);
			}
			google-cast-launcher{
				box-sizing: initial;
			}
			google-cast-launcher, .icon, span{
				padding: $castPadding * .75 $castPadding;
				font-size: 1em;
				color: currentColor !important;
			}
			google-cast-launcher, .icon{
				width: 1em;
				padding-right: math.div($castPadding, 4);
				vertical-align: middle;
			}
			span{
				padding-left: math.div($castPadding, 4);
				position: relative;
				top: .1rem;
				display: inline-block;
			}
			.cast{
				display: inline-block;
				background: rgba($background-black, .65);
				&:hover{
					color: $lightest-gray;
				}
			}
		}
		.big-play-button {
			margin-top: calc(#{$bigPlayButtonHeight} / -2);
			margin-left: calc(#{$bigPlayButtonWidth} / -2);
			background: $primary-accent-color;
			width: $bigPlayButtonWidth;
			line-height: calc(#{$bigPlayButtonHeight} * .9);
			font-size: calc(#{$bigPlayButtonHeight} * 1.5);
			height: $bigPlayButtonHeight;

			.icon {
				margin-left: .2em;
			}

			@include breakpointMax($breakpoint-large){
				font-size: $bigPlayButtonHeight;
			}

			&:hover {
				background: $primary-accent-color-highlight;
			}
		}

		.video-details-bg {
			position: absolute;
			background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
			left: 0;
			right: 0;
			top: 0;
			height: 12rem;
		}
    }

    .big-play .video-details-bg,
    .video-details,
    .embed-contents {
    	opacity: 0;
		transition: opacity $controlsTransitionSpeed;
	}

    .video-details, .embed-contents{
        z-index: 1;
    }

	.video-details {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 0;
		overflow: visible;
		white-space: nowrap;
		@include title-switcher(540px);
		font-size: 1.3em;
		user-select: none;
		.video-title, .video-subtitle {
			margin-left: 2rem;
			line-height: 1.5em;
			&, a, span {
				color: white;
			}
			a:hover {
				text-decoration: underline;
			}
		}

		div {
			float: left;
			clear: left;
			max-width: calc(100% - 3rem);
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.video-title {
			margin-bottom: .2em;
			margin-top: 2rem;
			> a, > span {
				font-size: 1.5em;
			}
		}

		@include breakpointMax($smallWaveformBreakpoint){
			.video-subtitle {
				display: none;
			}
		}
	}

	&.video-embed {
		.video-details div {
			max-width: calc(100% - 25rem);

			@include breakpointMax($breakpoint-medium){
				max-width: calc(100% - 9rem);
			}
		}
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	&:not(.video-embed){
		height: 0;
		padding-bottom: 56.25%;
	}

	.video-player-contents {
		z-index: 2;
	    position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    top: 0;
		display: flex;
		.video-player-controls {
			transition: padding-top $controlsTransitionSpeed, background $controlsTransitionSpeed, bottom $controlsTransitionSpeed;
			position: absolute;
			bottom: -4rem;
			left: 0;
			right: 0;
			background: none;
            z-index: 2;
			.control{
                &.play{
    				.icon, span:not(.shortcut-icon){
    					display: none;
    				}
    			}
                &.fullscreen{
                    .icon.fullscreen-exit{
                        display: none;
                    }
                }
            }
		}

		.embed-contents {
			font-size: 1.3em;
			// background: rgba(255,0,0,.2);
			display: flex;
			position: absolute;
			top: 0;
			right: 0;
			height: 6rem;
			padding: 2rem;
			color: white;

			> div, > a{
	            display: block;
	            float: left;
	            margin-left: 1rem;
	            color: currentColor;
	            .dark &{
	                color: $light-gray;
	            }

	            &.play-count, &.viewer-count {
	            	margin-left: 0;
	            }

	            @include breakpointMax($breakpoint-micro){
	                &.logo, .share{
	                    display: none !important;
	                }
	            }
	            @include breakpointMax($breakpoint-medium){
	                &.play-count, &.download-button, &.pdf-download-icon{
	                    display: none;
	                }
	                &.share-open{
	                    margin-right: 0;
	                }
	            }
	        }
			.share{
				max-width: 1em;
			}


			.logo {
				color: currentColor;
				.small {
					display: none;
				}
				&:hover{
                    color: $lightest-gray;
                }
				@include breakpointMax($breakpoint-medium){
		            .full{
		                display: none;
		            }
		            .small{
		                display: block;
		            }
		        }
			}


	        .play-count, .viewer-count {
	        	font-size: .8em;
	        	line-height: 2.1em;

	        	.icon {
		        	width: 1em;
		        	font-size: .75em;
		        	vertical-align: baseline;
		        }
	        }
			.viewer-count{
				.icon{
					width: 1.5em;
				}
			}
		}

		.share-window .copy-code-button {
			border-width: .1rem;
			border: .1rem solid currentColor;
		}

        .share-window{
            border-radius: 0 !important;
			width: 32em !important;
			max-width: calc(100% - 5em);
			@include breakpointMax(320px){
				.qr-share{
					display: none;
				}
			}
        }

		.player-controller, .time-controller {
			display: flex;
			.group {
				flex: 1;
				display: flex;
				&.center-group {
					// flex: 2;
					justify-content: center;
				}
				&.right-group {
					justify-content: flex-end;
				}
				.control, a, a:visited {
                    height: 2.25em;
                    text-align: center;
                    display: inline-block;
                    height: 100%;
                    position: relative;
					width: 3.5rem;
                }
				.control, a, a:visited, div.volume-slider{
                    line-height: 2em;
                    font-size: 1.4em;
                    color: $light-gray;
                    &:hover {
                    	color: $lightest-gray;
                    }
				}
                &.center-group{
                    .control, .play-button-container{
                        line-height: 1.5em;
                        font-size: 1.8em;
                    }
                }
			}
		}

		.controls-top {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 0;
		}

		.time-controller {
			transition: opacity $controlsTransitionSpeed, right $controlsTransitionSpeed;
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			pointer-events: none;
			font-size: 1.3em;

			.time {
				position: relative;
				transition: opacity $controlsTransitionSpeed;
				&.current, &.live{
					display: none;
				}
				.shortcut-tooltip{
					font-size: 1em;
					padding: .5rem .75rem;
				}
				span, input {
					width: 7rem;
					margin: 1rem;
					border-radius: 2rem;
					text-align: center;
					font-size: 1em;
					pointer-events: auto;
					transition: margin-bottom $controlsTransitionSpeed;
				}

				span {
					line-height: 1.85em;
					color: white;
					display: inline;
					background: rgba(0,0,0,.5);
				}

				input {
					padding: 0 0 .1rem;
					border: none;
					outline: 0;
					line-height: .5em;
					display: none;
				}

				&.input {
					span {
						display: none;
					}
					input {
						display: inline;
					}
				}

				&.hover {
		    		position: absolute;
		    		left: 50%;
                    opacity: 0;
                    transition: opacity $generic-transition-speed;
		    		margin-left: -4.5rem; //width of child span + left and right margin of 1rem
                    &.show{
                        opacity: 1;
                    }
		    	}
			}
		}

		.player-controller {
			padding: .5rem 0 0;

			.group {
				height: 3.5em;
				text-align: center;
				div, a{
                    &.skip-backward, &.skip-forward {
                        position: relative;
                    }
                    &.play-button-container{
                        width: 100%;
                        max-width: 2em;
                        box-sizing: content-box;
                        display: inline-block;
                        transition: padding $play-transition-speed, max-width $play-transition-speed;
                        > .play{
                            width: .9em;
                            max-width: none;
                            font-size: 1em;
                            background: transparent;
                            border-radius: 1.5em !important;
                            top: 0;
                            padding: 0 .5em !important;
                            position: relative;
                            left: 0;
                            box-sizing: content-box;
                            transition: all $play-transition-speed;
                            .icon.play{
                                margin-left: .2em;
                            }
                            .icon{
                                transition: fill $play-transition-speed;
                            }
                        }
                    }

                     @include breakpointMax(350px){
			            &.skip-backward,
			            &.skip-forward{
			                display: none !important;
			            }
			        }
                }
                .mute {
                	width: 2em;
                	text-align: right;
                	margin-right: .4rem;
                }
                &.left-group div:first-child.speed{
					// better padding when volume has been removed
					margin-left: .5rem;
                }
				.ie &{
					.mute .icon{
						max-width: 1.5em;
					}
					.speed .icon{
						max-width: 2.5em;
					}
					.volume-slider-container .icon{
						max-width: 4em;
					}
				}
			}
		}
	}

	$videoSettingsWidth: 28rem;
	.video-settings {
		position: absolute;
		right: calc(#{$videoSettingsWidth} * -1 - 1rem);
		bottom: 0rem;
		width: $videoSettingsWidth;
		background: rgba(0,0,0,.75);
		transition: right $controlsTransitionSpeed;
		font-size: 1.3em;
		line-height: 1.5em;
		color: $lightest-gray;
		text-align: left;
		overflow: hidden;
		.bitrate{
			opacity: .65;
			margin-left: .3rem;
			font-size: .9em;
		}
		.submenu.animating .scrolls{
			overflow: hidden;
		}
		.scrolls{
			max-height: calc(100% - 3.8rem);
		}
		.title {
			text-transform: uppercase;
			margin-top: .5rem;
			padding: .6rem 1.5rem;
			.icon{
				margin-right: .5rem;
				&.angle-left{
					margin-right: 1rem;
				}
			}
		}
		.submenu .title{
			cursor: pointer;
			&:hover{
				background: rgba(white, .2);
			}
		}
		.menu .close{
			margin: 0 -0.4rem 0 auto;
			padding: .4rem;
			cursor: pointer;
			height: 1.6em;
			pointer-events: all;
			&:hover{
				background: rgba(white, .2);
			}
		}
		.menu, .submenu{
			&:not(.noAnimate){
				transition: height $controlsTransitionSpeed, opacity $controlsTransitionSpeed;
			}
			opacity: 1;
			&:not(.open){
				opacity: 0;
				pointer-events: none;
				overflow: hidden;
			}
		}

        .submenu{
            .translation-dropdown{
				li{
					width: calc(100% - 3rem);
					display: flex;
					justify-content: space-between;
					margin-bottom: 0 !important;
					&:after{
						content: '';
						background-image: url('../../svg/light/check-white.svg');
						font-size: 1.3em;
						width: 1em;
						height: 1em;
						float: right;
						display: inline-block;
						background-repeat: no-repeat;
						background-position: center center;
						opacity: 0;
					}
					&.active{
						background: transparent;
						&:after{
							opacity: 1;
						}
					}
				}
				background: transparent;
                position: relative;
				min-width: 0;
				color: white;
				overflow: auto;
				// blue
				// @extend .scrolls;
            }
        }

		.title, li{
			display: flex;
			align-items: center;
		}

		.current-quality-label{
			margin-left: .5rem;
			font-style: italic;
			opacity: .75;
			font-size: .85em;
		}

		.submenu-list li, .submenu .title{
			> .translation-icon{
				width: 1.3em !important;
				background-color: transparent;
				font-size: .9rem;
				border: .1rem solid $lightest-gray;
				padding: 0 .7rem;
				margin-right: .5rem;
			}
		}

		li{
			cursor: pointer;
			padding: .5rem 1.5rem;
			&:hover {
				color: white;
				background: rgba(white, .4);
			}
			.icon{
				&:not(:last-child){
					width: 1.3em;
					margin-right: .5rem;
				}
				&:last-child{
					margin: 0 0 0 auto;
				}
			}
			.shortcut-icon .icon:last-child{
				margin: auto;
			}
		}
		.angle-right, .angle-left{
			font-size: 1.6em;
		}
		ul{
			margin: 0;
			padding: 0;
		}

		ul.quality-options {
			.icon {
				height: 1.3em;
				display: none;
			}

			li {
				&.current .icon{
					display: block;
				}
			}
		}
	}

	&.settings-open {
		.video-settings {
			right: 0rem;
		}

		.time-controller {
			right: calc(#{$videoSettingsWidth} + 1rem);
			.duration {
				opacity: 0;
			}
		}
	}

    &.paused{
		.video-player-contents .video-player-controls {
			padding-top: .8rem;
			background: $background-black;
			bottom: 0;
		}
		&:not(.simple-waveform){
			.waveform {
         		margin-top: 0;
				height: $waveformOpenHeight;
				.sa_wave-container{
					height: $waveformOpenHeight;
				}
				@include breakpointMax($smallWaveformBreakpoint){
		    		height: calc(#{$waveformOpenHeight} / 2);

		    		.sa_wave-container{
		    			height: calc(#{$waveformOpenHeight} / 2);;
		    		}
		    	}
			}
		}
    }

	&.archive .current.time{
		display: flex !important;
	}

    &.webcast{
		// this hides the controls altogether - use it if we need it
		// .video-player-contents .video-player-controls{
		// 	bottom: -5rem;
		// }
		.speed{
			display: none !important;
		}
		.video-player-controls{
			padding-top: 0rem;
		}
		// this leaves the LIVE and duration indicators on screen when controls
		// are closed. We disabled this feature because it interferes with
		// some user's overlays
		// .time-controller{
		// 	opacity: 1;
		// }
		.live.time{
			display: flex !important;
			cursor: pointer;
			span{
				width: auto;
				min-width: 5rem;
				margin: 1rem;
				padding: 0 1rem;
				color: white;
				display: flex;
				vertical-align: middle;
				justify-content: center;
				align-items: center;
				&:before{
					content: '';
					color: $light-gray;
					width: .6rem;
					margin-right: .6rem;
					height: .6rem;
					border-radius: 100%;
					background-color: currentColor;
				}
				&:after{
					content: '';
					width: .5rem;
				}
			}
		}
        &.live, &.unplayed{
			.skip-forward{
				opacity: .5;
				cursor: default;
				pointer-events: none;
			}
			.live.time{
				cursor: default;
				span:before{
		            color: $error-color;
		        }
			}
		}
    }

	&.controls-open, &.settings-open {
		.video-player-controls {
			padding-top: .8rem;
			background: $background-black;
			bottom: 0;
			transition: padding-top $controlsTransitionSpeed cubic-bezier(0.215, 0.610, 0.355, 1.000) calc(#{$controlsTransitionSpeed} * .5), background $controlsTransitionSpeed, bottom $controlsTransitionSpeed ease-out;
		}

		&:not(.simple-waveform){
			.waveform {
				height: $waveformOpenHeight;
				margin-top: 0;
				transition: height $controlsTransitionSpeed cubic-bezier(0.645, 0.045, 0.355, 1.000) calc(#{$controlsTransitionSpeed} * .5), margin $controlsTransitionSpeed cubic-bezier(0.645, 0.045, 0.355, 1.000) calc(#{$controlsTransitionSpeed} * .5);
				.sa_wave-container{
					height: $waveformOpenHeight;
					transition: height $controlsTransitionSpeed cubic-bezier(0.645, 0.045, 0.355, 1.000) calc(#{$controlsTransitionSpeed} * .5);
				}
				@include breakpointMax($smallWaveformBreakpoint){
		    		height: calc(#{$waveformOpenHeight} / 2);

		    		.sa_wave-container{
		    			height: calc(#{$waveformOpenHeight} / 2);;
		    		}
		    	}
			}
		}
	}
	&.unplayed, &.controls-open, &.paused {
		.big-play .video-details-bg,
		.video-details,
		.time-controller,
		.embed-contents {
			opacity: 1;
			transition: opacity $controlsTransitionSpeed, right $controlsTransitionSpeed;
		}
	}

	&.unplayed, &.paused{
        &:not(.ended){
            .control.play{
    			.play{
    				display: inline-block !important;
    			}
    		}
        }
	}
	&.ended{
		.control.play{
			.redo{
				display: inline-block !important;
			}
		}
	}
	&:not(.unplayed):not(.paused):not(.ended){
		.control.play{
			.pause{
				display: inline-block !important;
			}
		}
	}
	&:not(.unplayed){
		.time.current span{
			cursor: pointer;
			&:hover{
				color: $primary-accent-color;
				background-color: rgba(white,.75);
			}
		}
	}
	&.waiting{
		.spinner-container{
			opacity: 1;
		}
	}
	&.unplayed{
		.big-play-button, .big-cast-button {
			opacity: 1;
			transform: scale(1);
		}
		&:not(.simple-waveform){
			.time-controller .time{
				span, input{
					margin-bottom: 0;
				}
			}
		}
	}
	&.unplayed, &.webcast:not(.controls-open) {
		.video-player-contents {
			.video-player-controls {
				bottom: -4.5rem;
			}
		}
	}
	&:fullscreen{
		.control.fullscreen{
			.icon.fullscreen-exit{
				display: inline-block !important;
			}
			.icon.fullscreen{
				display: none !important;
			}
		}
	}

	&:not(.controls-open):not(.paused):not(.unplayed):not(.message){
		&, *{
			cursor: none !important;
		}
	}

	&.message{
		color: white;
		font-size: 1.3em;
		@media screen and (max-width: $video-medium-breakpoint){
			font-size: 1em;
		}
		@media screen and (max-width: $video-small-breakpoint){
			font-size: .8em;
		}
        &.no-sermon{
            background: $black;
        }
		.contents{
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			svg{
				width: 100%;
				font-size: 3em;
				fill: white;
			}
		}
		.contents > span{
			text-align: center;
			font-weight: 300;
			font-size: 1.8em;
			width: calc(100% - 2rem);
			padding: 0 1rem;
			display: inline-block;
			margin-bottom: 1.5em;
		}
	}

	.audio-mode-text{
		display: none;
		font-size: 1.4rem;
		justify-content: center;
		vertical-align: middle;
		flex-wrap: wrap;
		position: absolute;
		top: 50%;
		left: 0; right: 0;
		margin-top: -4rem;
		div{
			text-align: center;
			width: 100%;
			margin: .5rem 0;
		}
	}
	&.audio-mode:not(.unplayed){
		background: mix($dark-gray, $darkest-gray, .5);
		color: white;
		video, .poster{
			display: none;
		}
		.audio-mode-text{
			display: flex;
		}
		.icon.logo-full{
			margin-top: 1rem;
			font-size: 1.6em;
		}
	}
	&.audio-only{
		li[data-menu-toggle="submenu-quality"]{
			display: none;
		}
		&.unplayed{
			.audio-only-message .contents{
				opacity: .25;
			}
		}
		.audio-only-message, .audio-icon{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.contents{
			width: 80%;
			max-width: 90rem;
			display: flex;
			font-size: 1.75em;
			opacity: 1;
			transition: opacity $controlsTransitionSpeed;

			@include breakpointMax($video-large-breakpoint){
				font-size: 1.5em;
			}
			@include breakpointMax($video-medium-breakpoint){
				font-size: 1.25em;
				width: calc(100% - 2.5em);
				margin-left: 2.5em;
			}
			@include breakpointMax($video-small-breakpoint){
				font-size: 1em;
			}
		}
		.audio-only-message{
			position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    right: 0;
			color: white;
			background: linear-gradient(180deg, black 25%, darken($darkest-gray, 2) 100%);
			.title{
				font-size: 3em;
				font-weight: 300;
				margin-bottom: .2em;
				line-height: 1;
				margin-top: 0;
			}
		}
		.message{
			margin-left: 1.5em;
			font-size: 1em;
			margin-top: -.4em;
		}
		.audio-icon{
			background: $primary-accent-color;
			align-self: center;
			height: 1.75em;
			width: 1.75em;
			font-size: 2.5em;
			border-radius: 50%;
			flex-shrink: 0;
		}
	}
    &.hide-controls{
		.video-player-controls, .big-play{
			display: none;
		}
    }
	.airplay-video{
		vertical-align: text-bottom;
	}
	&.airplaying .speed{
		width: 5rem !important;
		.shortcut-tooltip{
			left: 1em;
			transform: none;
		}
	}
	&.airplaying{
		.big-play{
			background: rgba(white, .2);
		}
	}
}
