
$toast-length: 5s;
$margins: 1.5rem;
$side-offset: 10rem;

.toasts-container{
    position: fixed;
    top: 5rem;
    right: 0;
    left: 0;
    padding-left: $margins;
    padding-right: $margins;
    z-index: 10000000;
    pointer-events: none;
    @media screen and (min-width:480px) {
        left: auto;
    }
}

.toast{
    z-index: 100000;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    opacity: 0;
    animation: toast $toast-length;
    font-size: 1.3rem;
    padding: 1.5rem 2rem;
    margin-top: $margins;
    border-width: .1rem;
    border-style: solid;
    box-shadow: $box-shadow;
    line-height: 1.5em;
    cursor: pointer;
    pointer-events: all;

    &.alert{
        background-color: $error-color-light;
        border-color: $error-color;
        color: $error-color-dark;
    }
    &.warning{
        background-color: $warning-color-light;
        border-color: $warning-color;
        color: $warning-color-dark;
    }
    &.success{
        background-color: $success-color-light;
        border-color: $success-color;
        color: $success-color-dark;
    }
    &.info{
        color: $primary-accent-color-dark;
        background-color: $primary-accent-color-light;
        border-color: $primary-accent-color;
    }
    .error-messages{
        font-size: .8em;
    }
    span + span{
        margin-top: .5em;
        padding-top: .5em;
        border-top: .1em solid currentColor;
    }

    @media screen and (min-width:480px) {
        width: 40rem;
        font-size: 1.5rem;
    }

}

@keyframes toast {
    0% {
        right: -$side-offset;
        opacity: 0;
    }
    3% {
        right: 1rem;
    }
    5% {
        opacity: 1;
    }
    10% {
        right: 0rem;
    }
    90% {
        right: 0rem;
        opacity: 1;
    }
    100% {
        opacity: 0;
        right: -$side-offset;
    }
}
