.solo-sermons {
	background: $primary-bg-color !important;
	padding-top: 0;
}

.solo-sermons-nav {
	background: $off-white;

	.solo-block-content {

		padding: 0;

		ul {
			margin: 0;
			padding: 0;

			li {
				border-top: 1px solid $lightest-gray;

				a {
					display: block;
					padding: .8rem 2rem;

					&:hover {
						background: white;
					}
				}

				&.current-tab a {
					background: $lightest-gray;
					font-weight: bold;
				}
			}
		}
	}

	.solo-sermon-search{
		display: flex;
		align-items: center;
		padding: 0 2rem;
		margin: 1rem 0 0 auto;

		.search-bar {
			width: 100%;
			height: auto;
			// align-items: center;
			display: flex;

			*{
				float: none;
			}
			input {
				// margin-top: .2rem;
				margin-left: 0;
				vertical-align: middle;
				padding-bottom: .5rem;
				order: 1;
				min-width: 20rem;
				width: 100% !important;
				&:focus{
					border-bottom: .1rem solid $light-gray;
				}
			}

			.search-button, .search-close-button {
				border-bottom: 4px solid rgba(0,0,0,0);
				height: 3.4rem;
				padding: 0 10px 10px !important;

				&:hover {
					background: none;
					border-bottom: 4px solid $light-gray;
				}
			}

			.search-button {
				width: 4rem !important;
				border-left: none;
				order: 3;
			}
			.search-close-button{
				order: 2;
			}

			@media screen and (min-width: $solo-nav-inline-break) {
				min-width: 22rem;
			}
		}
	}

	@media screen and (min-width: $solo-nav-inline-break) {

		.solo-block-content {
			padding: 2rem 2rem 0;
			display: flex;
			padding-bottom: 0;
			justify-content: space-between;
            flex-wrap: wrap;

			ul {
				margin-top: 1rem;
				margin-left: 10px;
				display: flex;
				margin-bottom: 0;

				li {
					border-top: none;

					a {
						white-space: nowrap;
						padding: .5rem 1rem 1rem;
						border-left: 0px;
						border-bottom: 4px solid rgba(0,0,0,0);
						height: 3.4rem;
					}

					a:hover {
						background: none;
						border-bottom: 4px solid $light-gray;
					}

					&.current-tab a {
						font-weight: normal;
					}
				}
			}
		}

		.solo-sermon-search {
			padding: 0;
		}
	}
}

.speaker-round {
	position: relative;
	.speaker-img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0; bottom: 0;
		left: 0; right: 0;
	}
}

.solo-sermon-breadcrumbs {
	margin-bottom: 3rem;
	font-size: 1.2em;
	padding-left: 1rem;

	span {
		margin-right: 1rem;
	}
}

.solo-sorting-options {
	padding-bottom: 2rem;
	border-bottom: 1px solid $light-gray;
	.icon{
		margin-right: .5rem;
		vertical-align: bottom;
	}

	span{
		user-select: none;
	}
	ul{
		margin-top: 1rem;
		li.disabled {
			color: black;
		}
	}

	.search-applied & {
		margin-top: 1rem;
	}
}

.solo-series-list, .solo-event-type-list, .solo-year-list{
	padding: 0;
	flex-wrap: wrap;

	li{
		margin-bottom: 2rem;
        padding: 0 1rem;
	}
}

@mixin randomBackgroundsNth($count: 300, $opacity: 1, $desaturation: 0){
    @for $i from 1 through $count {
        &:nth-child(#{$i}) .random-background{
            background: desaturate(rgb(random(255), random(255), random(255), $opacity), $desaturation);
        }
    }
}

.solo-series-list{
    display: flex;
}
.solo-series-list li{
    @include randomBackgroundsNth(200, .7, 60);
    @include columnSize(12);
    @media screen and (min-width: 420px) {
        @include columnSize(6);
    }
    @media screen and (min-width: 720px) {
        @include columnSize(4);
    }
    @media screen and (min-width: 960px) {
        @include columnSize(3);
    }
    a{
        display: block;
        > div{
            border-radius: .5rem;
            overflow: hidden;
            border: 1px solid $lightest-gray;
        }
    }
    .album-art{
        width: 100%;
        display: block;
        color: white;
        font-size: 6em;
        line-height: 0;
        &:not(.random-background){
            background-color: $secondary-bg-color;
        }
    }
    div.album-art{
        position: relative;
        .icon-container{
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .spacer{
            padding-top: 100%;
            width: 100%;
        }
    }
    .metadata{
        padding: 1rem;
        background-color: $primary-bg-color;
        border-top: $generic-border;
        .date-info{
            color: $light-gray;
        }
    }
    .title{
        font-size: 1.2em;
        margin-bottom: .5em;
        font-weight: bolder;
    }
}

.solo-event-type-list li {
    display: block;
    a {
        display: block;
        border: 1px solid $lightest-gray;
        border-radius: 3px;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
        transition: box-shadow .1s, transform .1s;

        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,.1);
        }
    }

    .title {
        background: $off-white;
        font-weight: lighter;
        font-size: 1.5em;
        padding: 1rem;
        height: 100px;
    }
    .metadata {
        padding: 1rem;
        font-size: .8em;
        line-height: 1.5em;
    }
	@media screen and (min-width: $mobile-break) {
        display: inline-block;
	}
}

.solo-year-list {

	li {
		display: inline-block;
		a {
			background: $off-white;
			padding: 1rem;
			display: block;
			border: 1px solid $lightest-gray;
			border-radius: 3px;
			box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
			transition: box-shadow .1s, transform .1s;

			&:hover {
				transform: scale(1.05);
				box-shadow: 0px 0px 20px 0px rgba(0,0,0,.1);
			}

			.solo-year {
				font-weight: lighter;
				font-size: 3em;
			}

			.solo-year-metadata {
				font-size: .8em;
			}
		}
	}
}

.solo-bible-book-list {
	padding: 0;
	column-count: 2;
	@media screen and (min-width: $mobile-break) {
		column-count: 3;
	}

	li {
		display: block;
		line-height: 3.5rem;
		color: white;

		@media screen and (max-width: $mobile-break) {
			padding-left: 1.5rem;
		}

		.solo-bible-sermon-count {
			text-align: center;
			display: inline-block;
			width: 2.5rem;
			line-height: 2.5rem;
			border-radius: 2rem;
			margin-right: 1rem;
		}

		&.no-sermons {
			a {
				color: $light-gray;
			}

			.solo-bible-sermon-count {
				background-color: $light-gray;
			}
		}
	}
}

.solo-speaker-divider{
	border-bottom: 1px solid $light-gray;
	padding: 1rem;
}

.solo-speaker-list {
	padding: 0;

	$speaker-min-width: 200px;
	$large-parent-padding: 60px;
	$small-parent-padding: 40px;
	$speaker-column-2: $small-parent-padding + $speaker-min-width * 2;
	$speaker-column-3: $large-parent-padding + $speaker-min-width * 3;
	$speaker-column-4: $large-parent-padding + $speaker-min-width * 4;
	$speaker-column-5: $large-parent-padding + $speaker-min-width * 5;

	li {
		display:inline-block;
		vertical-align: top;
		min-width: $speaker-min-width;
		height: 18rem;
		width: 100%;

		@include breakpointMin($speaker-column-2) {
			width: 50%;
		}
		@include breakpointMin($speaker-column-3) {
			width: 33.3333332%;
		}
		@include breakpointMin($speaker-column-4) {
			width: 25%;
		}
		@include breakpointMin($speaker-column-5) {
			width: 20%;
		}

		a {
			display: block;
			padding: 1rem;
			transition: background-color .2s;
			border-radius: .3rem;
			margin: auto;
			width: $speaker-min-width;

			.speaker-round {
				width: 10rem;
				height: 10rem;
				margin: 0 auto 1rem;
				transition: box-shadow .1s, transform .1s;
			}

			&:hover {
				background-color: $off-white;

				.speaker-round{
					transform: scale(1.05);
				}
			}

			.speaker-list-name {
				text-align: center;
				font-weight: bold;
			}

			.speaker-sermon-count {
				color: $gray;
			}
		}
	}
}

.album-art:not(.random-background){
    position: relative;
    &:before{
        content: '';
        padding-bottom: 100%;
        display: block;
    }
    img{
        width: 100%;
        position: absolute;
        top: 0
    }
}

.solo-speaker-list-primary {
	margin-bottom: 4rem;
	a {
		text-align: center;
		padding: 1rem 1rem;
		max-width: 60rem;
		display: block;
		margin: 2rem auto;
		transition: background-color .2s;
		border-radius: .3rem;

		.speaker-round {
			width: 12rem;
			height: 12rem;
			margin: 0 auto 2rem;
			transition: box-shadow .1s, transform .1s;
		}

		&:hover {
			background-color: $off-white;

			.speaker-round{
				transform: scale(1.05);
			}
		}
	}

	p {
		margin: 0;
	}

	h3 {
		margin-top: 0;
	}

	@media screen and (min-width: $mobile-break) {
		a {
			display: flex;
			align-items: center;
			text-align: center;
			text-align: left;
			max-width: 60rem;
			// margin: 2rem 0;

			.speaker-round {
				margin: 0 2rem 0 4rem;
			}
		}
	}
}

.sermon-item-info{
	.sermon-item-title {
		margin-bottom: 5px;
		font-size: 1.5em;
	}

	.sermon-item-metadata {
		margin-top: 10px;

		div {
			line-height: 1.3em;
			@media screen and (min-width: $mobile-break) {
				display: inline-block;
				&:after {
					content: "|";
					margin: 0 0.8em;
				}
				&:last-child:after {
					content: none;
				}
			}
		}

	}
	.sermon-item-series {
		margin-top: 5px;

		span {
			font-size: .9em;
            text-transform: uppercase;
		}
	}
}

.solo-sermon-list {
	padding: 0;
	margin: 2rem 0;

	li {
		border-bottom: 1px solid $light-gray;
		padding: 15px 0;
		overflow: auto;

		&.sermon-item-content {

			.sermon-item-content-left {
				display: flex;
				margin-bottom: 20px;
			}
			.sermon-item-content-right {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}
		.speaker-round {
			display: none;
			width: 6rem;
			user-select: none;
			min-width: 6rem;
			margin-right: 1.5rem;
			.speaker-img{
				height: 6rem;
			}
		}
	}

	.sermon-item-video-thumbnail {
		display: block;
		width: 96px;
		height: 54px;
		overflow: hidden;
		color: white;
		position: relative;

		img {
			width: 100%;
		}

		.play-icon {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: background-color .2s;

			&:hover {
				background: rgba(0,0,0,.1);
			}
		}
	}

	.play-audio-button {
		color: $gray;
		width: 40px;
		height: 40px;
		border-radius: 30px;
		border: 1px solid $light-gray;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
		transition: background-color .2s;

		&:hover {
			background: $off-white;
			border-color: $gray;
			color: $gray;
		}
	}

	@media screen and (min-width: $mobile-break) {

		li {
			padding: 15px;

			&.sermon-item-content {
				display: flex;
				justify-content: space-between;

				.sermon-item-content-left {
					margin-bottom: 0;
				}

				.sermon-item-content-right {
					display: flex;
					justify-content: flex-start;
					flex-direction: row-reverse;
					align-items: center;
				}
			}

			.speaker-round {
				display: block;
			}

			.play-audio-button {
				margin-left: 10px;
				margin-right: 0;
			}
		}

	}
}

.solo-series-header{
    .solo-block-content{
        padding-bottom: 0;
        > .med-12-col{
            margin-bottom: 0;
        }
    }
    .flex-grid-row, &{
        overflow: auto;
    }
    .album-art{
        float: left;
        margin-right: 1.5em;
        width: 40%;
        max-width: 300px;
        min-width: 100px;
        background-color: $dark-gray;
    }
    .description a{
        &, &:visited{
            color: currentColor !important;
        }
    }
    .title{
        font-size: 2em;
        font-weight: lighter;
        margin-bottom: .25em;
    }
    .podcast-info{
        overflow: auto;
    }
    .description{
        margin-bottom: 1em;
        overflow: auto;
        @media screen and (max-width: $mobile-break) {
            clear: both;
        }
    }
    .podcast-button{
        background-color: $dark-gray !important;
        margin: 0 $site-button-spacing $site-button-spacing 0;
        white-space: nowrap;
        .icon{
            max-width: 1.5em;
        }
        &:hover{
            background-color: $gray !important;
        }
    }
    .solo-button:not(.podcast-button):hover{
        background-color: $theme1-accent-hover-color !important;
    }
    .solo-button{
        display: inline-flex;
        align-items: center;
    }
    .buttons-label{
        font-size: 1.2em;
        margin-bottom: .75em;
        border-top: .1rem solid $gray;
        padding-top: 1.5em;
        overflow: hidden;
    }
    .buttons{
        justify-content: space-between;
        @media screen and (min-width: $mobile-break) {
            display: flex;
        }
    }
    .label{
        text-transform: uppercase;
        margin-bottom: 1em;
    }
    .icon{
        margin-right: .5em;
    }
    + .solo-highlighted-sermon{
        margin: 0 0 2rem 0;
        &.highlighted-audio{
            height: 30rem;
        }
        .audio-background{
            bottom: 1.5rem;
        }
        .metadata{
            .speaker-photo{
                margin-left: 1em;
                .speaker-img{
                    height: 6rem;
                    width: 6rem;
                }
            }
            @media screen and (max-width: $mobile-break) {
                .speaker-img{
                    display: none;
                }
                .speaker-photo{
                    padding: 0 .5em;
                    margin-left: 0;
                }
            }
        }
    }
}

.solo-podcast-header{
    background-color: $darkest-gray;
    color: white;
}