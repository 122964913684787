// most player controls are in their respective scss files
// _video-base.scss and _audio-base.scss/_audio-embed.scss
// but from here on we want to use this file wherever possible.
// eventually we should extract out the player-control styles from those files

.cast{
    &:not(.available){
        display: none !important;
    }
    &.chromecast{
        google-cast-launcher{
            --connected-color: #{$primary-accent-color} !important;
        }
        &:hover{
            google-cast-launcher{
                --connected-color: #{$primary-accent-color-highlight} !important;
            }
        }
    }
    .audio-player &.chromecast{
        google-cast-launcher{
            --disconnected-color: #{$gray} !important;
        }
        &:hover{
            google-cast-launcher{
                --disconnected-color: #{$dark-gray} !important;
            }
        }
    }
    .video-player &.chromecast{
        google-cast-launcher{
            --disconnected-color: #{$light-gray} !important;
        }
        &:hover{
            google-cast-launcher{
                --disconnected-color: #{$lightest-gray} !important;
            }
        }
    }
}

// use this if we want our redo arrow to go counter clockwise
// .control .redo{
//     transform: scaleX(-1);
// }

.casting{
    &.airplaying .volume-slider, &.airplaying .mute, &.chromecasting .speed, .media-settings, .popout-icon, .spinner{
        display: none !important;
    }
    &.airplaying{
        .airplay{
            color: $primary-accent-color !important;
            &:hover{
                color: $primary-accent-color-highlight !important;
            }
        }
    }
}
