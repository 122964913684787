.solo {
	main {
		padding-top: 0;
	}
}



h1 {
	font-size: 2.5em;
	font-weight: lighter;
}

h3 {
	font-size: 1.5em;
	font-weight: lighter;
	margin-top: 0;

	&.bottom-border {
		padding-bottom: 2rem;
		border-bottom: .1rem solid $light-gray;
	}
}

@media screen and (min-width: $mobile-break) {

	h3 {
		font-size: 2em;
	}
}

a.address-link{
	color: white;
	margin-right: .5rem;
}

.solo-hero {
	&.solo-sermon {
		padding-bottom: 0;
	}
	h1{
		text-align: center;
	}

	&:first-child {
		padding-top: 8rem;
	}


	@media screen and (min-width: $mobile-break) {
		&:first-child {
			padding-top: 11rem;
		}
	}

}

.solo-blocks {
	&:not(.bg-color-offset) {
		.solo-block:nth-child(odd):not(.solo-footer):not(.solo-hero) {
			background-color: $secondary-bg-color;
		}
	}

	&.bg-color-offset {
		.solo-block:nth-child(even):not(.solo-footer):not(.solo-hero) {
			background-color: $secondary-bg-color;
		}
	}
}

.solo-block {
	font-size: 1.5rem;
	padding: 2em 0;
	background-color: white;

	.solo-block-content {
		padding: 1rem;
	}

	@media screen and (min-width: $mobile-break) {
		.solo-block-content {
			padding: 2rem;
		}
	}
}

.solo-block-content {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}

.solo-button, .modal-container button {
	display: inline-block;
	padding: 1rem 2rem;
	border-radius: 10rem;
	color: white;

	&:visited, .desktop &:hover {
		color: white;
	}
    &.solo-button-white {
		background: white !important;
		&, &:visited, .desktop &:hover {
			color: black;
		}
		.desktop &:hover {
			background: $off-white !important;
		}
	}
}

ul.solo-icon-list {
	padding: 0;
	margin: 0;

	li {
		margin: .5em 0;
		&:last-child{
			margin-bottom: 0;
		}
		> span:not(.markdown) {
			display: inline-block;
			width: 25px;
			float: left;
		}
		.markdown{
			display: block;
		}

		p {
			margin: 0 0 0 25px;
		}

		clear: left;
	}
}

ul.social-media-icon-group {
	&:hover a {
		&:hover:not(.disabled) {
			color: $gray;
		}
	}
	li {
		font-size: 2em;
		a {
			&.disabled {
				color: black;
			}
		}
	}
}

.solo-parallax {

	color: white;
	text-align: center;
	background-color: $darkest-gray;
	position: relative;
	overflow: hidden;
	z-index: 0;
	height: 0;
	padding: 50% 0 0;

	.solo-block-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		z-index: 3;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0,0,0,.5);
		z-index: 1;
	}

    .audio-only-message .contents{
        display: none !important;
    }

	.parallax-block-background {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: 56.25%;
		background-size: cover;
		background-position: center center;
		.video-player {
			position: absolute;
			top: 0;

			.vjs-v7 {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				bottom: 0;
			}
		}
	}

	@media screen and (min-width: $mobile-break) {
		padding: 30% 0 0;
	}
}

.solo-visit {
	position: relative;
	min-height: 30rem;
	.solo-visit-map {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #f1f2f0;
		background-size: cover;
		background-position: center center;
		z-index: 0;
        &, *{
			pointer-events: none;
		}
	}

	.solo-visit-info {
		background: white;
		border-radius: .3rem;
		position: relative;
		z-index: 1;
		padding: 3rem;
		box-shadow: 0px 0px 20px 0px rgba(0,0,0,.1);

		h3 {
			margin-bottom: 2rem;
		}

		.markdown {
			margin-top: 0;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

}

.solo-block.solo-about {
	.about-text p {
		line-height: 1.6em;
	}
}

.about-text.index-page{
	$line-height: 2.4rem;
	$lines-to-show: 6;
	$transition-speed: $generic-transition-speed;
	padding: .5rem;
	max-height: $line-height * ($lines-to-show + 1) + 1rem;
	position: relative;
	transition: height $transition-speed;
	+ .solo-button{
		margin-top: 1.5rem;
	}
	p{
		margin: 0;
	}
	overflow: hidden;
	> a{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		font-style: italic;
		text-align: right;
		padding: $line-height .5rem 0 0;
		transition: opacity $transition-speed;
		background: linear-gradient(to bottom, rgba(white, 0) 0%, white 60%, white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	}
	&.truncate{
		> a{
			opacity: 1;
			pointer-events: all;
		}
	}
	&:not(.truncate){
		> a{
			opacity: 0;
			pointer-events: none;
		}
	}
}

.solo-latest-sermons {

	.solo-sermon-list {
		padding: 0;
		margin: 2rem 0;
	}
}

ul.solo-pagination {
	padding: 0;
	text-align: center;
	margin-top: 50px;

	li {
		display: inline-block;

		&.page-control {
			display: none;
			&.disabled{
				pointer-events: none;
				a {
					color: $lightest-gray !important;
				}
			}
		}

		a {
			padding: 5px 10px;
			margin: 10px 0;
			border-radius: 3px;
			transition: background-color .2s;

			&:hover {
				background: $lightest-gray;
			}

			&.current-page {
				background: $off-white;
			}
		}

		@media screen and (min-width: $mobile-break) {
			&.page-control {
				display: inline-block;
			}
		}
	}
}

.sermons-anywhere {
	.solo-block-content,
	.sermons-anywhere-slider,
	.slider-content,
	.slides-container,
	.slides,
	.slide-prev,
	.slide-next {
		background-color: inherit !important;
	}
}

.button-row {
	text-align: center;
}

.send-message-anchor {
	display: block;
	position: relative;
	top: -14rem;
	visibility: hidden;
}

.solo-simple-form {
	display: block;
	width: 100%;
	position: relative;

	label {
		display: block;
	}
	input, textarea {
		display: block;
		width: 100%;
	}
	.solo-button-group {
		margin-top: 1em;
	}

	.solo-form-error-message {
		display: none;
		background-color: $error-color;
		color: white;
		padding: 1rem;
	}

	button .sent{
		display: none;
	}

    .solo-form-success-message {
        z-index: 2;
        background: $success-color;
        color: white;
        padding: 1rem;
		margin-left: 1rem;
		display: none;
    }
}

.send-message-form.send-success{
	input, button, textarea{
		pointer-events: none;
		background-color: $off-white;
		color: $light-gray;
	}
	button{
		border: none;
		.sent{
			display: inline;
		}
		.send{
			display: none;
		}
	}
	.solo-form-success-message{
		display: inline-block;
	}
}

.send-message-form.send-error .solo-form-error-message {
	display: flex;
}

.solo-button-group {

	.solo-button {
		margin-right: 10px;
	}

	.solo-button:last-child {
		margin-right: 0;
	}
}

.solo-about-top {
	p{
		margin-bottom: 3rem;
		line-height: 1.6em;
	}
	h3{
		font-size: 2em;
		margin-bottom: 2rem;
		+ p {
			font-size: 1.15em;
			margin-bottom: 2.5rem;
		}
	}
	.med-7-col{
		padding-right: 5rem;
	}
}

.solo-about-primary-speaker {

	.solo-block-content {
		min-height: 26rem;
	}

	.grid-col:first-child {
		text-align: center;

		.speaker-round {
			display: inline-block;
			width: 20rem;
			max-width: 20rem;
			height: 20rem;
		}
	}

	@media screen and (min-width: $mobile-break) {
		.grid-col:first-child .speaker-round {
			display: inline-block;
			width: 100%;
			height: auto;
		}
	}
}

.solo-notices{
	ul{
		padding: 0;
		max-width: 96rem;
		margin: 0em auto 3em;
	}
    li.notice{
		&:not(:last-child){
			border-bottom: 1px solid $lightest-gray;
		}
		padding: 1em 0 1em;
		div{
			padding: .75rem 0;
			max-width: 72rem;
		}
		.notice-title{
			font-weight: bolder;
		}
		.notice-date{
			color: $gray;
			font-size: .9em;
		}
		&.new{
			.notice-title{
				font-size: 1.3em;
			}
		}
	}
	.new-tag{
		border-radius: .5em;
		padding: .125em .5em;
		color: white;
		font-size: .75em;
		font-style: italic;
		text-transform: uppercase;
	}
}
