
.translation-icon{
    display: inline-flex;
    background-color: $light-gray;
    color: $primary-bg-color;
    border-radius: .3em;
    text-transform: uppercase;
    width: 2em;
    height: 1.4em;
    align-items: center;
    justify-content: center;
    span{
        vertical-align: middle;
    }
}

.translation-dropdown-button{
    position: relative;
    user-select: none;
    cursor: pointer;
    z-index: 10000;
    .translation-name{
        margin: 0 .25em;
    }
    &:not(.init) .translation-dropdown{
        display: none;
    }
    &:not(.active).init .translation-dropdown{
        pointer-events: none;
        display: none;
    }
    &.active.init .translation-dropdown{
        display: block;
    }
}

.translation-dropdown{
    background: $primary-bg-color;
    box-shadow: $box-shadow;
    padding: .5em 0 0 0;
    margin: 0;
    position: absolute;
    right: 0;
    color: $black;
    min-width: 25rem;
    overflow: hidden;
    &.up{
        bottom: 2.5em;
    }
    .translation-icon{
        background-color: $light-gray;
        margin-right: .5em;
    }
    em{
        margin-left: .5em;
        color: $light-gray;
    }
    li{
        padding: .5em 1.5em .5em 1em;
        text-align: left;
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        width: 100%;
        line-height: 1;
        &.active{
            background-color: $off-white;
            cursor: default;
        }
        &:hover:not(.active){
            background-color: $secondary-bg-color;
        }
        &:last-child:not(.report-error){
            margin-bottom: .5em;
        }
    }
    .report-error{
        border-top: $generic-border;
        padding: 1em;
        color: $dark-gray;
        margin-top: .5em;
    }
}
