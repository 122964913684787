
*{
    font-size: 1em;
    box-sizing: border-box;
}

input[type=file]{
    display: none;
}

html.fixed-scrollbar{
    overflow-y: scroll;
}

.flex-column{
    flex-direction: column;
}


ul.social-media-icon-group {
	padding: 0;
    margin: 0;
	&:hover a {
		&:hover:not(.disabled) {

		}
	}
	li {
		a {
			display: inline-block;
			width: 1.3em;
			text-align: center;
            color: currentColor;
			&.disabled {
				cursor: default;
			}
		}
		display: inline-block;
		margin: 0;
	}
}

.messages{
    width: 100%;
    padding: 0;
    li{
        padding: 1em;
        text-align: left;
        font-size: 1.2rem;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        background-color: $lightest-gray;
        + li{
            margin-top: 1em;
        }
        .dismiss{
            cursor: pointer;
            width: 1em;
            display: block;
            text-align: right;
        }
        &.error, &.success{
            color: white;
        }
        &.success{
            background-color: $primary-accent-color;
        }
        &.error{    background-color: $error-color; }
        &.warning{  background-color: $warning-color; }
        a{
            color: currentColor !important;
            text-decoration: underline !important;
        }
    }
}

.add-to-playlist{
    cursor: pointer;
}

.vertical-sermon-list{
    font-size: 1.2rem;
    $border-color: $lightest-gray;
    $max-sermon-count: 4;
    $max-sermon-count-scrolling: 8;
    $individual-sermon-height: 4.25em;
    $truncated-height: $max-sermon-count * $individual-sermon-height - .1em;
    $scrolling-height: $max-sermon-count-scrolling * $individual-sermon-height - .1em;
    h6{
        text-transform: uppercase;
        font-size: 1.3em;
        color: $gray;
        font-weight: 400;
        margin: 1em 0 .5em;
    }
    &.hasSubtitle{
        h6{
            margin-bottom: 0;
        }
    }
    em{
        display: inline-block;
        color: $darkest-gray;
        font-style: normal;
        font-size: 1.6em;
        line-height: 1em;
        margin-bottom: .5em;
    }
    .show-all-button{
        border: .1rem solid $border-color;
        border-top-width: 0;
        padding: .25em 0 .25em 3.5em;
        text-transform: uppercase;
        color: $gray;
        cursor: pointer;
        background-color: $off-white;
        .icon{
            fill: $gray;
            margin-left: .25em;
            font-size: 1.2em;
        }
        &:hover{
            background-color: $lightest-gray;
            color: $dark-gray;
            .icon{
                fill: $dark-gray;
            }
        }
    }
    .sermons{
        max-height: $truncated-height;
        overflow: hidden;
        border-top: .1em solid $border-color;
        border-bottom: .1em solid $border-color;
    }
    &.opened{
        .sermons{
            max-height: $scrolling-height;
            @extend .scrolls;
            .sermon{
                border-right-width: 0;
            }
        }
        .show-all-button{
            display: none;
        }
    }
    .sermon{
        font-size: 2em;
        width: 100%;
        border: .1rem solid $border-color;
        border-bottom-width: 0;
        display: inline-block;
        position: relative;
        &:first-child{
            border-top-width: 0;
        }
        a{
            padding: .25em;
            display: inline-block;
            width: 100%;
            &:hover{
                background: $secondary-bg-color;
                color: $black;
            }
        }
        .button-sets{
            font-size: .6em;
            position: absolute;
            width: 6.5em;
            right: 0;
            top: .125em;
            pointer-events: none;
            text-align: right;
            > div {
                width: 100%;
                float: left;
                height: 1.3em;
                &.buttons{
                    margin-top: .65em;
                    &.has-badges{
                        margin-top: 0em;
                    }
                }
                &.badges .icon{
                    margin-right: .5em;
                }
                > * {
                    display: inline-block;
                    margin-right: .5em;
                    &.icon{
                        margin: 0;
                    }
                    &.play-count .icon{
                        font-size: .7em;
                        vertical-align: baseline;
                    }
                }
            }
        }
        .info{
            display: flex;
            position: relative;
            flex-wrap: wrap;
            flex-direction: column;
            width: calc(100% - 8.5rem);
            font-size: .6em;
            @include title-switcher($desktop-small);
            .img-container{
                width: 2em;
                height: 100%;
                flex-grow: 1;
                position: absolute;
                top: 0;
                bottom: 0;
                img{
                    width: 100%;
                }
            }
            span, em{
                display: block;
                font-weight: 400;
            }
            span{
                font-size: 1em;
                line-height: 1.4em;
                width: calc(100% - 2em);
                margin: 0 0 0 2.5em;
            }
            em{
                font-size: .8em;
                margin-left: 3.2em;
                color: $gray;
            }
        }
    }
}

.truncate.text{
    @include truncate(3, 1.6em);
}
.more-toggle{
    color: $gray;
    user-select: none;
    pointer-events: auto;
    text-transform: uppercase;
    cursor: pointer;
    .less, .more{
        display: none;
    }
    .icon{
        fill: $gray;
        width: 1em;
        font-size: 1.3em;
        vertical-align: text-bottom;
    }
    &:hover{
        color: $dark-gray;
        .icon{
            fill: $dark-gray;
        }
    }
    &.oversized{
        .more{ display: block; }
        &.opened{
            .less{ display: block; }
            .more{ display: none; }
        }
    }
}

.hr{
    height: .1rem;
    border-bottom: $generic-border;
}


// TODO: this will eventually be used for speaker and broadcaster pages
// .open-podcast{
//     .caret{
//         transition: opacity $generic-transition-speed;
//         opacity: 0;
//         width: 1em;
//     }
//     &.open .caret{
//         opacity: 1;
//     }
// }
// .podcast-dropdown{
//     overflow: hidden;
//     transition: height $generic-transition-speed;
//     font-size: 1.4rem;
//     &.open{
//         height: 11.65em;
//     }
//     &.closed{
//         height: 0;
//     }
//     .dropdown{
//         @include dropdown(.85em, 100%);
//         float: left;
//     }
//     .contents{
//         background-color: $lightest-gray;
//         padding: 1.4em;
//         overflow: auto;
//     }
//     p{
//         margin: 0 0 1em 0;
//     }
//     button{
//         border-color: $darkest-gray;
//         color: $darkest-gray;
//         float: left;
//         margin: .5em .5em 0 0;
//         font-size: .85em;
//         width: 4.5em;
//         &:last-child{
//             margin-right: 0;
//         }
//         .icon{
//             fill: $darkest-gray;
//         }
//     }
// }
