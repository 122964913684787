
.animated-ellipsis{
	@keyframes animated-ellipsis {
		0% {
			opacity: 0.2;
		}
		30% {
			opacity: 1;
		}
		60% {
			opacity: 0.2;
		}
		100% {
			opacity: 0.2;
		}
	}

	span{
		margin: 0 .05em;
		vertical-align: middle;
		&:before{
			content: '•';
		}
	}
	&:not(.static) span{
		animation-name: animated-ellipsis;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		opacity: 0.2;
		&:nth-child(2) {
			animation-delay: .2s;
		}
		&:nth-child(3) {
			animation-delay: .4s;
		}
	}
}
