@use "sass:math";

@mixin cluster-icon ($clusterIndex){
    width: $base-cluster-size + ($cluster-multiplier * $clusterIndex) + (($base-cluster-border-radius + ($cluster-border-radius-multiplier * $clusterIndex)) * 2) !important;
    height: $base-cluster-size + ($cluster-multiplier * $clusterIndex) + (($base-cluster-border-radius + ($cluster-border-radius-multiplier * $clusterIndex)) * 2) !important;
    margin-left: math.div($base-cluster-size + ($cluster-multiplier * $clusterIndex) + (($base-cluster-border-radius + ($cluster-border-radius-multiplier * $clusterIndex)) * 2), 2);
    margin-top: math.div($base-cluster-size + ($cluster-multiplier * $clusterIndex) + (($base-cluster-border-radius + ($cluster-border-radius-multiplier * $clusterIndex)) * 2), 2);
    div{
        width: $base-cluster-size + ($cluster-multiplier * $clusterIndex) !important;
        height: $base-cluster-size + ($cluster-multiplier * $clusterIndex) !important;
        margin-left: ($base-cluster-border-radius + ($cluster-border-radius-multiplier * $clusterIndex)) !important;
        margin-top: ($base-cluster-border-radius + ($cluster-border-radius-multiplier * $clusterIndex)) !important;
    }
    span{
        line-height: $base-cluster-size + ($cluster-multiplier * $clusterIndex) !important;
    }
}
