.leaflet-popup-content{
    border: none !important;
}
.leaflet-popup-close-button{
    padding: .4rem 0 0 0 !important;
    height: 2.4rem !important;
    width: 2.2rem !important;
}
.finder .popup-header{
    background-color: rgba($lightest-gray, 0.35);
    overflow: auto;
    font-size: 1rem;
    text-transform: uppercase;
    img{
        width: 5.5em;
        height: 3.7em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        margin-left: 1.5em;
        margin-right: 1.35em;
        float: left;
        @media screen and (max-width: $mobile-width) {
            width: 0;
            margin-top: 0.5em;
            margin-bottom: 1em;
            margin-left: 0;
        }
    }
    .header-text{
        font-size: 1em;
        margin-top: 1.65em;
        @media screen and (max-width: $mobile-width) {
            margin-top: 1em;
        }
        p{
            color: $dark-gray;
            font-size: 1.05em;
            margin: 0;
            // margin-top: -0.1em;
            font-weight: 300;
        }
        .popup-church-name {
            margin-top: 0;
            strong{
                color: $darkest-gray;
                font-size: 1.2em;
                font-weight: normal;
                letter-spacing: 0.025em;
            }
        }
    }
}
.finder .popup-footer{
    width: 100%;
    height: $popup-footer-height;
    .popup-info{
        font-size: 1.1rem;
        text-align: center;
        color: $light-gray;
        display: inline-block;
        width: calc(100% - 6rem);
        margin-left: 2rem;
        font-weight: 300;
        line-height: $popup-footer-height - .25rem;
        height: $popup-footer-height;
        float: left;
    }

    .modal-link{
        color: $gray;
        border: 0;
        float: right;
        background-color: transparent;
        line-height: $popup-footer-height - .25rem;
        cursor: pointer;
        width: 4rem;
        text-align: center;
        height: 100%;
        transition: all .25s;
        .icon{
            fill: $gray;
            font-size: 2rem;
        }
        &:hover{
            color: $darkest-gray;
            .icon{
                fill: $darkest-gray;
            }
            background-color: $lightest-gray;
        }
    }
}
