
.share-modal{
    overflow: hidden;
    color: white;
    .title{
        margin-bottom: .5em;
        font-size: 1.6em;
        .icon{
            position: relative;
            top: -.125em;
            margin-right: .5em;
            .ie &{
                width: 1em;
            }
        }
    }
    .dismiss{
        @extend .box-dismiss;
    }
    button{
        width: 3.75rem;
        font-size: 2.25rem;
        &.copy-code-button{
            @extend .obround;
            padding: .35em .75em 1.25em !important;
            width: auto !important;
            float: right !important;
            font-size: 1.6em;
            margin-bottom: .75em;
            margin-right: 0 !important;
        }
    }
    .button-set{
        display: inline;
        float: left;
        position: relative;
        left: -.35em;
        font-size: 1.5em;
        .icon-button{
            padding: .1em .35em .2em;
            border: none;
            width: 2em;
        }
    }
    input{
        width: 100% !important;
    }
    .qr-code-share-container{
        position: absolute;
        overflow: inherit;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 100%;
        background-color: inherit;
        padding: inherit;
        transition: right $generic-transition-speed;
        &.show{
            right: 0;
        }
        .title{
            margin-bottom: .5em !important;
        }
        .qr-code-share-description{
            font-size: 1.2em;
            opacity: .75;
        }
        .share-qr-flex-container{
            display: flex;
            margin-top: 1.4em;
        }
        .qr-code-container{
            width: 66px;
            height: 66px;
            padding: 2px;
            background: white;
            flex-shrink: 0;
            margin-left: 1em;
            img, canvas{
                width: 100%;
            }
        }
    }
    .icon-button.custom-dropdown{
        + ul{
            font-size: 1rem;
            width: 27rem;
            padding: 0;
            margin: 0;
            position: absolute;
            right: 0;
            border: $generic-border;
            background: $primary-bg-color;
            box-shadow: $box-shadow;
            > div{
                padding: 0;
                position: relative;
                right: 0;
            }
            .share-qr-flex-container{
                margin: 0;
                padding: 1.5em;
            }
            .qr-code-container{
                border: .1rem solid black;
            }
        }
        &:not(.open){
            + ul{
                display: none;
            }
        }
    }
}

