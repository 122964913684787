
button, .label-button{
    text-align: center;
    font-size: 1.4rem;
    text-transform: none;
    user-select: none;
    white-space: nowrap;
    align-self: flex-start;
}
.label-button{
    @extend .obround-button;
    &.disabled{
        @extend :disabled;
    }
    &:hover{
        background: $secondary-bg-color;
    }
}

.bottom-border{
    border-bottom: $generic-border;
    form &, .form & {
        padding-bottom: 1.5em;
        margin-bottom: 1.5em;
    }
    &.header{
        padding-bottom: .5em;
    }
    &.button-header{
        padding-bottom: 1em;
    }
}

form, .form{
    justify-content: space-between;
    &.wrap{
        flex-wrap: wrap;
        .column{
            flex-basis: 40%;
        }
    }
    .black-header h3{
        color: $darkest-gray;
    }
    p{
        margin-top: 0;
        margin-bottom: 1em;
    }
    a{
        font-size: 1.4rem;
        margin: 1.25rem 0;
    }
    .dropdown{
        @include dropdown(1em, 100%, 3em);
    }
    &, > div{
        display: flex;
    }
    @include breakpointMax($desktop-small){
        display: block;
    }
    .column{
        flex-direction: column;
        flex-grow: 2;
        flex-basis: 50%;
        font-size: 1.3rem;
        color: $darkest-gray;
        + .button-stack{
            justify-content: space-between;
        }
        @include breakpointMax($desktop-small){
            max-width: 100%;
        }
    }
    .input-pair{
        display: flex;
        input{
            flex-grow: 2;
            width: 100%;
            &:first-child{
                margin-right: .5em;
            }
        }
    }
    [type="checkbox"]:not(:checked)+label, [type="checkbox"]:checked+label{
        font-size: 1.1em;
        line-height: 1.6em;
        max-width: 20em;
        display: inline-block;
        // overflow: auto;
        height: auto;
        margin: .125em 0 2em;
        padding-left: 2em;
        &:before{
            top: 0.25em;
        }
        &:after{
            top: .45em;
        }
    }
    input + button{
        margin-top: 2em;
        margin-bottom: 1em;
    }
    .button-stack{
        text-align: right;
        width: 100%;
        button{
            margin-left: $site-button-spacing;
            margin-top: .25em;
            margin-bottom: .25em;
        }
        .left{
            text-align: left;
            button{
                margin-left: 0;
                margin-right: $site-button-spacing;
            }
        }
    }
    label{
        font-size: 1.4rem;
        margin-top: 1.5em;
        margin-bottom: .5em;
        + em{
            font-style: normal;
            font-size: 1.2rem;
            line-height: 1.4em;
            margin-bottom: .75em;
            color: $dark-gray;
        }
    }
    input{
        color: $darkest-gray;
    }
    input:disabled, input:disabled:-webkit-autofill{
        background-color: $lightest-gray;
        -webkit-text-fill-color: $gray;
        -webkit-box-shadow: 0 0 0 100px $lightest-gray inset;
    }
    input, .dropdown, .pseudo-input, textarea{
        margin: .25em 0;
    }
    textarea{
        resize: vertical;
    }
}


.header, .footer{
    width: 100%;
    margin: $site-header-margins 0;
    overflow: auto;
}
.footer{
    &.top-border{
        border-top: solid .1rem $lightest-gray;
    }
    p{
        margin: 1.25em 0;
        font-size: 1.4rem;
    }
}

.header{
    font-size: 1.3em;
    h3, h4{
        font-size: 1.2em;
        float: left;
        display: inline-block;
        color: $gray;
        margin: 0 0 0 $site-header-margins;
        font-weight: 400;
        strong{
            font-weight: 600;
        }
    }
    &.large{
        h3, h4{
            font-size: 1.6em;
        }
    }
    &.black-header{
        h3, h4{
            color: $darkest-gray;
        }
    }
    &.button-header{
        display: flex;
        overflow: visible;
        justify-content: space-between;
        .label-button, button{
            margin-right: $site-header-margins;
        }
        &.large{
            .label-button, button{
                top: -.15em;
            }
        }
    }

    @include breakpointMax($modal-stack-width) {
        h3, h4 {
            margin: 0 $site-header-margins 0 $site-header-margins;
        }

        &.collapse-at-mobile{
            button, .label-button{
                width: 100%;
                margin-left: $site-header-margins;
            }
            h3, h4{
                display: none;
            }
        }
    }
}
