.modal-container {
    position: fixed;
    left: 0;
    bottom: 0;
    top: $header-height;
    .popout &, &.alert, &.submit{
        top: 0;
    }
    right: 0;
    z-index: 990000;
    pointer-events: none;
    display: flex;
    align-items: center;
    &.active{
        pointer-events: auto;
        > .modal-background{
            opacity: 1;
            @include fadeIn(
                $duration: 0.25s
            );
        }
        > .modal-contents-container{
            opacity: 1;
            @include fadeInDown(
                $duration: 0.25s
            );
        }
    }
    &.init{
        > .modal-background, > .modal-contents-container{
            display: block;
        }
    }

    .sk-wave-container{
        width: calc(100% - 2rem);
        height: 5rem;
        position: absolute;
        transition: opacity 0 $generic-transition-speed;
        opacity: 1;
    }

    .sk-wave{
        color: $lightest-gray;
        div{
            height: 6rem;
        }
    }

    .large-container .sk-wave-container{
        width: calc(100% - 4rem);
    }

    .loaded .sk-wave{
        opacity: 0;
    }
}

.modal-background{
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(black, 0.7);
    display: none;
    @include fadeOut(
        $duration: 0.25s
    );
}

.modal-sub-container{
    @extend .modal-container;
    position: absolute;
    top: 0;
    .modal-background{
        background-color: rgba(black, 0.4);
    }
    .modal-contents-container{
        width: 100%;
        height: auto !important;
    }
}

.modal-contents-container{
    background-color: $secondary-bg-color;
    margin: 0 auto;
    width: calc(100% - 4rem);
    height: 100%;
    max-height: 40rem;
    max-width: 85rem;
    padding: 2rem;
    position: relative;
    opacity: 0;
    display: none;
    box-shadow: $box-shadow;

    > input{
        width: 100%;
        margin-top: 2rem;
    }

    h3{
        font-size: 2.4rem;
        margin: 0;
    }
    h4{
        font-size: 1.8rem;
        margin-top: 3.2rem;
        margin-bottom: .6rem;
        color: $darkest-gray;
    }
    p{
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $darkest-gray;
    }
    > p{
        margin-bottom: 0;
    }
    p, .button-stack{
        margin-top: 2.4rem;
    }
    .button-stack{
        overflow: auto;
    }
    h4 + p{
        margin-top: 0;
    }
    p.feedback{
        line-height: 3em;
        font-size: .9em;
    }

    @include fadeOutUp(
        $duration: 0.25s
    );
    .dynamics-container{
        opacity: 0;
        transition: opacity 0 $generic-transition-speed;
        width: 100%;
        height: 100%;
    }
    &.loaded .dynamics-container{
        opacity: 1;
    }
    .tab-nav{
        margin: 1.5em 0;
        button{
            font-size: 1em;
        }
    }
    .modal-close-button{
        .icon{
            fill: $dark-gray;
        }
        position: absolute;
        right: 0;
        font-size: 1.4em;
        top: 0;
        width: 2rem;
        height: 2rem;
        padding-right: .2rem;
        padding-top: .2rem;
        text-align: center;
        line-height: 2rem;
        cursor: pointer;
        font-weight: 600;
        @media screen and (max-width: $modal-stack-width) {
            font-size: 1.5rem;
            padding-right: 0;
            font-weight: normal;
            width: 4rem;
            height: 4rem;
            line-height: 3rem;
        }
        &:hover .icon{
            fill: $darkest-gray;
        }
    }
    &.scrollable{
        margin: 0 auto !important;
        .modal-close-button{
            width: 3rem;
        }
    }

    &.large-container{
        height: calc(100vh - 4rem);
        max-height: 64rem;
        max-width: 96rem;
        padding: 4rem 2rem 4rem 4rem;
    }

    .alert &, .submit &{
        padding: 3.5rem;
        max-width: 50rem;
        max-height: none;
        height: auto;
        .button-stack{
            button{
                float: left;
                margin-right: $site-button-spacing;
            }
        }

        @media screen and (max-width: $mobile-width) {
            padding: 2rem;
        }
    }
    .fill &{
        height: 100%;
    }
    @media screen and (max-width: $modal-stack-width) {
        width: 100%;
        margin: 0 auto !important;
        max-height: none;
    }
    @media screen and (max-width: $mobile-width) {
        padding: 1.5rem 1rem;
        p {
            font-size: 1.4rem;
        }

    }
    span.id{
        color: $primary-accent-color;
    }
    .dropdown{
        @include dropdown(1.4rem, 100%);
        // dropdown($font-size: 12px, $width: 10em, $height: 2.5em)
    }
}
