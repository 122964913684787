
footer{
    background: $darkest-gray;
    padding: 3em;
    font-size: 1.4em;
    .flex-grid-row{
        max-width: 96rem;
        margin: auto;
        color: white;
        a{
            color: currentColor;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    h6{
        margin: 0;
    }
    ul.social-media-icon-group li{
        position: relative;
        left: -.2rem;
        font-size: 1.2em;
        &:not(:hover){
            opacity: .8;
        }
    }
    ul{
        margin-top: 1rem !important;
        padding: 0;
        li{
            list-style: none;
        }
        p{
            margin: .25em 0 0 0;
        }
        span, p{
            display: inline-block;
        }
        span{
            width: 1.5em;
        }
    }
}