
@use "sass:math";

html, body {
    font-size: $base-size;
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // https://stackoverflow.com/questions/3226001/some-font-sizes-rendered-larger-on-safari-iphone
    // https://blog.55minutes.com/2012/04/iphone-text-resizing/
    -webkit-text-size-adjust: 100%;
}
html, body, button, input, textarea, select{
    font-family: sans-serif;
    font-display: auto;
    line-height: 1.15;
    .fonts-loaded &{
        font-family: $font-family;
    }
}

button{
    pointer-events: none;
    .loaded &{
        pointer-events: inherit;
    }
}

img{
    &.lazy-load, &.lazy-loaded{
        transition: opacity 1s;
    }
    &.lazy-load{
        min-height: 10em;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../../svg/light/image.svg');
        opacity: 0;
    }
    &.lazy-loaded{
        opacity: 1;
    }
}

ul{
    position: relative;
}
li{
    list-style-type: none;
}
.divider{
    background: $lightest-gray;
    margin-bottom: .35em;
    height: .1rem;
}

[role="button"], [role="button"].icon-button, a{
    cursor: pointer;
    color: $gray;
    .icon{
        fill: currentColor;
    }
    &:hover{
        color: $darkest-gray;
    }
    .dark &{
        color: $light-gray;
        &:hover{
            color: white;
        }
    }
}


.speaker-img{
    $speakerImgSize: 3.5rem;
    width: $speakerImgSize;
    height: $speakerImgSize;
    border-radius: 50%;
    background: url('../../svg/solid/speaker-blank.svg');
    background-size: cover;
    img{
        width: 100%;
    }
}

/*
UNCOMMENT IF WE WANT A MARQUEE IN THE FUTURE
@keyframes marquee {
    0%{
        left: 0%;
        width: 100%;
    }
    40%{
        left: 0%;
        width: 100%;
    }
    75% {
        left: -100%;
        width: 200%;
    }
    90% {
        left: -100%;
        width: 200%;
    }
    100% {
        left: 0%;
        width: 100%;
    }
}

.marquee{
    overflow: hidden;
    box-sizing: border-box;
    > *{
        @extend .overflow-ellipsis;
        position: relative;
    }
    &.animate{
        animation: marquee 8s linear infinite;
    }
}
*/
button, input[type=submit], input[type=button], .obround-button{
    border: solid .1rem currentColor;
    border-radius: 0;
    color: $gray;
    display: inline-block;
    .icon{
        fill: currentColor;
    }
    text-transform: uppercase;
    background: transparent;
    padding: .5em 1em;
    &.thin{
        padding-top: .3em;
        padding-bottom: .35em;
    }
    &.wide{
        padding-left: 2em;
        padding-right: 2em;
    }
    cursor: pointer;
    &.padded{
        padding-left: 1.25em;
        padding-right: 1.25em;
    }
    &.rounded{
        border-radius: .3rem;
    }
    &.borderless{
        border: none;
    }
    &.removeBackground{
        background: transparent !important;
    }
    &::-moz-focus-inner {
        border: none;
    }
    .desktop &:hover{
        color: $dark-gray;
        background-color: rgba($dark-gray, .05);
    }
    &.active{
        color: $primary-accent-color;
        .desktop &:hover{
            color: $primary-accent-color-dark;
            background-color: rgba($primary-accent-color, .05);
        }
    }
    &.negative{
        color: $error-color;
        &:hover{
            color: $error-color-dark;
        }
    }
    .spinner{
        margin-left: -1em;
        transition: all .25s;
    }
    &:disabled, &:disabled:hover{
        background-color: $off-white !important;
        color: darken($lightest-gray, 5) !important;
        border-color: $off-white !important;
        cursor: default !important;
        &:not(.require-processing), &.require-processing.processing{
            .spinner{
                opacity: 1;
                border-color: currentColor;
                margin-left: .5em;
            }
        }
    }
    &.filled{
        border: transparent;
        &, &:hover{
            color: white !important;
        }
        &.active{
            background-color: $primary-accent-color;
            &:hover{
                background-color: $primary-accent-color-dark;
            }
        }
    }
    body.dark &{
        border-color: $gray;
        color: $light-gray;
        &.active{
            color: lighten($primary-accent-color-highlight, 20);
            border-color: $primary-accent-color-highlight;
        }
        &:disabled, &:disabled:hover{
            background-color: $black !important;
            color: darken($dark-gray, 10) !important;
            border-color: $black !important;
        }
    }
    body.dark.desktop &{
        &:hover{
            color: white;
            border-color: $off-white;
            background-color: rgba($lightest-gray, .4);
        }
        &.active:hover{
            color: white;
            border-color: lighten($primary-accent-color-highlight, 20);
            background-color: rgba($primary-accent-color-highlight, .2);
        }
    }
}
.obround-button, button.obround, input.obround{
    border-radius: 2em;
}
input, textarea{
    -webkit-appearance: none;
    padding: 1rem;
    font-family: inherit;
    &[type=number]{
        -moz-appearance:textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    }
}
input, textarea, .search-bar{
    border-radius: 0;
    background-color: $primary-bg-color;
    border: 1px solid $lightest-gray;
    transform: scale(1);
    line-height: 1.35em;
    &.outline{
        outline: none !important;
    }
    &:focus, &.focus {
        border-color: $primary-accent-color;
    }
    &:disabled{
        color: $light-gray;
        background-color: transparent;
        font-style: italic;
        user-select: none;
    }
    body.dark &{
        background-color: $darkest-gray;
        border: 1px solid $light-gray;
        color: white;
        &:focus, &.focus {
            border-color: $primary-accent-color-highlight;
        }
        &:disabled{
            color: $gray;
            background-color: transparent;
            border-color: $darkest-gray;
        }
    }
    &.error{
        border-color: $error-color;
        box-shadow: $box-shadow-error;
    }
}
.pseudo-input{
    @extend input;
    display: flex;
    flex-wrap: wrap;
    padding: 0em;
    min-height: 3em;
    input{
        border: none;
        flex-grow: 1;
        margin: 0;
        flex-shrink: 1;
        width: 5em;
        display: inline;
        padding: 0 0 0 .5em;
    }
    &.disabled{
        @extend .pseudo-input-disabled;
    }
    textarea{
        border: none;
        width: 100%;
        margin: 0;
        transition: color $generic-transition-speed, background-color $generic-transition-speed;
    }
    &.time-input{
        flex-wrap: nowrap;
        justify-content: center;
        width: 6.5em;
        overflow: hidden;
        $spanPadding: .2em;
        &.disabled{
            background: $off-white;
            color: $gray;
            input{
                font-style: normal !important;
                color: inherit !important;
                &::-webkit-input-placeholder{
                    color: inherit !important;
                }
                &::-moz-placeholder{
                    color: inherit !important;
                }
                &:-ms-input-placeholder{
                    color: inherit !important;
                }
                &:-moz-placeholder{
                    color: inherit !important;
                }
            }
        }
        input{
            padding: 0;
            width: 1.5em;
            &.hours{
                text-align: right;
                + span{
                    margin-left: $spanPadding;
                }
            }
            &.seconds{
                text-align: left;
            }
            &.minutes{
                flex-basis: 1em;
                text-align: center;
                + span{
                    margin-right: $spanPadding;
                }
            }
        }
        span{
            line-height: 2.6em;
        }
        &.no-hours{
            .hours, .hours-colon{
                display: none;
            }
            .minutes{
                flex-basis: auto;
                text-align: right;
                + span{
                    margin-left: $spanPadding;
                }
            }
        }
    }
}

.pseudo-input-disabled{
    // border-color: $light-gray;
    input, textarea{
        background: $lightest-gray;
        color: $gray;
        font-style: normal;
    }
    .counter{
        color: $gray;
    }
    pointer-events: none;
}

.dropdown-disabled{
    color: $light-gray;
    border-color: $light-gray;
    pointer-events: none;
    &, select{
        background: $lightest-gray;
    }
    &:after{
        border-top-color: $gray;
        opacity: .75;
    }
    &.custom-dropdown:focus{
        border-color: $lightest-gray !important;
        &:after{
            border-top-color: $dark-gray !important;
        }
    }
}

.dropdown{
    padding: 0;
    overflow: hidden;
    position: relative;
    margin: .5em .5em .5em 0;
    display: inline-block;
    transition: box-shadow .2s;
    vertical-align: top;
    &.disabled{
        @extend .dropdown-disabled;
    }
    &.pseudo-dropdown{
        padding-left: .7em;
        cursor: pointer;
    }
    &.pseudo-dropdown:not(.selected){
        background: $secondary-bg-color;
        color: $gray;
    }
    &:not(.pseudo-dropdown):focus{
        border-color: $primary-accent-color !important;
        &:after{
            border-top-color: $primary-accent-color !important;
        }
    }
    &:after{
        content: '';
        top: 50%;
        right: .65em;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-width: .45em;
        margin-top: -.1em;
        // disabling this until I can figure out
        // why it was there in the first place
        // z-index: 1;
    }

    select, &.custom-dropdown .title {
        padding: 0 2.2em 0 .7em;
        width: 100%;
        font-size: 1em;
        line-height: 2.4em;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background-image: none;
        cursor: pointer;
        appearance: none;
        &:focus {
            color: $primary-accent-color !important;
        }
        &::-ms-expand {
            display: none;
        }
        &.outline{
            margin: .5rem;
            width: calc(100% - 1rem);
            padding-left: .35rem;
        }
    }
    @include dropdown(1.2rem, 20rem, 2.5em);
    &.error{
        border-color: $error-color;
        box-shadow: $box-shadow-error;
        color: $error-color-dark;
        &:after{
            border-top-color: $error-color-dark;
        }
        &.pseudo-dropdown:not(.selected){
            background-color: lighten($error-color-light, 20);
        }
    }
    &.loading{
        .spinner{
            opacity: .2;
        }
        &, *{
            cursor: progress !important;
        }
    }
}

.box-dismiss{
    cursor: pointer;
    display: inline;
    z-index: 100;
    float: right;
    font-size: 1.2em;
    position: relative;
    top: -.125em;
    width: 1em;
    text-align: right;
    color: $gray;
    .icon{
       margin: 0 !important;
       fill: currentColor !important;
    }
    &:hover{
        color: white !important;
    }
}

.custom-dropdown{
    overflow: visible;
    cursor: pointer;
    position: relative;
    &:not(.open):not(.open-up) ul{
        display: none;
    }
    &.open-up ul{
        position: absolute;
        bottom: 3rem;
    }
    .title{
        user-select: none;
        pointer-events: none;
    }
    ul{
        margin: 0;
        border: $generic-border;
        background: $primary-bg-color;
        z-index: 10000000;
        position: absolute;
        padding: 1rem 0;
        box-shadow: $box-shadow;
        &:not(.left){
            right: 0;
        }
        li{
            padding-left: 2rem;
            padding-right: 3rem;
            user-select: none;
            min-width: 15rem;
            line-height: 2.5;
            cursor: pointer;
            white-space: nowrap;
            &:hover{
                background-color: $secondary-bg-color;
            }
            &.disabled{
                background: $off-white;
                color: $lightest-gray;
                cursor: default;
            }
        }
    }
}

.search-bar{
    background-color: $primary-bg-color;
    height: 3em;
    outline-offset: .5rem;
    box-sizing: content-box;
    $search-button-width: 4em;
    $search-button-close-width: 2.5em;
    $input-left-margin: 1em;
    &.rounded{
        border-radius: .4rem;
    }
    input, button{
        height: 100%;
        line-height: 0;
        body.dark &, &{
            border: none;
        }
    }
    input{
        float:left;
        line-height: 3.25em;
        padding: 0;
        background-color: transparent;
        margin-left: $input-left-margin;
        display: inline;
        width: calc(100% - #{$search-button-width} - #{$input-left-margin});
        outline-offset: 0rem;
    }
    button{
        font-size: 1.3em;
        padding: 0 !important;
        width: math.div($search-button-width, 1.3) !important;
        float: right;
        margin: 0 !important;
    }
    button.search-close-button{
        display: none;
        width: math.div($search-button-close-width, 1.3) !important;
        outline-offset: -.25rem;
        @extend .active;
    }
    &:not(.sans-spinner){
        input.searching{
            ~ .search-button{
                pointer-events: none;
                .spinner{
                    opacity: .25;
                }
                .icon{
                    opacity: 0;
                }
            }
        }
    }
    &.filtering{
        input{
            width: calc(100% - #{$search-button-width} - #{$search-button-close-width} - #{$input-left-margin});
        }
        .search-button{
            border-left: solid $lightest-gray .1rem;
            body.dark &{
                border-left-color: $light-gray;
            }
        }
        &.focus .search-button{
            border-left-color: $primary-accent-color;
            body.dark &{
                border-left-color: $primary-accent-color-highlight;
            }
        }
        .search-close-button{
            display: inline-block;
        }
    }
    &.gray{
        border: none;
        background-color: $off-white;
        .search-button, &.focus .search-button{
            border-left-color: $light-gray;
        }
        .search-close-button{
            svg{
                fill: $light-gray;
            }
            .desktop &:hover{
                background-color: $lightest-gray;
                svg{
                    fill: $gray;
                }
            }
        }
    }
}
*:focus {
    outline: none;
}

.outline, .outline:hover, .reference-input.outline{
    outline-color: rgba($primary-accent-color, .5);
    outline-offset: .25rem;
    outline-style: dashed;
    position: relative;
    z-index: 1;
    outline-width: .1rem;
}
a{
    &, &:visited{
        color: $darkest-gray;
    }
    &.active{
        color: $primary-accent-color;
    }
    text-decoration: none;
}
.hidden{
    display: none !important;
}
.select-all{
    user-select: all;
}

.group{
    > div, > a{
        display: block;
        float: left;
        margin-right: .1rem;
        &:first-child:not(:only-child):not(.shortcut-tooltip){
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        &:last-child:not(:only-child):not(.shortcut-tooltip){
            margin: 0;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
        &:not(:first-child):not(:last-child):not(:only-child){
            border-radius: 0 !important;
        }
    }
}

.feedback{
    &.error  { color: $error-color; }
    &.success{ color: $primary-accent-color; }
}

.sk-wave-container{
    pointer-events: none !important;
    justify-content: center;
    display: flex;
}
.sk-wave{
    margin: 40px auto;
    color: $off-white;
    div{
        margin-right: .2rem;
        background-color: currentColor;
    }
}

.overflow-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes spinner {
    100% {
        transform: rotate(405deg);
    }
}

.spinner{
    transform: rotate(45deg);
    animation: spinner .8s linear infinite;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: .15em solid $darkest-gray;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    display: inline-block;
    line-height: 1em;
    vertical-align: middle;
    opacity: 0;
    transition: opacity $generic-transition-speed;
    pointer-events: none;
    &.visible{
        opacity: 1;
    }
}

.pipe:before{
    content: '|';
    margin: 0 .35em;
}

.code-group-container{
    $copy-button-width: 8rem;
    $copy-button-margin: 1rem;
    height: 3rem;
    position: relative;
    pointer-events: none;
    .copy-code-button, button + input{
        height: 3rem;
        float: left;
        pointer-events: all;
    }
    button + input{
        box-sizing: border-box;
        width: calc(100% - #{$copy-button-width});
        padding: 0 1rem;
        font-size: 1.1rem;
        &:disabled{
            color: $light-gray;
            border: none;
            background: $off-white !important;
        }
        // put this back if we want the disabled looking style
        // background-color: $secondary-bg-color;
        // border-color: $lightest-gray;
        // color: $light-gray;
        .mobile & {
            width: 100%;
        }
        &:focus{
            color: $darkest-gray;
        }
    }
    .refresh-copy{
        position: absolute;
        right: .1rem;
        bottom: .1rem;
        cursor: pointer;
        padding: .7rem;
        display: flex;
        align-items: center;
        background: rgba($primary-bg-color, .9);
        pointer-events: all;
        &:hover{
            background: $off-white;
        }
    }
    .copy-code-button{
        width: $copy-button-width - $copy-button-margin;
        margin-right: $copy-button-margin;
        display: inline-block;
        padding: 0;
        .mobile & {
            display: none;
        }
        span{
            pointer-events: none;
        }
        .after{
            display: none;
        }
        &.success{
            border-color: $success-color;
            color: $success-color;
            .after{
                display: inline;
            }
            .before{
                display: none;
            }
            &:hover{
                color: $success-color;
                background-color: $success-color-light;
            }
        }
    }
}

.tooltip{
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 1.1em;
    &:not(.init){
        overflow: hidden;
    }
    .tooltip-button{
        font-size: 1.6em;
        margin-top: -.3em;
        transition: color $generic-transition-speed;
        max-width: 1em;
        &:hover{
            color: $black;
        }
    }
    .tooltip-target{
        opacity: 0;
        transition: opacity $generic-transition-speed;
        pointer-events: none;
        box-shadow: $box-shadow;
        background: $primary-bg-color;
        padding: 1em 1.2em;
        font-size: 1.2em;
        position: absolute;
        border: .1rem solid $off-white;
        color: $darkest-gray;
        z-index: 10000;
        width: 22rem;
        top: 1.5em;
    }
    &.right-offset .tooltip-target{
        right: 0;
        left: auto;
    }
    &:focus, &:active, &:hover{
        .tooltip-target{
            opacity: 1;
        }
    }
}

span.text-highlight {
    background: $primary-accent-color-light;
    padding: 0 5px;
    border-radius: 4px;
}

.powered-by-sa{
    color: $dark-gray;
    .icon{
        vertical-align: baseline;
    }
    a, a:visited{
        color: inherit;
        &:hover{
            color: $gray;
        }
    }
}

@keyframes webcast-live-pulse {
	0% {transform: scale(1,1); opacity: 1;}
	25% {transform: scale(4,4); opacity: 0;}
	100% {opacity: 0}
}

$live-webcast-indicator-size: 1em;
$live-webcast-indicator-duration: 6.5s;
.live-webcast-indicator{
    display: inline-block;
    width: $live-webcast-indicator-size;
    height: $live-webcast-indicator-size;
    background: $error-color;
    vertical-align: middle;
    border-radius: $live-webcast-indicator-size;
    position: relative;
    overflow: visible;
    margin-bottom: .2rem;
    margin-left: .75rem;
    transform: scale(.6,.6);

    &:not(.static){
        &:after {
            content: "";
            display: block;
            width: $live-webcast-indicator-size;
            height: $live-webcast-indicator-size;
            animation: webcast-live-pulse $live-webcast-indicator-duration ease-out infinite;
            background: $error-color;
            border-radius: $live-webcast-indicator-size;
        }
    }


}
