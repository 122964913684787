.solo-header {
	background: rgba(19,94,137,1);

    .translation-dropdown-button{
		border-right-color: white;
		.icon{
			color: white;
		}
        > .translation-icon{
            background-color: white;
            color: $theme1-accent-color;
        }
    }

	@media screen and (min-width: $mobile-break) {

		background: rgba(19,94,137,.8);

	}
}
.new-tag{
    background-color: $theme1-accent-hover-color;
}

.audio-player {
    .sermon-details{
		.sermon-title, .sermon-subtitle{
			a, span{
				color: $black;
			}
		}
    }
	.player-controller {
		.control, .volume-slider, .popup-audio{
			&:hover{
				color: $darkest-gray !important;
			}
		}
        .chromecast{
            &:hover{
                google-cast-launcher{
                    --disconnected-color: #{$darkest-gray} !important;
                }
            }
        }
	}
}

.solo .markdown{
	--list-style-color: #{$theme1-accent-hover-color};
}

.blbColor{
   color: $theme1-accent-color !important;
}
.blbColor2{
    color: $theme1-accent-hover-color !important;
}

.solo-hero {
	background-color: #46cee6;
	background-image: url('../../solo/img/blue-gradient-1.png');
	background-size: cover;
	background-position: center center;

	h1 {
		color: white;
	}
}

.solo-button, .modal-container button {
	display: inline-block;
	padding: 10px 20px;
	border-radius: 100px;

	&:not(.icon-button){
		background-color: $theme1-accent-color;
	}

	.desktop &:hover {
		background-color: $theme1-accent-color-dark;
	}

	&, &:visited, .desktop &:hover {
		color: white;
	}
}

a {
	.about-text &, &.accent-link, .sermon-description &, .notice-message & {
		color: $theme1-accent-color;
		&:visited {
			color: $theme1-accent-color;
		}
		&:hover {
			color: $theme1-accent-hover-color;
		}


	}
}

.solo-bible-book-list {
	.solo-bible-sermon-count {
		background-color: $theme1-accent-color;
	}
	a {

	}
}


.solo-sermons-nav {
	.solo-block-content {

		@media screen and (min-width: $solo-nav-inline-break) {
			ul {
				li {
					&.current-tab a {
						border-left: 0px;
						background: none;
						border-bottom: 4px solid $theme1-accent-color;
					}
				}
			}
		}
	}
}

.sermons-anywhere-slider {
	.platform-icon, .slide-pager-icon {
		color: $theme1-accent-color;
	}

	.slide-pager-icon {

		&.active {
			background-color: $theme1-accent-color !important;
			color: white;
		}
	}
}

.solo-share-modal .share-modal .copy-code-button{
    border: none;
	color: white !important;
	background-color: $theme1-accent-color;
	&:hover{
		background-color: $theme1-accent-hover-color;
	}
}

.solo-download-sermon.open{
	&, &:hover{
		background-color: $theme1-accent-hover-color;
	}
}
