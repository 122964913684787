$squareButtonSize: 4rem;

@mixin webcast-button-colors($regular, $dark, $light, $text: white) {
    @include webcast-button-background-colors($regular, $dark, $light, $text);
    @include webcast-button-remove-background-colors($regular, $dark);
}
@mixin webcast-button-background-colors($regular, $dark, $light, $text:white) {
    border-color: $dark;
    color: $text;
    .icon, &.button:hover .icon{
        fill: $text;
    }
    &, &.button:hover{
        border-color: $dark;
        color: $text;
    }
    &.data{
        color: $dark;
    }
    &.button{
        background: $regular;
    }
    &.button:hover{
        background: $light;
    }
    &.data{
        background: lighten($light, 20);
    }
}
@mixin webcast-button-remove-background-colors($regular, $dark) {
    &.removeBackground{
        border-color: $regular;
        color: $regular;
        .icon{
            fill: $regular;
        }
        &.button:hover{
            border-color: $dark;
            color: $dark;
            .icon{
                fill: $dark;
            }
        }
        background: transparent !important;
    }
}

.webcast-button{
    min-width: $squareButtonSize;
    button, main{
        width: 100%;
        font-size: 1.2rem;
        height: $squareButtonSize;
        padding: 0;
    }
    em{
        font-style: normal;
    }
    @media screen and (max-width: 150px) {
        em, .data{
            display: none;
        }
        .icon{
            margin: 0 !important;
        }
    }
    button{
        span{
            font-weight: 400;
            font-size: 1.15rem;
            display: inline-block;
            vertical-align: bottom;
            margin-top: .1rem;
            .icon{
                font-size: 1.5rem;
                margin-right: .5rem;
            }
        }
        &.viewCount, &.peakCount{
            &.rounded.online{
                border-radius: .3rem .3rem 0 0;
            }
        }
    }
    .data{
        font-size: 1.1rem;
        height: 2.2rem;
        line-height: 2.2rem;
        border-width: .1rem;
        border-style: solid;
        border-top-width: 0;
        text-align: center;
        vertical-align: middle;
        span{
            margin: 0 .45rem;
        }
        &.borderless{
            border-width: 0 !important;
            &.removeBackground{
                line-height: 1rem;
            }
        }
        &.rounded{
            border-radius: 0 0 .3rem .3rem;
        }
    }

    .red{
        @include webcast-button-colors($error-color, $error-color-dark, $error-color-highlight);
    }
    .purple{
        @include webcast-button-colors($live-color, $live-color-dark, $live-color-light);
    }
    .blue{
        @include webcast-button-colors($primary-accent-color, $primary-accent-color-dark, $primary-accent-color-highlight);
    }
    .black{
        @include webcast-button-colors($darkest-gray, $black, $dark-gray);
    }
    .offline{
        @include webcast-button-background-colors($lightest-gray, $gray, $off-white, $gray);
        @include webcast-button-remove-background-colors($light-gray, $gray);
    }
}
