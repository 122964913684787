
@keyframes pulseWebcastBeacon {
    1%{
        background-color: rgba($live-color,0.5);
    }
    81%{
        background-color: rgba($live-color,0);
        width: 10rem;
        height: 10rem;
        margin-top: -3rem;
        margin-left: -3.5rem;
    }
    100% {
        background-color: rgba($live-color,0);
        width: 10rem;
        height: 10rem;
        margin-top: -3rem;
        margin-left: -3.5rem;
    }
}

@keyframes pulseUserLocation {
    1%{
        background-color: rgba($primary-accent-color-highlight,0.5);
    }
    81%{
        background-color: rgba($primary-accent-color-highlight,0);
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }
    100%{
        background-color: rgba($primary-accent-color-highlight,0);
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }
}

.broadcaster-marker{
  position: relative;
  .icon{
    font-size: 4.1rem;
  }
  &:hover .pin .background{
    fill: $primary-accent-color;
  }
  &:hover .pin-live .background{
    fill: $live-color-light;
  }
  &.marker-group{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    position: relative;
    .tower{
      display: none;
    }
    .number{
      color: white;
      -webkit-transform:translateZ(1em);
      text-align: center;
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: .35em;
    }
  }
  &.live-webcast-marker{
    svg{
        z-index: 1;
        position: absolute;
    }
    &:before{
      content: '';
      pointer-events: none;
      position: absolute;
      border-radius: 100%;
      width: 0rem;
      height: 0rem;
      margin-top: 1.25rem;
      margin-left: 1.5rem;
      background-color: rgba($live-color,0);
      animation: pulseWebcastBeacon 4s ease 2s infinite;
    }
  }
}



.centerMarker{
  margin-left: -5em !important;
  margin-top: -5em !important;
  height: 10em !important;
  width: 10em !important;
  border-radius: 100%;
  z-index: 1000000 !important;
  position: relative;
  font-size: $base-size;
  cursor: -webkit-grab;
  pointer-events: none !important;
  &:before, &:after{
    content: '';
    position: absolute;
    border-radius: 100%;
  }
  &:before{
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    background-color: rgba($primary-accent-color-highlight,0);
    animation: pulseUserLocation 4s ease 2s infinite;
  }
  &:after{
    background-color: $primary-accent-color-highlight;
    box-shadow: 0 0 1.5rem rgba(0,0,85,0.25);
    border: solid white 0.2rem;
    left: calc(50% - 0.7rem);
    top: calc(50% - 0.7rem);
    height: 1rem;
    width: 1rem;
  }
}
