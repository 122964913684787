.accordion-button{
    .icon{
        transition: transform .25s;
    }
    &:not(.closed):not(.minimized){
        .icon{
            &.angle-up{
                transform: rotate(180deg);
            }
            &.angle-down{
                transform: rotate(90deg);
            }
            &.angle-right{
                transform: rotate(90deg);
            }
        }
    }
}

.accordion-container{
    overflow: hidden;
    transition: height $generic-transition-speed;
    .accordion-button.closed + &, .accordion-button.minimized + &{
        height: 0 !important;
    }
    *{
        transform: translateZ(0);
    }
}

.pro-tip{
    cursor: pointer;
    border: .1rem solid $lightest-gray;
    border-radius: 1rem;
    padding: .875em;
    background: $secondary-bg-color;
    transition: all $generic-transition-speed;
    font-size: .9em !important;
    color: $gray;
    p{
        margin-bottom: 0;
    }
    .pro-tip-header{
        text-transform: uppercase;
        font-weight: bolder;
        white-space: nowrap;
        margin-right: .5em;
    }
    .angle-up{
        margin-left: 1em;
    }
    .pro-tip-subject{
        width: 100%;
        color: $darkest-gray;
    }
    .icon{
        font-size: 1.4em !important;
    }
    .lightbulb-exclamation{
        color: $success-color;
    }
    .accordion-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
