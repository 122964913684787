$stylized_ul_width: 1.1em;
$stylized_ol_width: 1.8em;
.markdown{
    // if we want lists to not be inset when by themselves, use this
    // ol, ul{
    //     &:first-child{
    //         padding-left: 1em;
    //     }
    // }
    a{
        text-decoration: underline;
    }
    p{
        line-height: 1.4em;
    }
    h1, h2, h3, h4, h5, h6{
        margin: 1em 0 !important;
    }
    h1{
        font-size: 1.75em !important;
    }
    h2{
        font-size: 1.5em !important;
    }
    h3{
        font-size: 1.25em !important;
    }
    h4, h5, h6{
        font-size: 1.15em !important;
    }
    li{
        margin-bottom: .5em;
        line-height: 1.4em;
    }
    &:not(.stylized-lists){
        li{
            list-style: unset;
        }
    }
    code{
        padding: .25em;
    }
    pre{
        padding: 1em;
    }
    code, pre{
        border-radius: .4rem;
        border: .1rem solid rgba(black, .2);
        background-color: rgba(white, .2);
    }
    pre code{
        padding: 0;
        background: none;
        border: none;
    }
    blockquote{
        margin-left: 0;
        padding-left: .5em;
        border-left: .2rem solid rgba(black, .2);
    }
    hr{
        border: none;
        border-bottom: .1rem solid currentColor;
        opacity: .5;
    }
    img{
        display: inline-block;
        max-width: 100%;
    }
    .image-container{
        width: 100%;
        box-sizing: border-box;
    }
    &.stylized-lists{
        li{
            &:before{
                color: var(--list-style-color);
                display: inline-block;
                font-weight: bold;
            }
        }
        ul{
            li{
                &:before{
                    width: $stylized_ul_width;
                    margin-left: -$stylized_ul_width;
                    content: '\2022';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                }
            }
        }
        ol{
            li{
                &:before{
                    width: $stylized_ol_width;
                    margin-left: -$stylized_ol_width;
                }
                &:nth-child(1):before {content: '1.';}
                &:nth-child(2):before {content: '2.';}
                &:nth-child(3):before {content: '3.';}
                &:nth-child(4):before {content: '4.';}
                &:nth-child(5):before {content: '5.';}
                &:nth-child(6):before {content: '6.';}
                &:nth-child(7):before {content: '7.';}
                &:nth-child(8):before {content: '8.';}
                &:nth-child(9):before {content: '9.';}
                &:nth-child(10):before{content: '10.';}
                &:nth-child(11):before{content: '11.';}
                &:nth-child(12):before{content: '12.';}
                &:nth-child(13):before{content: '13.';}
                &:nth-child(14):before{content: '14.';}
                &:nth-child(15):before{content: '15.';}
                &:nth-child(16):before{content: '16.';}
                &:nth-child(17):before{content: '17.';}
                &:nth-child(18):before{content: '18.';}
                &:nth-child(19):before{content: '19.';}
                &:nth-child(20):before{content: '20.';}
            }
        }
    }
}

.markdown-preview-page{
    &, body{
        margin: 0;
        padding: 0;
    }
    body{
        height: auto !important;
    }
    main{
        font-size: 1.4rem;
        padding: 0 1rem;
    }
}

.markdown-unit{
    display: flex;
    flex-direction: column;
    iframe{
        border: none;
        width: 100%;
    }
    > *{
        width: 100% !important;
        box-sizing: border-box !important;
        border-top: none;
        margin: 0;
    }
    textarea{
        border-color: $off-white;
    }
    .button-stack button{
        font-size: 1.2rem;
    }
}
