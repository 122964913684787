.speaker-input, .series-input{
    $standard-visible-li-count: 6;
    $short-list-visible-li-count: 3;
    $li-height: 5.2rem;
    position: relative;
    user-select: none;
    .title-divider{
        background-color: $lightest-gray;
        color: $darkest-gray;
        text-transform: uppercase;
        padding: 0 1.5rem;
        line-height: 2.5em;
        font-size: 1.2rem;
        font-weight: 600;
    }
    .active-series, .active-speaker{
        display: none;
    }
    .dropdown-selector{
        display: none;
        position: absolute;
        right: -.1rem;
        cursor: default;
        top: 3.7rem;
        max-width: 36rem;
        width: calc(100% - 3rem);
        border: solid .1rem $lightest-gray;
        background: $secondary-bg-color;
        > div > input{
           background: none;
           border: none;
           margin: 0;
           float: left;
           padding-left: .5em;
           min-width: 50%;
       }
    }
    .search-speakers, .search-series{
        border-bottom: none;
    }

    .clear-selection {
        display: none;
        position: absolute;
        right: 2em;
        top: 0;
        width: 2em;
        text-align: center;
        border-right: 1px solid $lightest-gray;

        &:hover {
            background: $off-white;
        }

    }

    &.selected .clear-selection {
        display: block;
    }

    .icon.search{
        display: block;
        float: left;
        line-height: 3em;
        height: 3em;
        padding: 1em 0 1em .75em;
        width: 2em;
    }
    .result-count{
        float: right;
        padding-right: 1rem;
    }
    .show-when-searched{
        position: relative;
    }
    &.active{
        overflow: visible;
        border-color: $lightest-gray !important;
        z-index: 2;
        .dropdown-selector{
            display: block;
            box-shadow: $box-shadow;
        }
    }
    &:not(.searching) .show-when-searched, &:not(.loading) .loading-speakers{
        display: none;
    }
    .loading-speakers {
        margin-left: .5rem;
        .spinner {
            position: absolute;
            right: 1.5rem;
            top: .75rem;
            opacity: 1;
        }
    }
    ul{
        margin: 0;
        padding: 0;
        border-top: 0;
        width: 100%;
        max-height: $li-height * $standard-visible-li-count;
    }
    &.short-list ul{
        max-height: $li-height * $short-list-visible-li-count;
    }
    li{
        background-color: $primary-bg-color;
        border-bottom: solid .1rem $lightest-gray;
        border-top: solid .1rem $lightest-gray;
        padding: .8rem 1.4rem;
        color: $darkest-gray;
        height: $li-height;
        cursor: pointer;
        overflow: hidden;
        .count{
            color: rgba($darkest-gray, .75);
            font-size: .9em;
        }
        &.hide{
            display: none;
        }
        &:first-child{
            border: none;
        }
        &:hover{
            background-color: $secondary-bg-color;
        }
        &.active{
            color: white;
            background-color: $primary-accent-color;
            border-color: $primary-accent-color-dark;
            border-bottom: solid .1rem $primary-accent-color-dark;
            height: $li-height + .1rem;
            + li{
                height: $li-height - .1rem;
                border-top: none;
            }
            .count{
                color: rgba(white, .75);
            }
        }
        &:last-child{
            border-bottom: none !important;
        }
        .name, .count{
            display: block;
            line-height: 1.2em;
        }
        .name{
            margin-top: .2rem;
            font-size: 1.4rem;
        }
        &.add-new{
            .name{
                line-height: 3.3rem;
            }
            .icon{
                margin: -.25rem 1.5rem 0 1.2rem;
                width: 1em;
            }
            .when-adding{
                height: 3.3rem;
                input{
                    margin: 0;
                    &:not([type="button"]){
                        padding: .65rem .75rem;
                        float: left;
                        width: calc(100% - 10rem);
                    }
                    &[type="button"]{
                        height: 2.25em;
                        padding: 0 1em;
                        float: right;
                        background: $primary-bg-color;
                    }
                }
            }
        }
    }
    li, .title-divider{
        + li:not(.active){
            border-bottom: none;
        }
    }
    &:not(.adding){
        .when-adding{
            display: none;
        }
    }
    &.adding{
        .not-adding{
            display: none;
        }
    }
}
.series-input{
    .count{
        font-style: italic;
    }
}
.speaker-input{
    .speaker-img{
        float: left;
        margin-right: 1rem;
    }
    li[value="Various Speakers"]{
        .name{
            line-height: 2.2em;
        }
        .speaker-img{
            width: 2.5rem;
            height: 2.5rem;
            + .speaker-img{
               margin-left: -2.5rem;
               margin-top: 1rem;
            }
        }
    }
    &:not(.has-searched) .add-new{
        background: $off-white;
        color: $gray;
    }
}
