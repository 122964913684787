
.tag{
    display: inline-block;
    background: $off-white;
    border-radius: 2em;
    margin: .35em;
    flex-shrink: 0;
    overflow: hidden;
    flex-grow: 0;
    position: relative;
    padding: .35em 1em;
    max-width: 100%;
    &.tag-template{
        display: none;
    }
    &:not(.in-progress):not(.counter){
        padding-right: 2.8rem;
    }
    &.in-progress{
        background: transparent;
        color: $gray;
        border: .1rem solid currentColor;
        /*
        IF USING ANIMATED ELLIPSIS - USE THESE VALUES
        padding: .1em 1em .25em;
        .animated-ellipsis{
            margin-left: .25em;
        }
        */
    }
    &:not(.counter):last-child{
        margin-right: 0;
    }
    > span{
        display: inline-block;
    }
    .tag-name{
        width: 100%;
        vertical-align: top;
        @extend .overflow-ellipsis;
    }
    .tag-remove{
        font-size: .8em;
        cursor: pointer;
        position: absolute;
        right: 1.2rem;
    }
}
.tag-input {
    &.outline {
        border-color: $primary-accent-color;
    }
}


.counter{
    position: absolute;
    right: 0;
    transition: opacity .75s;
    &.hide{
        opacity: 0;
    }
    &.tag{
        &.bottom-right{
            bottom: .5em;
            right: .75em;
        }
        &.maxed{
            background-color: rgba($warning-color, 1);
        }
    }
    &.text{
        color: $gray;
        &.maxed{
            color: $error-color;
        }
        &.bottom-right{
            bottom:  -2rem;
        }
    }
}
