@use "sass:math";

nav, .user-panel{
    height: calc(100vh - #{$header-height});
    top: $header-height;
    position: fixed;
    z-index: -2;
    opacity: 0;
    transition: opacity 1s, z-index 0.01s step-end;
    &.loaded{
        opacity: 1;
    }
    .nav-link{
        .icon{
            // fill: $gray;
            vertical-align: bottom;
        }
    }
    .nav-link, .divider{
        width: 100%;
        display: inline-block;
    }
    .divider{
        background: $lightest-gray;
        margin-bottom: .35em;
        height: .1rem;
    }
    .panels-switching &{
        transition-duration: math.div($wrapper-open-speed, 2);
    }
    .wrapper-transitioning &{
        transition-duration: $wrapper-open-speed;
    }
}

.user-panel{
    width: $user-panel-width;
    background: $black;
    right: 0;
    display: flex;
    flex-direction: column;
    .pane{
        padding-top: 2em;
        display: none;
        &.active{
            display: block;
        }
    }
    .section{
        border-bottom: .1rem solid $dark-gray;
        border-top: .1rem solid $dark-gray;
        margin: 2.5em 0;
        padding: .25em 0;
    }
    h3{
        color: $gray;
        font-size: 1.2em;
        margin-left: 1em;
        &.section-header{
            font-size: 1.4em;
            text-transform: uppercase;
        }
    }
    img.speaker-img{
        width: 1em;
        height: 1em;
    }
    .up-next{
        h3{
            color: white;
            text-transform: uppercase;
            display: inline-block;
        }
        *{ display: none; }
    }
    .sa-info{
        position: relative;
        height: 100%;
        > h3{
            margin-top: 0;
        }
        .scrolls{
            position: absolute;
            top: 3em; right: 0;
            bottom: 0; left: 0;
            overflow-y: auto;
        }
    }
    .featured-sermon{

    }
    .banners{
        padding-bottom: 3em;
        .slick-dots{
            margin-top: 1.5rem;
            li{
                button{
                    background: darken($dark-gray, 15);
                    border: none;
                    height: .9rem;
                    width: .9rem;
                    margin: .5rem .6rem;
                }
                &:hover button{
                    background: $dark-gray;
                }
                &.slick-active button{
                    cursor: default;
                    background: $light-gray;
                }
            }
        }
    }
    .player{
        .sermon-header{
            margin-bottom: 1em;
            padding: 0 1em;
        }
        .audio-player{
            padding: 0 2em;
        }
        h1{
            margin: 0;
            font-weight: 400;
            color: $lightest-gray;
            @include title-switcher();
        }
        .series{
            color: $gray;
            margin: .5em 0;
            label{
                font-weight: 600;
                margin-right: 1em;
            }
            h2{
                font-weight: 400;
                display: inline-block;
                font-style: italic;
                margin: 0;
            }
        }
    }
    .divider{
        background: $dark-gray;
    }
}

nav{
    background: $off-white;
    width: $nav-width;
    font-size: 1.4em;
    padding: 2em 0 0 0;
    opacity: 0.0001;
    .icon{
        display: inline-block;
        margin-right: 1em;
        width: 1em;
    }
    .nav-link{
        padding: .75em 0 .75em 2.5em;
        font-weight: 400;
        .desktop &:hover{
            background-color: rgba(black, .035);
            color: $black;
            .icon{
                fill: $black;
            }
        }
    }
}
nav, .user-panel{
    @include breakpointMax($mobile-width) {
      width: 100vw;
    }
}
.panel-shader{
    background-color: rgba(black, .6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: all $wrapper-open-speed $wrapper-ease;
    .desktop &:hover{
        background-color: rgba(black, .5);
    }
    .wrapper-open &{
        opacity: 1;
        pointer-events: auto;
    }
}

main{
    z-index: 100;
    position: relative;
    left: 0;
}
html.modalized{
    // This stops scrollbars on desktop, but seems to completely break iOS
    body.desktop{
        overflow: hidden;
    }
    &, nav, .overscroll{
        background-color: white !important;
    }
    main{
        // creates an unwanted scrollbar...
        // overflow-y: scroll;
        z-index: unset;
    }

}
main, .sidebar, .breadcrumbs, .fixed-body-header{
    transition: left $wrapper-open-speed $wrapper-ease, width $wrapper-open-speed $wrapper-ease;
}
.nav-open{
    main, .panel-shader, .sidebar, .breadcrumbs, .fixed-body-header{
        left: $nav-width !important;
        @include breakpointMax($mobile-width) {
            left: 100vw !important;
        }
    }
    @include breakpointMin($modal-stack-width){
        .sidebar + main{
            &, .fixed-body-header{
                left: $nav-width + $sidebar-width !important;
            }
        }
        .sidebar.minimized + main{
            &, .fixed-body-header{
                left: $nav-width + $sidebar-minimized-width !important;
            }
        }
    }
    nav{
        z-index: 2;
    }
}
.user-panel-open{
    main, .panel-shader, .sidebar, .breadcrumbs, .fixed-body-header{
        left: -$user-panel-width !important;
        @include breakpointMax($mobile-width) {
            left: -100vw !important;
        }
    }
    @include breakpointMin($modal-stack-width){
        .sidebar + main{
            &, .fixed-body-header{
                left: -$user-panel-width - $sidebar-width !important;
            }
        }
        .sidebar.minimized + main{
            &, .fixed-body-header{
                left: -$user-panel-width - $sidebar-minimized-width !important;
            }
        }
    }
    .user-panel{
        z-index: 2;
    }
}

header .menu-icon{
    transition: all $wrapper-open-speed $wrapper-ease;
}

.wrapper-open, .wrapper-transitioning{
    overflow: hidden;
    position: fixed;
    height: 100%;
}
