.solo-block-content{
	.grid-col{
		margin-bottom: 0 !important;
	}
}

.solo-block-content.audio-video-switch {
	text-align: center;
	margin-bottom: 0;
	padding: 0;

	.switch-media {
		display: inline-block;
		padding: 1rem 2rem;
		border-bottom: 5px solid transparent;
		color: white;

		&:hover {
			border-bottom: 5px solid rgba(255,255,255,.5);
		}

		.display-audio &.switch-to-audio,
		.display-video &.switch-to-video {
			border-bottom: 5px solid white;
		}
	}
}

.solo-download-sermon{
	ul{
		text-align: left;
		margin: 1.5rem 0;
    }
}

.solo-pdf-banner{
    background: $off-white;
	padding: 1.5rem;
	margin: 1rem 0 1.5rem 0;
	border-radius: .4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon{
		margin-right: 1rem;
	}
	button{
		border-color: transparent;
		margin-left: 1rem;
	}
}

.solo-sermon-block {
	background: rgba(255,255,255,.5);
	.player-overlay{
		position: absolute;
		border-radius: 0 !important;
	}
	.sermon-block-content {
		padding-top: 0;
	}

	&.solo-audio-block, &.solo-video-block {
		padding-top: 0;

		.solo-block-content {
			padding: 0;

			.grid-col {
				overflow: hidden;
				height: 0;
				transition: height $generic-transition-speed, padding $generic-transition-speed;
				position: relative;

				.video-player {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					right: 0;
				}

				.offline-message {
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;

					div {
						color: white;
						background: $gray;
						padding: 2rem;
						border-radius: .3rem;
					}
				}
			}
		}
	}

	&.solo-audio-block {
		position: relative;
		.grid-col{
			padding: 0 2rem;
			@media screen and (min-width: $mobile-break ) {
				padding: 0 3rem;
			}
		}
		.solo-audio-player {
			margin-top: 8rem;
			google-cast-launcher{
				font-size: 1em;
				vertical-align: sub;
			}
            .player-contents{
                .time-controller, .player-controller{
                    .group{
                        &.left-group{
                            div:not(.volume-slider-fill-container){
                                left: -.25rem;
                            }
                        }
                        &.right-group{
                            div:not(.shortcut-tooltip), a{
                                right: -.65rem;
                            }
                        }
                    }
                }
            }
		}
	}

	&.solo-video-block {
		background: $darkest-gray;
		position: relative;

		.solo-block-content .grid-col {
			padding: 0;
		}
	}

	.display-audio &.solo-audio-block .solo-block-content .grid-col {
		height: 22rem;
	}
	.display-video &.solo-video-block .solo-block-content .grid-col {
		width: 100%;
		padding-top: 56.25%;
	}
}

.custom-dropdown.solo-download-sermon ul{
	a{
		margin: 0 !important;
	}
	@media screen and (max-width: $mobile-break - 1 ) {
		right: auto;
		left: 0;
	}
}

.solo-sermon-page-metadata,
.solo-webcast-page-metadata {
	background: white !important;
	align-items: center;
	padding: 0 1rem;

	h1 {
		text-align: left;
		font-size: 1.5em;
	}

	.solo-block-content:first-child {
		padding-top: 0;
	}
	.solo-sermon-page-metadata-top {
		margin: 0 0;
		padding: 10px 0 20px;

		.solo-sermon-metadata-main {
			.tabular-data {
				padding-top: 1rem;
				margin-left: 0;
				line-height: 1.3em;
				margin-top: 2rem;
				display: flex;
				.column{
					white-space: nowrap;
					&.labels{
						font-weight: bold;
						margin-right: 2rem;
						min-width: 10rem;
					}
				}

				.row{
					margin-bottom: .5rem;
				}
			}
            .accent-link{
                overflow: hidden;
                text-overflow: ellipsis;
            }
		}

        strong{
            text-transform: uppercase;
        }

		.speaker-display {
			display: flex;
		}

		.speaker-round {
            width: 7rem;
            height: 7rem;
		}

		.speaker-name {
			width: 25rem;
			margin-left: 1rem;
			padding-top: 1rem;
			line-height: 1.3em;

			strong {
				display: block;
				margin-bottom: .5rem;
			}
		}

		.solo-sermon-actions {
			margin-left: 0;
			margin-top: 1.5rem;
			padding-top: .6rem;
            a{
				margin-top: .5rem;
            }
		}
	}

	@media screen and (min-width: $mobile-break) {

		h1 {
			font-size: 2em;
		}

		.solo-sermon-page-metadata-top {
			display: flex;
			justify-content: space-between;

			.solo-sermon-actions {
				margin-top: 0;
				margin-left: 0;

				.solo-button-group{
					text-align: right;
					button{
						margin-right: 0;
					}
					a{
						margin-left: 1rem;
					}
				}
			}
		}
	}

	@media screen and (min-width: 900px) {
		.solo-sermon-page-metadata-top {
			.solo-sermon-metadata-main {
				display: flex;
				width: 70%;

				.tabular-data {
					margin-top: 0;
				}
			}
		}
	}
}

.solo-webcast-page-metadata {
	.solo-sermon-page-metadata-top {
		.solo-sermon-metadata-main {
			.tabular-data {
				margin-left: 0;
				width: 100%;
				strong{
					display: inline-block;
					width: 100%;
					span{
						font-weight: normal;
					}
				}
			}
		}
	}
}

.audio-player{
	.speed{
		width: 2.5em !important;
	}
	@media screen and (max-width: $mobile-break) {
		.volume-slider{
			display: none !important;
		}
	}
	.shortcut-tooltip{
		line-height: 2.5em !important;
		bottom: 4.2em !important;
	}
}
.audio-player{
	.speed{
		width: 2.5em !important;
	}
	@media screen and (max-width: $mobile-break) {
		.volume-slider{
			display: none !important;
		}
	}
	.shortcut-tooltip{
		line-height: 2.5em !important;
		bottom: 4.2em !important;
	}
}

.sermon-description {
	border-top: 1px solid $light-gray;
	padding-top: 4rem;
	margin-bottom: 2rem;

	p {
		margin: 0;
		line-height: 1.6em;
        + p{
            margin-top: 1em;
        }
	}
}
