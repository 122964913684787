$video-background-color: rgba(0,0,0,.85);
.player-overlay{
    font-size: 10px;
    text-align: left;
    transition: top $generic-transition-speed, opacity $generic-transition-speed;
    position: absolute;
    right: 0;
    height: auto !important;
    width: calc(100% - 1.5em) !important;
    background: $black;
    padding: .75em;
    margin: 0 !important;
    clear: both !important;
    line-height: 1 !important;
    opacity: 0;
    a, button{
        color: $off-white;
        border-color: $lightest-gray;
        text-transform: none;
        &:hover{
            color: white;
        }
    }
    .title{
        text-transform: uppercase;
        margin-bottom: .5em;
        &:not(a){
            color: white;
        }
        .icon{
            position: relative;
            top: -.125em;
        }
    }
    .dismiss{
        @extend .box-dismiss;
    }
    &.show{
        top: 0;
        opacity: 1;
    }
    .video-player &{
        background-color: $video-background-color;
        &.show{
            top: 1em;
        }
        right: 1em;
    }
    &.webcast-notification, &.casting-notification{
        color: white;
        z-index: 4;
        width: auto !important;
        font-size: 1.3em;
        top: -4em;
        border-width: .1rem;
        border-style: solid;
        display: flex;
        align-items: center;
        min-height: 1.5em;
        max-width: calc(100% - 3.5em);
        .dismiss{
            font-size: .9em;
            top: 0;
            right: .3rem;
            position: absolute;
        }
        button{
            margin-left: .75em;
            color: $off-white;
            border-color: currentColor;
            white-space: nowrap;
            &:not(:hover){
                background-color: $video-background-color;
            }
            &:hover{
                color: white;
                background-color: $darkest-gray;
            }
        }
        > .icon{
            margin-right: .35em;
            flex-shrink: 0;
        }
        @include breakpointMax(360px){
            flex-wrap: wrap;
            > .icon{
                display: none;
            }
            button{
                width: 100%;
                margin-left: 0 !important;
                margin-top: 1rem !important;
            }
        }
    }
    &.webcast-gone-offline-notification{
        border-color: $error-color;
    }
    &.webcast-back-online-notification, &.webcast-new-stream-notification{
        border-color: $success-color;
    }
    &.casting-notification{
        border-color: $primary-accent-color;
    }
    .device-name{
        margin-left: .2em;
    }
}
.share-window{
    z-index: 3;
    width: calc(100% - 1.5rem) !important;
    padding: .75rem;
    top: -16.5em;
    border-radius: .3rem;
    @include breakpointMin(150px, true){
        width: calc(100% - 3em) !important;
        max-width: 31em;
        padding: 1.5em;
        border-radius: 0 .3rem 0 0;
    }
    @include breakpointMax(149px, true){
        height: calc(100% - 1.5rem) !important;
    }
    button{
        @include breakpointMax(149px, true){
            width: 3rem;
        }
        &:hover:not(.success){
            color: white !important;
            background-color: transparent;
        }
        &.copy-code-button{
            &:hover{
                background: rgba(white, .25);
                color: white;
            }
            @include breakpointMax($breakpoint-micro){
                font-size: 1.2em;
                padding: .5em .75em 1.25em !important;
                width: 100% !important;
                margin-top: .5em;
            }
            @include breakpointMax(149px, true){
                font-size: 1.2em;
                padding: .25em 1em .35em !important;
                height: 2.5rem;
            }
        }
    }
    .button-set{
        .icon-button{
            color: $lightest-gray;
        }
        @include breakpointMin(150px, true){
            font-size: 2.25em;
        }
        @include breakpointMax($breakpoint-micro){
            font-size: 2em;
            text-align: center;
            width: 100%;
            justify-content: space-between;
            display: flex;
            left: 0;
            .icon-button{
                padding-left: 0;
                padding-right: 0;
                width: auto;
            }
        }
    }
    input{
        @include breakpointMax(149px, true){
            width: calc(100% - 18rem) !important;
            height: 2.5rem !important;
        }
    }
    .title{
        @include breakpointMax(149px, true){
            font-size: 1.2em;
        }
        @include breakpointMax($breakpoint-micro){
            font-size: 1em;
            .share-outline.icon{
                display: none;
            }
        }
    }

    .mini &{
        .qr-share, input{
            display: none;
        }
        @include breakpointMin($breakpoint-micro){
            .button-set{
                top: -.5rem;
            }
        }
    }
}
.broadcaster-online-notification{
    top: -8.75em;
    &.clicked{
        top: -5.5em;
    }
    z-index: 2;
    max-width: 18em;
    .title{
        font-size: 1.2em;
        @include breakpointMax($breakpoint-micro){
            font-size: 1em;
        }
    }
    a{
        max-width: 100%;
    }
    button{
        // padding: .6em 1em;
        // margin-left: 1.6em;
        // line-height: .9em;
        white-space: inherit;
        font-size: 1em;
        // @include breakpointMax($breakpoint-micro){
        //     margin-left: 1.2em;
        // }
        max-width: 100%;
    }
    display: flex;
    .live-webcast-indicator{
        margin-left: 0;
        flex-shrink: 0;
        margin-right: .4em;
        + div{
            width: calc(100% - 1.5em);
        }
    }

    // .video-player &{
    //     padding: 1em;
    //     font-size: 1em;
    //     button{
    //         font-size: 1.1em;
    //         line-height: 1.1em;
    //         margin-left: 1em;
    //         margin-top: .2rem;
    //         margin-bottom: .2rem;
    //     }
    // }
}
