$base-cluster-size: 34px;
$cluster-multiplier: 10px;
$base-cluster-border-radius: 2px;
$cluster-border-radius-multiplier: 4px;

$cluster-border-color:        rgba(darken($primary-accent-color,15), .3);
$cluster-background-color:    $primary-bg-color;
$cluster-color:               $dark-gray;

$popup-footer-height: 3rem;
