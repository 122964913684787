
.audio-player-frame{
    $default-audio-height: 149px;
    padding: 1rem;
    border-radius: .3rem !important;
    overflow: hidden !important;
    background-color: $off-white;
    display: flex;

    .volume-shortcuts{
        left: -2rem !important;
    }

    @include breakpointMax($default-audio-height, true){
        padding: .5rem 1rem;
    }
    .dark &{
        background-color: $darkest-gray;
    }
    body:not(.legacy) &{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .legacy &{
        background-color: transparent;
        height: $embed-height-legacy;
        margin-top: 4em;
        position: relative;
        border-radius: 0 !important;
    }
    .album-art{
        display: flex;
        align-items: center;
    }
    .album-art img{
        height: calc(100vh - 2rem);
        margin-right: 1rem;
        user-select: none;
        &.mini{
            border-radius: 50%;
            @media (max-width: $breakpoint-micro) {
                display: none;
                height: 0;
                margin-right: 0;
            }
        }
        @include breakpointMax($default-audio-height, true){
            height: calc(100vh - 3.25rem);
            max-height: 67px;
            &.full{
                display: none;
            }
        }
        @media (min-height: $default-audio-height + 1) and (max-width: $breakpoint-medium) {
            display: none;
            height: 0;
            margin-right: 0;
        }
    }
    .audio-player{
        flex: 2;
        width: 100%;
        @include breakpointMax($default-audio-height, true){
            display: flex;
            .sa_wave-container{
                height: calc(100% - 1.9rem - 2rem);
                > div{
                    height: 100%;
                }
            }
            .time-controller{
                pointer-events: none;
                opacity: 0;
                height: 1.25em;
                margin-top: .2rem;
            }
            &.scrubbing .time-controller{
                opacity: 1;
            }
            .player-contents{
                width: 100%;
            }
            .player-controller{
                margin-top: -1.8em;
                .group{
                    font-size: .8em;
                    height: 2.5em;
                    div, a{
                        line-height: 1.2em;
                        padding: 0;
                        &.play{
                            height: 1.8em;
                        }
                    }
                    &.center-group div{
                        line-height: 1.1em;
                    }
                }
            }
            &.paused.unplayed, &.scrubbing{
                .player-contents .player-controller .group{
                    .control, .popup-audio, .volume-slider{
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
            &.paused.unplayed{
                .player-contents .player-controller .group{
                    div.play{
                        opacity: 1;
                        top: -1.3em;
                        pointer-events: all;
                    }
                }
            }
            &:not(.unplayed){
                .player-contents .player-controller .group{
                    div.play{
                        top: -.2rem;
                    }
                }
            }
        }
        .legacy &{
            margin: 0;
        }
        @include breakpointMax($breakpoint-medium){
            margin: 0;
        }
        .sermon-details{
            max-width: calc(100% - 22rem);
            height: 3.25em;
            > div{
                @extend .overflow-ellipsis;
            }
            @include breakpointMax($breakpoint-medium){
                max-width: calc(100% - 5rem);
            }
            @include breakpointMax($breakpoint-micro){
                max-width: 100%;
            }
            @include breakpointMax($default-audio-height, true){
                height: auto;
                margin-bottom: .5rem;
                .sermon-subtitle{
                    display: none;
                }
                .sermon-title{
                    font-size: .9rem;
                }
            }
        }
        @include breakpointMax($breakpoint-medium){
            .volume-slider{
                display: none !important;
            }
            .speed-shortcuts{
                left: -2rem;
                transform: initial;
            }
        }
        @include breakpointMax($breakpoint-micro){
            .skip-backward, .skip-forward, .mute{
                display: none !important;
            }
        }
        .add-to-playlist{
            display: none !important;
        }
    }

    .info-container{
        position: absolute;
        font-size: 1.4rem;
        top: 1rem;
        right: 1rem;
        @include breakpointMax($default-audio-height, true){
            top: .5rem;
        }
        > div, > a{
            display: block;
            float: left;
            margin-right: 1rem;
            color: $darkest-gray;
            .dark &{
                color: $light-gray;
            }

            @include breakpointMax($breakpoint-micro){
                &.logo, .share{
                    display: none !important;
                }
            }
            @include breakpointMax($breakpoint-medium){
                &.play-count, &.download-button, &.pdf-download-icon{
                    display: none;
                }
                &.share-open{
                    margin-right: 0;
                }
            }
            .legacy &.play-count{
                display: block;
            }
            &:last-child{
                margin-right: 0;
            }
            &.play-count{
                font-size: .8em;
                line-height: 2.1em;
                .icon{
                    width: 1em;
                    font-size: .75em;
                    vertical-align: baseline;
                }
            }
            .ie &{
                &.download-button, &.share-open{
                    width: 1em;
                }
            }
            &.logo{
                .small{
                    display: none;
                }
                &:hover{
                    color: $dark-gray;
                    .dark &{
                        color: white;
                    }
                }
                @include breakpointMax($breakpoint-medium){
                    .full{
                        display: none;
                    }
                    .small{
                        display: block;
                    }
                }
            }
        }
        .legacy &{
            top: .5rem;
        }
    }


    // .noHTML5 .noHTML5Message{
    //     @media screen and (min-width: $breakpoint-large) {
    //         font-size: 1.4rem;
    //         line-height: 1.9rem;
    //     }
    //     @media screen and (max-width: $breakpoint-small) {
    //         font-size: 1.1rem;
    //         line-height: 1.4rem;
    //     }
    //     @media screen and (max-width: $breakpoint-micro) {
    //         font-size: 1rem;
    //         padding: .5rem;
    //     }
    // }
}
