// these are the browser type icons on the embed-editor page
.embed-thumb {
    $thumb-border-width: .2em;

    width: 13.2em;
    height: 13.2em;
    border: $thumb-border-width solid $light-gray;
    position: relative;
    display: flex;
    flex-direction: column;
    div {
        box-sizing: border-box;
    }

    .embed-thumb-header {
        background: $primary-bg-color;
        border-bottom: $thumb-border-width solid $light-gray;
        width: 100%;
        padding: .5em;

        .embed-thumb-rect {
            width: 2.2em;
            height: 1.5em;
            background: $primary-accent-color;
        }
    }

    .embed-thumb-body {
        background: #cbcbcb; // TODO: changes this
        height: 100%;

        .embed-thumb-filters {
            overflow: auto;
            padding: .5em;
        }

        .embed-thumb-text {
            float: left;
        }

        .embed-thumb-text-rect1,
        .embed-thumb-text-rect2 {
            background: $gray;
            height: .4em;
        }

        .embed-thumb-text-rect1 {
            background: $gray;
            width: 3em;
            margin-bottom: .1em;
        }

        .embed-thumb-text-rect2 {
            background: $gray;
            width: 2em;
        }

        .embed-thumb-circles {
            float: right;
        }

        .embed-thumb-circle1,
        .embed-thumb-circle2 {
            background: $gray;
            width: .8em;
            height: .8em;
            border-radius: .8em;
            float: right;
            margin-left: .2em;
        }

        .embed-thumb-ul {
            height: 6.2em;
            overflow: hidden;
            position: relative;
            margin: 0 .5em;

            .embed-thumb-li {
                background: $primary-bg-color;
                height: 1.9em;
                border: $thumb-border-width solid $light-gray;
                margin-bottom: .6em;
                padding: .3em .5em;
                margin-right: 1.2em;

                .embed-thumb-li-circle {
                    background: $primary-accent-color;
                    width: 1em;
                    height: 1em;
                    border-radius: 1em;
                }
            }
        }

        .embed-thumb-scrollbar {
            background: $light-gray;
            width: .8em;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            overflow: hidden;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 20%;
                background: $dark-gray;
            }
        }
    }

    .embed-thumb-footer {
        background: $primary-bg-color;
        height: 1.8em;
        border-top: $thumb-border-width solid $light-gray;
    }

    &.classic {
        .embed-thumb-ul {
            margin: 0 .5em;
            height: 6.4em;
        }
    }

    &.compact {
        .embed-thumb-ul {
            margin: 0;
            height: 7em;
            .embed-thumb-li {
                height: 1.9em;
                margin-bottom: 0;
                padding: .4em .5em;
                border-bottom: none;
                border-left: none;
                margin-right: 0;
            }
        }
    }
}
