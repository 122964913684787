@use "sass:math";

@mixin playButton() {
    border-radius: 50%;
    border: solid .1rem currentColor;
    width: $sermon-list-button-icon-width + $sermon-list-play-button-extra-width;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .icon{
        padding-left: .25rem;
        width: $sermon-list-button-icon-width + $sermon-list-play-button-extra-width;
        transform: rotate(0deg);
        transition: transform $sermon-list-sermon-transition-time;
        fill: currentColor;
        max-width: none;
    }
}

.sermon-count{
    .sk-wave{
        opacity: 0;
        height: 0;
        margin: 0 auto;
        transition: all .75s;
        overflow: hidden;
    }
    &.visible{
        .sk-wave{
            opacity: 1;
            margin: 1.5rem auto;
            height: 4rem;
        }
    }
}

.sermon-list{
    overflow: auto;
    &.no-scroll{
        overflow: hidden;
    }
    h2{
        text-transform: uppercase;
        text-align: center;
        margin: 2rem 0;
        color: $gray;
        font-weight: normal;
    }

    .sermon{

        &.audio{ @include sermon-list-widths(false, true); }
        &.video{ @include sermon-list-widths(true, false); }
        &.audio.video{ @include sermon-list-widths(true, true); }
        .img-container{
            margin-right: 1rem;
            width: $sermon-list-bar-height;
            display: inline-block;
            height: $sermon-list-bar-height + 1.5rem;
            img, .speaker-img{
                height: $sermon-list-bar-height;
                width: $sermon-list-bar-height;
            }
            @media screen and (max-width: $mobile-width){
                width: 0;
                margin: 0;
                overflow: hidden;
            }
        }
        position: relative;
        padding: 1.5rem 1.25rem 0;
        border: solid .1rem $lightest-gray;
        background-color: $primary-bg-color;
        margin-bottom: .4rem;
        overflow: hidden;
        text-align: left;
        outline-offset: -.1rem;
        &.animate{
            @include fadeInUp( $duration: 1s );
            &.remove{
                @include fadeOutDown( $duration: .35s );
            }
        }

        &:nth-last-of-type(1){
            margin-bottom: 0;
        }
        > div{
            float: left;
        }
        .info{
            height: $sermon-list-bar-height + 1.5rem;
            .title{
                font-size: 1.4rem;
                height: 1.8rem;
                font-weight: 400;
                color: $darkest-gray;
                display: block;
                margin-top: -.1rem;
                line-height: 1.7rem;
                @extend .overflow-ellipsis;
                &:hover{
                    text-decoration: underline;
                }
                @include title-switcher(540px);
            }
            .more-info{
                color: $dark-gray;
                font-size: 1.3rem;
                height: 1.6rem;
                margin-top: .1rem;
                .icon {
                    fill: currentColor;
                    width: .8em;
                    vertical-align: bottom;
                }
                @extend .overflow-ellipsis;
                span{
                    a{
                        margin-left: .25rem;
                        &:hover .icon{
                            fill: $darkest-gray;
                        }
                    }
                    &:before{
                        content: ' | ';
                    }
                    &:nth-child(1){
                        font-style: italic;
                        &:before{
                            content: '';
                        }
                    }
                    &:nth-child(2):before{
                        content: ' - ';
                    }
                }
            }
        }
        &.audio.video .video-button{
            margin-right: $sermon-list-video-margin - .4rem;
        }
        .buttons{
            margin-left: $sermon-list-button-container-margin;
            height: $sermon-list-bar-height;
            color: $light-gray;
            div, a{
                float: right;
                height: $sermon-list-bar-height;
                line-height: $sermon-list-bar-height - .2rem;
                cursor: pointer;
                font-size: 1.2rem;
                margin-left: $sermon-list-button-icon-margin;
                color: inherit;
                &:last-child{
                    margin-left: 0;
                }
                .icon{
                    width: $sermon-list-button-icon-width;
                    fill: currentColor;
                }
                &:hover{
                    color: $darkest-gray;
                }
            }
            .play-button{
                @include playButton ();
            }
            .download-button{
                .ie &{
                    width: 2rem;
                }
                @media screen and (max-width: $mobile-width){
                    display: none;
                }
            }
            $video-border-radius: .3rem;
            .video-button{
                position: relative;
                background-color: rgba(black, .25);
                padding: 0;
                height: $sermon-list-video-height - .2rem;
                border-width: .1rem;
                box-sizing: content-box;
                border-style: solid;
                border-radius: $video-border-radius;
                overflow: hidden;
                border-color: $primary-bg-color;
                margin-top: -$sermon-list-extra-video-height;
                .icon{
                    position: absolute;
                    top: math.div($sermon-list-video-height, 2) - .6rem;
                    width: 100%;
                    fill: white !important;
                    z-index: 1;
                    max-width: none;
                }
                img, div{
                    height: $sermon-list-video-height - .2rem;
                }
                img{
                    display: block;
                    margin: auto;
                }
                div{
                    opacity: .5;
                    background: black;
                    width: $sermon-list-video-width;
                }
                &:hover{
                    background-color: 0;
                    div{
                        opacity: .75;
                    }
                }
            }
        }
        .sk-wave{
            margin: 2rem auto;
            position: absolute;
            top: 4.5rem;
            left: 0;
            right: 0;
            opacity: .5;
            transition: opacity 1s;
            color: $lightest-gray;
        }
        .player-container{
            width: 100%;
            position: relative;
            overflow: hidden;
            opacity: 0;
            height: 0;
            transition: height $sermon-list-sermon-transition-time, opacity 1.25s;

            &.loaded{
                opacity: 1;
                & + .sk-wave-container .sk-wave{
                    opacity: 0;
                }
            }
            .audio-player{
                margin-top: -1rem;
            }
            @media screen and (max-width: 320px){
                .speed, .skip-backward, .skip-forward{
                    display: none;
                }
            }
        }

        &.open{
            height: auto;
            .player-container{
                height: 8.5rem;
            }
            .buttons{
                .play-button{
                    color: $gray;
                }
                .play-button .icon{
                    transform: rotate(90deg);
                }
                .video-button{
                    display: none;
                }
            }
        }
        &.remove-player .buttons .popup-audio{
            @include playButton ();
        }
        &.open .buttons .popup-audio{
            width: $sermon-list-button-icon-width;
        }
    }

    body.dark &{
        color: $dark-gray;
        > h2{
            color: $darkest-gray;
        }
        .sermon{
            background-color: $darkest-gray;
            border-color: $dark-gray;
            .speaker-img{
                box-sizing: border-box;
            }
            .info{
                .title{
                    color: $off-white;
                }
                .more-info{
                    color: $light-gray;
                    .icon {
                        fill: $light-gray;
                    }
                }
            }
            &.open .play-button{
                color: white;
            }
            .buttons{
                div:hover, a:hover{
                    color: $off-white;
                }
                .video-button{
                    border-color: $light-gray;
                    background-color: rgba(white, .25);
                }
            }
            .group{
                color: white;
                .icon{
                    fill: white;
                }
            }
        }
    }
}

.pagination{
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 1.4rem;
    display: flex;
    button{
        text-transform: uppercase;
        padding: 0;
        line-height: 1em;
    }
    > button, > div{
        display: flex;
    }
    .skips{
        margin: 0 1rem;
        button{
            width: 3.5rem;
            font-size: 1em;
            &:first-child{
                margin-right: .5rem;
            }
            @media screen and (max-width: $mobile-width - 40px){
                display: none;
            }
            .icon{
                font-size: 1.4em;
            }
        }
    }
    .pages{
        button{
            margin-right: .5rem;
            min-width: 2.75rem;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                cursor: default;
                &:hover{
                    background-color: transparent !important;
                    color: $primary-accent-color;
                }
            }
        }
    }
}

.sermon-list-pagination{
    height: $sermon-list-pagination-height - 2.5rem;
    display: none;
    padding: 1.25rem 1.5rem;
    @extend .pagination;
}
