@keyframes scale-from-zero {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.scale-from-zero {
  animation-name: scale-from-zero;
  animation-duration: .6s;
  animation-iteration-count: 1;
}
