.features{
    width: 1px;
    min-width: 100%;
    .slide{
        a, img{
            width: 100%;
        }
        opacity: .95;
        transition: opacity .25s;
        &:hover{
            opacity: 1;
            z-index: 100;
        }
    }
    main{
        height: 100%;
    }
}

.slider{
    opacity: 0;
    transition: opacity 1s;
    &.loaded{
        opacity: 1;
    }
    &:not(.slick-initialized){
        display: none;
    }
    .slick-arrow{
        position: absolute;
        z-index: 2;
        border: none;
        top: 0 !important;
        bottom: 0 !important;
        background-color: rgba($black, .6) !important;
        padding: 0;
        div{
            color: white;
            font-size: 1.5em;
            padding: 0 .4em;
        }

        &:hover{
            background-color: rgba($black, .85) !important;
        }
        &.slick-prev{
            left: 0;
        }
        &.slick-next{
            right: 0;
        }
    }
    &.dots{
        .slick-arrow{
            bottom: 1.5rem !important;
        }
    }
    .slick-dots{
        text-align: center;
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            cursor: pointer;
            button{
                font-size: 0;
                width: .6rem;
                height: .6rem;
                border: none;
                padding: 0;
                margin: 0 .5rem;
                border-radius: 50%;
                display: inline-block;
                background: $light-gray;
            }
            &:hover button{
                background: $dark-gray;
            }

            &.slick-active button{
                cursor: default;
                &, &:hover{
                    background: $black;
                }
            }
        }
    }
}
