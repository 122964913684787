@keyframes broadcastPing1 {
    0%  { opacity: 1; }
    60% { opacity: 1; }
    61% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes broadcastPing2 {
    0%  { opacity: 1; }
    80% { opacity: 1; }
    81% { opacity: 0; }
    100% { opacity: 0; }
}

.broadcast-animated{
    .wave_1{
        animation: broadcastPing2 1.85s infinite reverse;
    }
    .wave_2{
        animation: broadcastPing1 1.85s infinite reverse;
    }
}
