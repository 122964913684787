
header {
    $search-mobile-transition-speed: .1s ease-out;
    .left-flex, .right-flex, .nav-toggle, .search-bar, label{
        height: $header-height;
    }
    top: 0;
    // transform: translate3d(0,0,2px);
    box-shadow: $box-shadow;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .left-flex, .right-flex {
        flex-grow: 1;
        display: flex;
    }
    .header-icon, .user-icon{
        width: $header-height;
        font-size: 1.5em;
        padding: 1em .9em 0;
        flex-grow: 0;
        cursor: pointer;
        .desktop &:hover{
            background: rgba(black, .025);
        }
    }
    .user-icon{
        pointer-events: none;
        width: 5rem;
        > div{
            position: relative;
            background: green;
        }
        iframe{
            position: absolute;
            top: -0.6rem;
            right: 0;
            border: none;
            pointer-events: all;
            &:not(.opened){
                width: 2.9rem;
                height: 2.9rem;
                border-radius: 100%;
            }
            &.opened{
                width: 100vw;
                height: 100vh;
            }
        }
    }
    #site-feedback-target{
        padding-top: 0;
    }
    .translation-dropdown-button.header-icon{
        font-size: 1.4em;
    }
    .translation-dropdown-button{
        color: $light-gray;
        display: inline-flex;
        position: relative;
        width: auto;
        padding: 0 .75em 0 1em;
        transition: color .5s;
        cursor: pointer;
        > .icon, > .translation-icon{
            align-self: center;
        }
        > .icon{
            font-size: 1.5em;
            margin-left: .25em;
        }
        .translation-dropdown{
            top: $header-height;
        }
        &.active{
            color: $dark-gray;
        }
    }
    .right-flex{
        $icons-width: 2 * $header-height;
        text-align: right;
        justify-content: flex-end;
        flex-grow: 100;
        .search-bar{
            font-size: 1.2em;
            transition: flex-grow $search-mobile-transition-speed, width $search-mobile-transition-speed, padding $search-mobile-transition-speed, background-color $search-mobile-transition-speed;
            display: inline-block;
            flex-grow: 1;
            max-width: 43.5rem;
            width: 100%;
            input{
                transition: margin $search-mobile-transition-speed, color $search-mobile-transition-speed;
            }
            .search-button{
                transition: border-color $search-mobile-transition-speed;
            }
            .search-close-button{
                opacity: 1;
                transition: opacity $search-mobile-transition-speed;
            }
        }
        .search-back-button{
            width: 0;
            transition: width $search-mobile-transition-speed, margin $search-mobile-transition-speed;
            font-size: 2em;
            line-height: $header-height - .6rem;
            overflow: hidden;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            svg{
                fill: $light-gray;
            }
        }
        label{
            transition: width $search-mobile-transition-speed, padding $search-mobile-transition-speed;
            display: flex;
            align-items: center;
            line-height: $header-height;
            cursor: pointer;
            outline-offset: 0rem;
            text-align: center;
            .icon{
                fill: $light-gray;
                transition: all .15s;
                font-size: 1.6rem;
                width: 1.8rem;
                @media screen and (max-width: $mobile-width) {
                    font-size: 1.4rem;
                    width: 1.6rem;
                }
            }
            &.active{
                .icon{
                    fill: $primary-accent-color;
                }
                &:hover .icon{
                    fill: $primary-accent-color-highlight;
                }
            }
            &.active .icon{
                fill: $darkest-gray;
            }
        }
    }
    a.header-logo{
        display: inline-block;
        flex-grow: 1;
        overflow: hidden;
        font-size: 1.25rem;
        margin: 0 1em;
        color: $darkest-gray;
        .desktop &:hover{
            color: $dark-gray;
        }
        transition: margin $search-mobile-transition-speed, width $search-mobile-transition-speed;
        span{
            line-height: $header-height;
            font-size: 1.7rem;
        }
        .full{
            display: block;
        }
        .small{
            display: none;
        }
        @media screen and (max-width: $modal-stack-width) {
            .small{
                display: block;
                max-width: 2em;
            }
            .full{
                display: none;
            }
        }
        svg{
            vertical-align: baseline;
        }
    }

    @media screen and (max-width: $mobile-width) {
        .right-flex .search-bar .search-button:hover{
            background-color: transparent;
        }
        &.search-closed{
            a.header-logo{
                width: 2.25em;
                flex-grow: 0;
            }
            .right-flex .search-bar{
                background-color: transparent;
                margin: 0;
                width: 5em;
                flex-grow: 0.0001;
                input{
                    color: transparent;
                    pointer-events: none;
                    cursor: default;
                }
                .search-button{
                    border-left-color: transparent;
                }
                .search-close-button svg{
                    opacity: 0;
                }
            }
        }
        &.search-open{
            .right-flex{
                flex-grow: 100;
                label, div{
                    width: 0;
                    margin: 0;
                    overflow: hidden;
                    padding: 0 !important;
                }
                .search-back-button{
                    width: 2em;
                }
            }
            .left-flex{
                flex-grow: 0;
                .header-logo{
                    width: 0;
                    margin: 0;
                    flex-grow: 0;
                }
            }
        }
    }
}

.link-warning-container{
    padding: 2rem;
    font-size: 1.4rem;
    background: $warning-color;

    @include breakpointMin($modal-stack-width) {
        margin: 0 -2rem;
    }
    .link-warning-content {
        max-width: 96rem;
        margin: auto;
    }
    a{
        color: $black;
        font-weight: bold;
        text-decoration: underline;
    }
}

.breadcrumbs{
    background: $darkest-gray;
    top: $header-height;
    position: fixed;
    left: 0;
    right: 0;
    height: $breadcrumb-height;
    z-index: 1001;
    padding-left: 1.5rem;
    font-size: 1.4rem;
    color: white;
    padding-right: 3em;
    .learn &{
        padding-right: 9.25em;
    }
    .crumbs{
        height: $breadcrumb-height;
        @extend .overflow-ellipsis;
        > a{
            line-height: $breadcrumb-height;
            &:not(.no-url):hover{
                text-decoration: underline;
            }
            &.no-url{
                cursor: default;
            }
        }
    }
    @include breakpointMax($modal-stack-width) {
        position: relative;
    }
    .icon, a{
        color: currentColor;
    }
    .icon{
        margin: 0 .5rem;
        font-size: 2.4rem;
    }
    .dashboard-help-dropdown{
        position: absolute;
        right: 1rem;
        top: 1.75rem;
        ul{
            margin-top: 1rem;
            color: $black;
        }
        .icon{
            font-size: 1.2em;
        }
    }
    .page-learn{
        position: absolute;
        right: 4rem;
        top: 1.1rem;
    }
}
