.time-slider {
    width: 100%;
    height: 4.5rem;
    *{
        user-select: none;
    }
    > div {
        float: left;
        text-align: center;
    }
    .time {
        margin-top: .75rem;
        width: 100%;
        font-size: 1.3rem;
    }
    .nudge, .bar-container{
        height: 2.5rem;
    }
    .nudge {
        width: 3rem;
        cursor: pointer;
        font-size: 2.4rem;
        line-height: 0;
        border: .1rem solid currentColor;
        color: $light-gray;
        &:hover {
            color: $primary-accent-color;
        }
        &:active{
            border-color: $primary-accent-color-dark;
            background-color: rgba($primary-accent-color-highlight, .125);
        }
        .icon{
            transform: rotate(0deg);
        }
    }
    .bar-container {
        width: calc(100% - 7.5rem);
        margin: 0 .75rem;
        position: relative;
        background-color: $off-white;
        div {
            position: absolute;
            top: 0;
            bottom: 0;
        }
        .bar {
            background-color: $primary-accent-color;
            left: 0;
            right: 0;
            z-index: 1;
        }
        .handle {
            width: 1.7rem;
            height: 3.5rem;
            top: -.5rem;
            left: -.9rem;
            cursor: pointer;
            z-index: 2;
            &:before{
                content: '';
                width: .1rem;
                position: absolute;
                background: $darkest-gray;
                left: .8rem;
                height: 100%;
                pointer-events: none;
            }
        }
    }
}
